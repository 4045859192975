import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from '../../floor-store/reducers';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {exhaustMap, switchMap} from 'rxjs/operators';
import {TaskBusinessTypeActions} from '../actions';
import {of} from 'rxjs';


@Injectable()
export class TaskBusinessTypeEffects {

  // updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskActions.updateSelectedTaskId),
  //   )
  // );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }

  beginGetAllTaskBusinessTypes$ = createEffect(() => this.actions$.pipe(
    ofType(TaskBusinessTypeActions.beginGetAllTaskBusinessTypes),
    exhaustMap((action) =>
      this.projectManagementService.getAllTaskBusinessTypes().pipe(
        switchMap(taskBusinessTypes =>
          of(TaskBusinessTypeActions.addTaskBusinessTypes({taskBusinessTypes: taskBusinessTypes }))
        ),
    )
  )));
}
