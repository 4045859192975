import {AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {LayoutService} from '../services/layout.service';
import {BreakpointObserver, MediaMatcher} from '@angular/cdk/layout';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackBarMessage, SnackBarService} from '../services/snack-bar.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {State} from '../../reducers';
import {Store} from '@ngrx/store';
import {CoreActions} from '../../store/core-store/actions';

@Component({
  selector: 'echo-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(private layoutService: LayoutService,
              private store: Store<State>,
              private breakpointObserver: BreakpointObserver,
              private snackBar: MatSnackBar,
              private renderer: Renderer2,
              changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
              private snackBarService: SnackBarService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => {
      this.layoutService.isMobile$.next(this.mobileQuery.matches);
      //console.log(this.mobileQuery.matches);
      changeDetectorRef.detectChanges();
    };
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.layoutService.rightDrawer$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value) {
          this.rightSidenav.open();
        }
        if (!value) {
          this.rightSidenav.close();
        }
      });
    this.layoutService.leftDrawer$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        if (value) {
          this.leftSidenav.open();
        }
        if (!value) {
          this.leftSidenav.close();
        }
      });
  }

  @ViewChild('leftSidenav', {static: false}) public leftSidenav;
  @ViewChild('rightSidenav', {static: true}) public rightSidenav;
  //
  private unsubscribe$ = new Subject<void>();

  // /** Release user ticket when user closes window **/
  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(event) {
  //   /**
  //    * Releasing ticket through an effect in the store avoids injecting TicketService in LayoutComponent
  //    * which results in an error at singleton initialization because user doesn't have probably received
  //    * a token yet
  //    */
  //   this.store.dispatch(CoreActions.releaseTicket());
  //   // this.ticketService.releaseTicket().subscribe();
  // }

  ngOnInit() {
    //this.userService.setUserFromIdentity();

    this.snackBarService.message$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => this.openSnackBar(data));
    this.rightSidenav.openedChange.pipe(takeUntil(this.unsubscribe$))
      .subscribe((o: boolean) => {
        this.layoutService.rightDrawer$.next(o);
      });
    // this.userService.getEcsyUser().pipe(
    //   takeUntil(this.unsubscribe$),
    //   switchMap(user => this.rxStompService.watch(`/notifications/${user.id}`)),
    //   map((message: Message) => {
    //     console.log(message);
    //     this.receivedMessages.push(message);
    //   })).subscribe();

  }

  ngAfterViewInit() {
    this.mobileQuery.onchange;
  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    // Emit something to stop all Observables
    this.unsubscribe$.next();
    // Complete the notifying Observable to remove it
    this.unsubscribe$.complete();
  }

  openSnackBar(message: SnackBarMessage) {
    this.snackBar.open(message.message, message.action, {
      horizontalPosition: 'center',
      duration: message.alert ? 8000 : 4000,
      panelClass: message.alert ? 'snackbar-alert' : 'snackbar-info',
    });
  }

  closeRightDrawer() {
    this.layoutService.rightDrawerState$.next(false);
  }

  closeLeftDrawer() {
    this.layoutService.leftDrawerState$.next(false);
  }

}
