import {createAction, props} from '@ngrx/store';
import {SvgUse} from '../../../svg-factory/models/svg-use.model';
import {Update} from '@ngrx/entity';
import {SvgBoundingBox} from '../../../svg-factory/models/svg-bounding-box.model';

export const addUses = createAction(
  '[Floor/API] - Add all SvgUses',
  props<{ svgUses: SvgUse[] }>()
);

export const addUse = createAction(
  '[Floor/API] - Add SvgUse',
  props<{ svgUse: SvgUse }>()
);

export const updateUse = createAction(
  '[SvgRenderer] - Update SvgUse',
  props<{ svgUse: Update<SvgUse> }>()
);

export const updateUses = createAction(
  '[SvgRenderer] - Update SvgUses',
  props<{ svgUses: Update<SvgUse>[] }>()
);

export const upsertUse = createAction(
  '[SvgRenderer] - Upsert SvgUse',
  props<{ svgUse: SvgUse }>()
);

export const upsertUses = createAction(
  '[SvgRenderer] - Upsert SvgUses',
  props<{ svgUses: SvgUse[] }>()
);

export const deleteUse = createAction(
  '[SvgRenderer] - Delete Use',
  props<{ id: number }>()
);

export const deleteUses = createAction(
  '[SvgRenderer] - Delete Uses',
  props<{ ids: number[] }>()
);

export const updateSelectedUseId = createAction(
  '[SvgRenderer] - Save Selected Use Id',
  props<{ id: number }>()
);

export const updateHoveredUseId = createAction(
  '[SvgRenderer] - Save Hovered Use Id',
  props<{ id: number }>()
);

export const updateselectedUseDOMRect = createAction(
  '[SvgRenderer] - Save Selected Use DOMRect',
  props<{ DOMRect: any }>()
);

export const updateselectedUseBoundingBox = createAction(
  '[SvgRenderer] - Save Selected Use Bounding Box',
  props<{ boundingBox: SvgBoundingBox }>()
);

export const clearUses = createAction('[Floor] Clear Uses');

export const addToSelection = createAction(
  '[SvgRenderer] - Add UseId to selection',
  props<{ id: number, clearSelection?: boolean }>()
);

export const removeFromSelection = createAction(
  '[SvgRenderer] - Remove UseId from selection',
  props<{ id: number }>()
);

export const updateSelection = createAction(
  '[Use] - Update Use Selection Ids',
  props<{ addedId: number, removedId: number }>()
);

export const clearSelection = createAction('[SvgRenderer] - Clear Selected UseIds');

export const addBoundingBox = createAction(
  '[SvgRenderer] - Add use BoundingBox',
  props<{ id: number, bbox: SvgBoundingBox }>()
);
