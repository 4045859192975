import {createAction, props} from '@ngrx/store';
import {LanguageItem} from '../../../domain-models/core/z-language-item.model';

export const beginGetAllLanguageItems = createAction('[Shared/API] - Begin Get All LanguageItems');

export const addLanguageItems = createAction(
  '[API] - Add LanguageItems',
  props<{ languageItems: LanguageItem[] }>()
);

export const successGetAllLanguageItems = createAction('[API] - Success Get All LanguageItems');
export const errorGetAllLanguageItems = createAction('[Shared/API] - Error Get All LanguageItems', props<Error>());
