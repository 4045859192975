import {createSelector} from '@ngrx/store';
import * as fromPerimeters from '../reducers/perimeters.reducer';
import {selectSharedState} from '../reducers';
import {Perimeter} from '../../../domain-models/business/perimeter.model';

export const selectPerimeters = createSelector(
  selectSharedState,
  state => state[fromPerimeters.perimetersFeatureKey]
);

export const selectPerimetersIds = createSelector(
  selectPerimeters,
  fromPerimeters.selectPerimetersIds
);
export const selectPerimetersEntities = createSelector(
  selectPerimeters,
  fromPerimeters.selectPerimetersEntities
);
export const selectAllPerimeters = createSelector(
  selectPerimeters,
  fromPerimeters.selectAllPerimeters
);
export const selectPerimetersTotal = createSelector(
  selectPerimeters,
  fromPerimeters.selectPerimetersTotal
);

// perimetres customs

export const selectPerimeterById = (id: number) => createSelector(
  selectPerimetersEntities,
  (entities) => entities[id]
);

export const selectPerimetersByIds = (ids: number[]) => createSelector(
  selectAllPerimeters,
  (allPerimeters) => {
    return allPerimeters.filter((p: Perimeter) => ids.includes(p.id));
  }
);
