import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as FloorDoorStyleActions from '../actions/floor-door-style.actions';
import {FloorDoorStyle} from '../../../domain-models/business/floor-door-style.model';

export const floorDoorStylesFeatureKey = 'floorDoorStyles';

export interface State extends EntityState<FloorDoorStyle> {
  isLoaded: boolean;
}

export const adapter: EntityAdapter<FloorDoorStyle> = createEntityAdapter<FloorDoorStyle>({});

export const initialState: State = adapter.getInitialState({
  isLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(FloorDoorStyleActions.addFloorDoorStyles, (state, {floorDoorStyles}) => {
    return adapter.addMany(floorDoorStyles, state);
  }),
  on(FloorDoorStyleActions.successGetAllFloorDoorStyles,  state => ({ ...state, isLoaded: true }))
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorDoorStylesIds = selectIds;
export const selectFloorDoorStylesEntities = selectEntities;
export const selectAllFloorDoorStyles = selectAll;
export const selectFloorDoorStylesTotal = selectTotal;
