import {createSelector} from '@ngrx/store';
import * as fromFloor from '../reducers/floor.reducer';
import {selectFloorsState} from '../reducers';

/** Select store slice **/
export const selectFloors = createSelector(
  selectFloorsState,
  state => state[fromFloor.floorFeatureKey]
);

/** Basics selectors **/

export const selectFloorIds = createSelector(
  selectFloors,
  fromFloor.selectFloorIds // shorthand for floorsState => fromFloor.selectFloorIds(floorsState)
);
export const selectFloorEntities = createSelector(
  selectFloors,
  fromFloor.selectFloorEntities
);
export const selectAllFloors = createSelector(
  selectFloors,
  fromFloor.selectAllFloors
);
export const selectFloorTotal = createSelector(
  selectFloors,
  fromFloor.selectFloorTotal
);
export const selectCurrentFloorId = createSelector(
  selectFloors,
  fromFloor.getSelectedFloorId
);

export const selectCurrentFloor = createSelector(
  selectFloorEntities,
  selectCurrentFloorId,
  (floorEntities, floorId) => floorEntities[floorId]
);

/** Advanced selectors **/

// Don't use selectCurrentBuildingId to allow memoization
// https://medium.com/angular-in-depth/ngrx-parameterized-selector-e3f610529f8
export const selectFloorsByBuildingId = (buildingId: number) => createSelector(
  selectAllFloors,
  (floors) => floors
    .filter(f => f.buildingId === buildingId)
    .sort((a, b) => a.elevation - b.elevation)
);
