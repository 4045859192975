import {createSelector} from '@ngrx/store';
import * as fromTaskBusinessType from '../reducers/task-business-type.reducer';
import {selectProjectManagementState} from '../reducers';

/** Select store slice **/

export const selectTaskBusinessTypes = createSelector(
  selectProjectManagementState,
  state => state[fromTaskBusinessType.taskBusinessTypeFeatureKey]
);

/** Basics selectors **/

export const selectTaskBusinessTypeIds = createSelector(
  selectTaskBusinessTypes,
  fromTaskBusinessType.selectTaskBusinessTypeIds
);
export const selectTaskBusinessTypeEntities = createSelector(
  selectTaskBusinessTypes,
  fromTaskBusinessType.selectTaskBusinessTypeEntities
);
export const selectAllTaskBusinessTypes = createSelector(
  selectTaskBusinessTypes,
  fromTaskBusinessType.selectAllTaskBusinessTypes
);
export const selectTaskBusinessTypeTotal = createSelector(
  selectTaskBusinessTypes,
  fromTaskBusinessType.selectTaskBusinessTypeTotal
);
export const selectCurrentTaskBusinessTypeId = createSelector(
  selectTaskBusinessTypes,
  fromTaskBusinessType.getSelectedTaskBusinessTypeId
);

export const selectCurrentTaskBusinessType = createSelector(
  selectTaskBusinessTypeEntities,
  selectCurrentTaskBusinessTypeId,
  (taskBusinessTypeEntities, taskBusinessTypeId) => taskBusinessTypeEntities[taskBusinessTypeId]
);

/** Advanced selectors **/

export const selectTaskBusinessTypeById = (id: number) => createSelector(
  selectTaskBusinessTypeEntities,
  (entities) => entities[id]
);
