import {Action, combineReducers, createAction, createFeatureSelector, INIT} from '@ngrx/store';
import * as fromFloor from '../reducers/floor.reducer';
import * as fromRoom from '../reducers/room.reducer';
import * as fromRoomAllocation from '../reducers/room-allocation.reducer';
import * as fromWorkplace from './workplace.reducer';
import * as fromWorkplaceAllocation from './workplace-allocation.reducer';


import * as fromUiContext from './ui-context.reducer';
import * as fromRoot from '../../../reducers';

export const floorFeatureKey = 'floor';

export const resetFloorFeatureState = createAction('[FloorFeatureState] - Reset FloorFeatureState');

export interface FloorState {
  [fromFloor.floorFeatureKey]: fromFloor.State;
  [fromRoom.roomFeatureKey]: fromRoom.State;
  [fromRoomAllocation.roomAllocationsFeatureKey]: fromRoomAllocation.State;
  [fromUiContext.uiContextFeatureKey]: fromUiContext.State;
  [fromWorkplace.workplaceFeatureKey]: fromWorkplace.State;
  [fromWorkplaceAllocation.workplaceAllocationsFeatureKey]: fromWorkplaceAllocation.State;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: FloorState | undefined, action: Action) {
  if (action != null && action.type === resetFloorFeatureState.type) {
    return reducers(undefined, {type: INIT});
  }
  return combineReducers({
    [fromFloor.floorFeatureKey]: fromFloor.reducer,
    [fromRoom.roomFeatureKey]: fromRoom.reducer,
    [fromRoomAllocation.roomAllocationsFeatureKey]: fromRoomAllocation.reducer,
    [fromUiContext.uiContextFeatureKey]: fromUiContext.reducer,
    [fromWorkplace.workplaceFeatureKey]: fromWorkplace.reducer,
    [fromWorkplaceAllocation.workplaceAllocationsFeatureKey]: fromWorkplaceAllocation.reducer,
  })(state, action);
}

export interface State extends fromRoot.State {
  [floorFeatureKey]: FloorState;
}

// /**
//  * A selector function is a map function factory. We pass it parameters and it
//  * returns a function that maps from the larger state tree into a smaller
//  * piece of state. This selector simply selects the `books` state.
//  *
//  * Selectors are used with the `select` operator.
//  *
//  * ```ts
//  * class MyComponent {
//  *   constructor(state$: Observable<State>) {
//  *     this.booksState$ = state$.pipe(select(getBooksState));
//  *   }
//  * }
//  * ```
//  */
//
/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectFloorsState = createFeatureSelector<State>(
  floorFeatureKey
);
//
// /**
//  * Every reducer module exports selector functions, however child reducers
//  * have no knowledge of the overall state tree. To make them usable, we
//  * need to make new selectors that wrap them.
//  *
//  * The createSelector function creates very efficient selectors that are memoized and
//  * only recompute when arguments change. The created selectors can also be composed
//  * together to select different pieces of state.
//  */
// export const selectBookEntitiesState = createSelector(
//   selectBooksState,
//   state => state.books
// );
//
// export const selectSelectedBookId = createSelector(
//   selectBookEntitiesState,
//   fromBooks.selectId
// );
//
// /**
//  * Adapters created with @ngrx/entity generate
//  * commonly used selector functions including
//  * getting all ids in the record set, a dictionary
//  * of the records by id, an array of records and
//  * the total number of records. This reduces boilerplate
//  * in selecting records from the entity state.
//  */
// export const {
//   selectIds: selectBookIds,
//   selectEntities: selectBookEntities,
//   selectAll: selectAllBooks,
//   selectTotal: selectTotalBooks,
// } = fromBooks.adapter.getSelectors(selectBookEntitiesState);
//
// export const selectSelectedBook = createSelector(
//   selectBookEntities,
//   selectSelectedBookId,
//   (entities, selectedId) => {
//     return selectedId && entities[selectedId];
//   }
// );
//
// /**
//  * Just like with the books selectors, we also have to compose the search
//  * reducer's and collection reducer's selectors.
//  */
// export const selectSearchState = createSelector(
//   selectBooksState,
//   state => state.search
// );
//
// export const selectSearchBookIds = createSelector(
//   selectSearchState,
//   fromSearch.getIds
// );
// export const selectSearchQuery = createSelector(
//   selectSearchState,
//   fromSearch.getQuery
// );
// export const selectSearchLoading = createSelector(
//   selectSearchState,
//   fromSearch.getLoading
// );
// export const selectSearchError = createSelector(
//   selectSearchState,
//   fromSearch.getError
// );
//
// /**
//  * Some selector functions create joins across parts of state. This selector
//  * composes the search result IDs to return an array of books in the store.
//  */
// export const selectSearchResults = createSelector(
//   selectBookEntities,
//   selectSearchBookIds,
//   (books, searchIds) => {
//     return searchIds
//       .map(id => books[id])
//       .filter((book): book is Book => book != null);
//   }
// );
//
// export const selectCollectionState = createSelector(
//   selectBooksState,
//   state => state.collection
// );
//
// export const selectCollectionLoaded = createSelector(
//   selectCollectionState,
//   fromCollection.getLoaded
// );
// export const getCollectionLoading = createSelector(
//   selectCollectionState,
//   fromCollection.getLoading
// );
// export const selectCollectionBookIds = createSelector(
//   selectCollectionState,
//   fromCollection.getIds
// );
//
// export const selectBookCollection = createSelector(
//   selectBookEntities,
//   selectCollectionBookIds,
//   (entities, ids) => {
//     return ids
//       .map(id => entities[id])
//       .filter((book): book is Book => book != null);
//   }
// );
//
// export const isSelectedBookInCollection = createSelector(
//   selectCollectionBookIds,
//   selectSelectedBookId,
//   (ids, selected) => {
//     return !!selected && ids.indexOf(selected) > -1;
//   }
// );
