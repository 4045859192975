import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LayoutService} from '../../core/services/layout.service';
import {ClientService} from '../../core/services/client.service';
import {EcSyFile} from '../../domain-models/models/EcSyFile.model';
import {UserService} from '../../core/services/user.service';
import {AuthService} from '../../core/services/auth.service';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {State} from '../../reducers';
import {CoreSelectors} from '../../store/core-store/selectors';
import {User} from '../../domain-models/business-extended/user.model';

@Component({
  selector: 'echo-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPanelComponent implements OnInit {

  client$: Observable<EcSyFile>;
  user$: Observable<User>;

  constructor(private store: Store<State>,
              private router: Router,
              private authService: AuthService,
              private clientService: ClientService,
              private userService: UserService,
              private layoutService: LayoutService) {
  }

  ngOnInit() {
    this.user$ = this.store.select(CoreSelectors.selectUser);
    this.client$ = this.store.select(CoreSelectors.selectClient);
  }

  logout() {
    this.authService.logout();
    this.closeDrawer();
  }

  navigate() {
    this.router.navigateByUrl('app/(debug//sidenav:debug)');
  }

  closeDrawer() {
    this.layoutService.rightDrawer$.next(false);
  }


}
