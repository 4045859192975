import {createSelector} from '@ngrx/store';
import * as fromTaskFunction from '../reducers/task-function.reducer';
import {selectProjectManagementState} from '../reducers';

/** Select store slice **/

export const selectTaskFunctions = createSelector(
  selectProjectManagementState,
  state => state[fromTaskFunction.taskFunctionFeatureKey]
);

/** Basics selectors **/

export const selectTaskFunctionIds = createSelector(
  selectTaskFunctions,
  fromTaskFunction.selectTaskFunctionIds
);
export const selectTaskFunctionEntities = createSelector(
  selectTaskFunctions,
  fromTaskFunction.selectTaskFunctionEntities
);
export const selectAllTaskFunctions = createSelector(
  selectTaskFunctions,
  fromTaskFunction.selectAllTaskFunctions
);
export const selectTaskFunctionTotal = createSelector(
  selectTaskFunctions,
  fromTaskFunction.selectTaskFunctionTotal
);
export const selectCurrentTaskFunctionId = createSelector(
  selectTaskFunctions,
  fromTaskFunction.getSelectedTaskFunctionId
);

export const selectCurrentTaskFunction = createSelector(
  selectTaskFunctionEntities,
  selectCurrentTaskFunctionId,
  (taskFunctionEntities, taskFunctionId) => taskFunctionEntities[taskFunctionId]
);

/** Advanced selectors **/

export const selectTaskFunctionById = (id: number) => createSelector(
  selectTaskFunctionEntities,
  (entities) => entities[id]
);
