import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TaskFloorActions} from '../actions';
import {TaskFloor} from '../../../domain-models/business/task-floor.model';

export const taskTaskFloorFeatureKey = 'taskFloors';

export interface State extends EntityState<TaskFloor> {
  selectedTaskFloorId: number | null;
}

export function generateId(a: TaskFloor): number {
  return a.taskId;
  //return a.taskId + '-' + a.floorId;
}

export const adapter: EntityAdapter<TaskFloor> = createEntityAdapter<TaskFloor>({
  selectId: generateId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectedTaskFloorId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskFloorActions.addTaskFloor, (state, { taskFloor }) => {
    return adapter.addOne(taskFloor, state);
  }),
  on(TaskFloorActions.setTaskFloor, (state, { taskFloor }) => {
    return adapter.setOne(taskFloor, state);
  }),
  on(TaskFloorActions.upsertTaskFloor, (state, { taskFloor }) => {
    return adapter.upsertOne(taskFloor, state);
  }),
  on(TaskFloorActions.addTaskFloors, (state, { taskFloors }) => {
    return adapter.addMany(taskFloors, state);
  }),
  on(TaskFloorActions.upsertTaskFloors, (state, { taskFloors }) => {
    return adapter.upsertMany(taskFloors, state);
  }),
  on(TaskFloorActions.updateTaskFloor, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskFloorActions.updateTaskFloors, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskFloorActions.mapTaskFloors, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskFloorActions.deleteTaskFloor, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskFloorActions.deleteTaskFloors, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskFloorActions.deleteTaskFloorsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskFloorActions.loadTaskFloors, (state, { taskFloors }) => {
    return adapter.setAll(taskFloors, state);
  }),
  on(TaskFloorActions.clearTaskFloors, state => {
    return adapter.removeAll({ ...state, selectedTaskFloorId: null });
  }),
  /** END OF CRUD **/
  on(TaskFloorActions.updateSelectedTaskFloorId, (state, {id}) => {
    return ({...state, selectedTaskFloorId: id});
  }),
);

export const getSelectedTaskFloorId = (state: State) => state.selectedTaskFloorId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskTaskFloor ids
export const selectTaskFloorIds = selectIds;

// select the dictionary of taskTaskFloor entities
export const selectTaskFloorEntities = selectEntities;

// select the array of taskTaskFloors
export const selectAllTaskFloors = selectAll;

// select the total taskTaskFloor count
export const selectTaskFloorTotal = selectTotal;
