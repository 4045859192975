import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  //Draw related Observables
  public leftDrawer$ = new Subject<boolean>();
  public rightDrawer$ = new Subject<boolean>();
  public rightDrawerState$ = new Subject<boolean>();
  public leftDrawerState$ = new Subject<boolean>();
  public isHandset$ = new BehaviorSubject<boolean>(false);
  public isMobile$ = new BehaviorSubject<boolean>(null);

  constructor() {
  }

}
