import {Injectable} from '@angular/core';
import {Adapter} from '../adapter';
import {SvgGroup} from '../../svg-factory/models/svg-group.model';
import {TaskFloorModel, TaskFloorModelAdapter} from '../business-extended/task-floor-model.model';
import {Floor, FloorAdapter} from '../business/floor.model';

const BUILDINGS_FLOOR = {
  floors: 'floor',
  floorModels: 'FloorModel',
  floorDataItems: 'SvgGroupDTO',
};

export class ApiBuildingsFloors {
  constructor(
    public floors: Floor[],
    public floorModels: TaskFloorModel[],
    public floorDataItems: SvgGroup[],
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiBuildingsFloorsAdapter implements Adapter<ApiBuildingsFloors> {
  adapt(item: any): ApiBuildingsFloors {

    const floors = [] as Floor[];
    item[BUILDINGS_FLOOR.floors].forEach(
      f => floors.push(new FloorAdapter().adapt(f))
    );

    /** Create one TaskFloorModel per Floor with mapped Fl_TaId **/
    const floorModels = [] as TaskFloorModel[];
    floors.forEach(f => {
      /** Take blueprintExtendFloorModel from payload, use it as a reference FloorModel item **/
      const blueprintExtendTaskFloorModel = new TaskFloorModelAdapter().adapt(item[BUILDINGS_FLOOR.floorModels][0]);
      blueprintExtendTaskFloorModel.taskId = f.taskId;
      floorModels.push(blueprintExtendTaskFloorModel);
    });

    const floorDataItems = item[BUILDINGS_FLOOR.floorDataItems] as SvgGroup[];

    return new ApiBuildingsFloors(
      floors,
      floorModels,
      floorDataItems,
    );
  }
}
