import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface SnackBarMessage {
  message: string;
  action: string;
  alert?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class SnackBarService {

  message$ = new Subject<SnackBarMessage>();

  constructor() {
  }
}
