import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as RoomActivityStatusTypeActions from '../actions/room-activity-status-type.actions';
import {RoomActivityStatusType} from '../../../domain-models/business/room-activity-status-type.model';

export const roomActivityStatusTypesFeatureKey = 'roomActivityStatusTypes';

export interface State extends EntityState<RoomActivityStatusType> {}

export const adapter: EntityAdapter<RoomActivityStatusType> = createEntityAdapter<RoomActivityStatusType>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(RoomActivityStatusTypeActions.successGetAllRoomActivityStatusTypes, (state, {roomActivityStatusTypes}) => {
    return adapter.addMany(roomActivityStatusTypes, state);
  }),
  on(RoomActivityStatusTypeActions.addRoomActivityStatusType, (state, roomActivityStatusType) => {
    return adapter.addOne(roomActivityStatusType, state);
  })
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRoomActivityStatusTypeIds = selectIds;
export const selectRoomActivityStatusTypeEntities = selectEntities;
export const selectAllRoomActivityStatusTypes = selectAll;
export const selectRoomActivityStatusTypeTotal = selectTotal;
