import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const TASK_FLOOR = {
  databaseTableName: 'task_floor',
  taskId: 'TaFl_TaskId',
  floorId: 'TaFl_FloorId',
  isSourceFloor: 'TaFl_IsSourceFloor',
  isTargetFloor: 'TaFl_IsTargetFloor',
  isPlanningTask: 'TaFl_IsPlanningTask',
  isAsBuiltTask: 'TaFl_IsAsBuiltTask',
  floorModelId: 'TaFl_FloorModelId'
};

export class TaskFloor {
  constructor(
    public databaseTableName: string,
    public taskId: number,
    public floorId: number,
    public isSourceFloor: boolean,
    public isTargetFloor: boolean,
    public isPlanningTask: boolean,
    public isAsBuiltTask: boolean,
    public floorModelId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskFloorAdapter implements Adapter<TaskFloor> {
  adapt(item: any): TaskFloor {
    return new TaskFloor(
      TASK_FLOOR.databaseTableName,
      item[TASK_FLOOR.taskId],
      item[TASK_FLOOR.floorId],
      item[TASK_FLOOR.isSourceFloor],
      item[TASK_FLOOR.isTargetFloor],
      item[TASK_FLOOR.isPlanningTask],
      item[TASK_FLOOR.isAsBuiltTask],
      item[TASK_FLOOR.floorModelId]
    );
  }

  encode(item: TaskFloor | Partial<TaskFloor>): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_FLOOR.taskId]: item.taskId,
      [TASK_FLOOR.floorId]: item.floorId,
      [TASK_FLOOR.isSourceFloor]: item.isSourceFloor,
      [TASK_FLOOR.isTargetFloor]: item.isTargetFloor,
      [TASK_FLOOR.isPlanningTask]: item.isPlanningTask,
      [TASK_FLOOR.isAsBuiltTask]: item.isAsBuiltTask,
      [TASK_FLOOR.floorModelId]: item.floorModelId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
