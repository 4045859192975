import {createAction, props} from '@ngrx/store';
import {EntityMap, Predicate, Update} from '@ngrx/entity';
import {TaskLink} from '../../../domain-models/business/task-link.model';

/**
 * CRUD Actions
 */

export const loadTaskLinks = createAction('[TaskLink/API] Load TaskLinks', props<{ taskLinks: TaskLink[] }>());
export const addTaskLink = createAction('[TaskLink/API] Add TaskLink', props<{ taskLink: TaskLink }>());
export const setTaskLink = createAction('[TaskLink/API] Set TaskLink', props<{ taskLink: TaskLink }>());
export const upsertTaskLink = createAction('[TaskLink/API] Upsert TaskLink', props<{ taskLink: TaskLink }>());
export const addTaskLinks = createAction('[TaskLink/API] Add TaskLinks', props<{ taskLinks: TaskLink[] }>());
export const upsertTaskLinks = createAction('[TaskLink/API] Upsert TaskLinks', props<{ taskLinks: TaskLink[] }>());
export const updateTaskLink = createAction('[TaskLink/API] Update TaskLink', props<{ update: Update<TaskLink> }>());
export const updateTaskLinks = createAction('[TaskLink/API] Update TaskLinks', props<{ updates: Update<TaskLink>[] }>());
export const mapTaskLinks = createAction('[TaskLink/API] Map TaskLinks', props<{ entityMap: EntityMap<TaskLink> }>());
export const deleteTaskLink = createAction('[TaskLink/API] Delete TaskLink', props<{ id: number }>());
export const deleteTaskLinks = createAction('[TaskLink/API] Delete TaskLinks', props<{ ids: number[] }>());
export const deleteTaskLinksByPredicate = createAction('[TaskLink/API] Delete TaskLinks By Predicate', props<{ predicate: Predicate<TaskLink> }>());
export const clearTaskLinks = createAction('[TaskLink/API] Clear TaskLinks');

/** END of CRUD **/

export const beginGetAllTaskLinks = createAction('[TaskLink/API] - Begin Get All TaskLinks');
export const errorGetAllTaskLinks = createAction('[API] - Error Get All TaskLinks', props<Error>());

export const updateSelectedTaskLinkId = createAction(
  '[TaskLink/API] - Save Selected TaskLink Id',
  props<{ taskLinkId: number }>()
);
