import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from './logging.service';
import {API_ENDPOINTS} from '../../shared/api-endpoints';
import {Z_APP_PARAMETER} from '../../domain-models/core/Z_App_Parameter';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService extends ApiService {

  appParams = {};
  appParams$ = new BehaviorSubject(this.appParams);

  constructor(http: HttpClient,
              loggingService: LoggingService) {
    super(http, loggingService);
    /** TODO Make better async process between all core services loading processes (Clients / Params / Api)**/
    if (sessionStorage.getItem('appParams')) {
      this.loadAppParams();
    }
  }

  public loadAppParams() {
    this.get(API_ENDPOINTS.appParams).subscribe(
      data => {
        this.appParams = {};
        data.payload.forEach(
          param => this.appParams[param[Z_APP_PARAMETER.name]] = param[Z_APP_PARAMETER.value]
        );
        localStorage.setItem('appParams', JSON.stringify(this.appParams));
        this.appParams$.next(this.appParams);
        //console.log(this.appParams);
      });
  }
}
