import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, filter, map, switchMap, tap} from 'rxjs/operators';
import {Router} from "@angular/router";
import {clearState} from "../reducers";
import {AuthService} from "../core/services/auth.service";
import {UserService} from "../core/services/user.service";
import {CoreActions} from "../store/core-store/actions";
import {EMPTY} from 'rxjs';

@Injectable()
export class RootEffects {

  clearState$ = createEffect(() => this.actions$.pipe(
    ofType(clearState),
    switchMap(action => this.authService.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated === true),
      concatMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.userService.setUserFromIdentity().pipe(
            //tap(a => console.log(a)),
            map(user => CoreActions.setUser({user: user}))
          );
        } else {
          return EMPTY;
        }
      })
    ))
  ));

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) {
  }
}
