import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LoggingService} from './logging.service';
import {ApiData} from '../models/api-data.model';
import {Z_USER_DATAGRID_UI} from '../../domain-models/core/Z_User_Datagrid_UI';
import {API_ENDPOINTS} from '../../shared/api-endpoints';
import {ApiResponseCodeEnum} from '../../domain-models/models/api-response-code.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(protected http: HttpClient,
              protected loggingService: LoggingService) {
  }

  get(url: string, usageContextId?: number, params?: HttpParams): Observable<ApiData> {
    let httpOptions: object;
    if (usageContextId) {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': String(usageContextId)
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': '1'
        })
      };
    }
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }

    return this.http.get<ApiData>(url, httpOptions)
      .pipe(
        //tap(_ => this.log('fetched ' + url)),
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              this.log(data.message);
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this))
      );
  }

  post(url: string, object: any, params?: HttpParams): Observable<ApiData> {
    let httpOptions = {};
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }
    return this.http.post<ApiData>(url, object, httpOptions)
      .pipe(
        //tap(_ => this.log('posted ' + url)),
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              this.log(data.message);
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this)) //prevent loosing context https://stackoverflow.com/questions/52078137/service-call-doesnt-work-inside-error-handler-in-angular-5
      );
  }

  patch(url: string, object: any, usageContextId?: number, params?: HttpParams): Observable<ApiData> {
    let httpOptions: object;
    if (usageContextId) {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': String(usageContextId),
        })
      };
    } else {
      httpOptions = {
        headers: new HttpHeaders({
          'Echo-Usage-Context-Id': '1',
          'Content-Type': 'application/json',
        })
      };
    }
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }
    //console.log('Patch operation');
    return this.http.patch<ApiData>(url, object, httpOptions)
      .pipe(
        tap(_ => this.log('patched ' + url)),
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              this.log(data.message);
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this))
      );
  }

  put(url: string, object: any): Observable<any> {
    console.log('Patch operation');
    return this.http.put<any>(url, object)
      .pipe(
        tap(_ => this.log('patched ' + url)),
        catchError(this.handleError)
      );
  }

  delete(url: string, params?: HttpParams): Observable<ApiData> {
    let httpOptions = {};

    httpOptions = {...httpOptions,
      headers: new HttpHeaders({
        //'Echo-Usage-Context-Id': '1',
        'Content-Type': 'application/json',
      })
    };
    if (params) {
      httpOptions = {
        ...httpOptions,
        params: params
      };
    }
    return this.http.delete<ApiData>(url, httpOptions)
      .pipe(
        map(data => {
          if (data.statusCode === ApiResponseCodeEnum.success) {
            if (data.message) {
              this.log(data.message);
            }
            return data as ApiData;
          } else {
            throw data;
          }
        }),
        catchError(this.handleError.bind(this))
        // tap(_ => this.log('deleted ' + url)),
        // catchError(this.handleError.bind(this))
      );
  }

  public updatePagination(tableName: string, usageContextId: string, pageSize: number): Promise<boolean> {
    return new Promise((resolve) => {
      const userDatagridUi = {};
      userDatagridUi[Z_USER_DATAGRID_UI.paginationItemsCount] = pageSize;
      userDatagridUi[Z_USER_DATAGRID_UI.usageContextId] = usageContextId;
      userDatagridUi[Z_USER_DATAGRID_UI.tableName] = tableName;
      this.patch(API_ENDPOINTS.paginationUpdate, userDatagridUi).subscribe(_ => {
        resolve(true);
      });
    });
  }

  protected handleError(error: any): Observable<any> {
    if (error.statusCode) { //check if error is ApiData
      // A client-side or network error occurred. Handle it accordingly.
      console.error(`Domain error code: ${error.statusCode}\nError: ${error.message}`);
      this.loggingService.displayError(error.message, true);
      return throwError(error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body message was: ${JSON.stringify(error.error)}`);
      switch (error.status) {
        case 500:
          return throwError('Internal Server Error');
        case 400:
          return throwError('Bad Request');
        case 401:
          return throwError('Not authenticated');
        case 404:
          return throwError('Page Not Found');
        default:
          // return an observable with a user-facing error message
          return throwError(error.error);
      }
    }
    /*return throwError(
      'Something bad happened; please try again later.');*/
  }

  // /**
  //  * Handle Http operation that failed.
  //  * Let the app continue.
  //  * @param operation - name of the operation that failed
  //  * @param result - optional value to return as the observable result
  //  */
  // private handleError<T>(operation = 'operation', result?: T) {
  //   return (error: any): Observable<T> => {
  //
  //     // TODO: send the error to remote logging infrastructure
  //     console.error(error); // log to console instead
  //
  //     // TODO: better job of transforming error for user consumption
  //     this.log(`${operation} failed: ${error.message}`);
  //
  //     // Let the app keep running by returning an empty result.
  //     return of(result as T);
  //   };
  // }

  /** Log a HeroService message with the MessageService */
  protected log(message: string) {
    this.loggingService.displayMessage(message);
    this.loggingService.message.next(`ApiService: ${message}`);
  }
}
