import {createAction, props} from '@ngrx/store';
import {EntityMap, Predicate, Update} from '@ngrx/entity';
import {TaskValidation} from '../../../domain-models/business/task-validation.model';

/**
 * CRUD Actions
 */

export const loadTaskValidations = createAction('[TaskValidation/API] Load TaskValidations', props<{ taskValidations: TaskValidation[] }>());
export const addTaskValidation = createAction('[TaskValidation/API] Add TaskValidation', props<{ taskValidation: TaskValidation }>());
export const setTaskValidation = createAction('[TaskValidation/API] Set TaskValidation', props<{ taskValidation: TaskValidation }>());
export const upsertTaskValidation = createAction('[TaskValidation/API] Upsert TaskValidation', props<{ taskValidation: TaskValidation }>());
export const addTaskValidations = createAction('[TaskValidation/API] Add TaskValidations', props<{ taskValidations: TaskValidation[] }>());
export const upsertTaskValidations = createAction('[TaskValidation/API] Upsert TaskValidations', props<{ taskValidations: TaskValidation[] }>());
export const updateTaskValidation = createAction('[TaskValidation/API] Update TaskValidation', props<{ update: Update<TaskValidation> }>());
export const updateTaskValidations = createAction('[TaskValidation/API] Update TaskValidations', props<{ updates: Update<TaskValidation>[] }>());
export const mapTaskValidations = createAction('[TaskValidation/API] Map TaskValidations', props<{ entityMap: EntityMap<TaskValidation> }>());
export const deleteTaskValidation = createAction('[TaskValidation/API] Delete TaskValidation', props<{ id: number }>());
export const deleteTaskValidations = createAction('[TaskValidation/API] Delete TaskValidations', props<{ ids: number[] }>());
export const deleteTaskValidationsByPredicate = createAction('[TaskValidation/API] Delete TaskValidations By Predicate', props<{ predicate: Predicate<TaskValidation> }>());
export const clearTaskValidations = createAction('[TaskValidation/API] Clear TaskValidations');

/** END of CRUD **/

export const beginGetAllTaskValidations = createAction('[TaskValidation/API] - Begin Get All TaskValidations');
export const getTaskValidationsByTaskId = createAction(
  '[TaskValidation/API] - Begin Get All TaskValidations by TaskId',
  props<{taskId: number}>()
);
export const getCurrentTaskValidations = createAction('[TaskValidation/API] - Begin Get Current Task TaskValidations');
export const errorGetAllTaskValidations = createAction('[API] - Error Get All TaskValidations', props<Error>());

export const updateSelectedTaskValidationId = createAction(
  '[TaskValidation/API] - Save Selected TaskValidation Id',
  props<{ taskValidationId: number }>()
);
