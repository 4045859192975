export enum ApiResponseCodeEnum {
  success = 'S00',
  /** Database **/
  unableToTargetDb = 'D01',
  dataNotFound = 'D02',
  invalidQueryResult = 'D03',
  invalidQueryArgument = 'D04',
  /** Validation **/
  dataAlreadyExists = 'V01',
}
