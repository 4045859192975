import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorkplaceActions} from '../actions';
import {Workplace} from '../../../domain-models/business/workplace.model';


export const workplaceFeatureKey = 'workplace';

export interface State extends EntityState<Workplace> {
  // additional entities state properties
  // selectedWorkplaceId: number | null;
  selectedIds: number[];
}

export function generateId(a: Workplace): number {
  return a.floorDataId;
}

export const adapter: EntityAdapter<Workplace> = createEntityAdapter<Workplace>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  // selectedWorkplaceId: null,
  selectedIds: [],
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(WorkplaceActions.addWorkplace, (state, {workplace}) => {
    return adapter.addOne(workplace, state);
  }),
  on(WorkplaceActions.setWorkplace, (state, {workplace}) => {
    return adapter.setOne(workplace, state);
  }),
  on(WorkplaceActions.upsertWorkplace, (state, {workplace}) => {
    return adapter.upsertOne(workplace, state);
  }),
  on(WorkplaceActions.addWorkplaces, (state, {workplaces}) => {
    return adapter.addMany(workplaces, state);
  }),
  on(WorkplaceActions.upsertWorkplaces, (state, {workplaces}) => {
    return adapter.upsertMany(workplaces, state);
  }),
  on(WorkplaceActions.updateWorkplace, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(WorkplaceActions.updateWorkplaces, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(WorkplaceActions.mapWorkplaces, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(WorkplaceActions.deleteWorkplace, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(WorkplaceActions.deleteWorkplaces, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(WorkplaceActions.deleteWorkplacesByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(WorkplaceActions.loadWorkplaces, (state, {workplaces}) => {
    return adapter.setAll(workplaces, state);
  }),
  on(WorkplaceActions.clearWorkplaces, state => {
    return adapter.removeAll({...state, selectedIds: []});
  }),
  on(WorkplaceActions.updateSelection, (state, {addedId, removedId}) => {
    const alreadySelectedId = state.selectedIds.find(e => e === addedId);
    const idToRemoveIndex = state.selectedIds.indexOf(removedId, 0);

    let cases: number;
    if (addedId && !removedId) {
      if (!alreadySelectedId) {
        cases = 1;
      } else {
        cases = 0;
      }
    } else if (!addedId && removedId) {
      if (idToRemoveIndex > -1) {
        cases = 2;
      } else {
        cases = 0;
      }
    } else if (addedId && removedId) {
      if (!alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 3;
        } else {
          cases = 1;
        }
      } else if (alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 2;
        } else {
          cases = 0;
        }
      }
    } else {
      cases = 0;
    }
    switch (cases) {
      case 0:
        return state;
      case 1:
        return {...state, selectedIds: [...state.selectedIds, addedId]};
      case 2:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1)]};
      case 3:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1), addedId ]};
      default: {
        return state;
      }
    }
  }),
  /** END OF CRUD **/
  on(WorkplaceActions.addToSelection, (state, {id, clearSelection}) => {
    const existingId = state.selectedIds.find(e => e === id);
    if (clearSelection) {
      return {...state, selectedIds: [id]};
    } else {
      if (existingId) {
        return {...state, selectedIds: state.selectedIds};
      } else {
        return {...state, selectedIds: [...state.selectedIds, id]};
      }
    }
  }),
  on(WorkplaceActions.clearSelection, (state) => {
    return {...state, selectedIds: state.selectedIds.slice(0, 0)};
  }),
  on(WorkplaceActions.removeFromSelection, (state, {id}) => {
    return {...state, selectedIds: state.selectedIds.filter(e => e !== id)};
  }),
);

// export const getSelectedWorkplaceId = (state: State) => state.selectedWorkplaceId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectWorkplaceIds = selectIds;
export const selectWorkplaceEntities = selectEntities;
export const selectAllWorkplaces = selectAll;
export const selectWorkplaceTotal = selectTotal;
