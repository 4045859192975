import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const APP_USER = {
  databaseTableName: 'app_user',
  id: 'ApUs_Id',
  directoryId: 'ApUs_DirectoryId',
  code: 'ApUs_Code',
  title: 'ApUs_Title',
  firstName: 'ApUs_FirstName',
  lastName: 'ApUs_LastName',
  roleId: 'ApUs_RoleId',
  isActive: 'ApUs_IsActive',
  policyId: 'ApUs_PolicyId',
  perimeterId: 'ApUs_PerimeterId',
};

export class AppUser {
  constructor(
    public databaseTableName: string,
    public id: number,
    public directoryId: number,
    public code: string,
    public title: string,
    public firstName: string,
    public lastName: string,
    public roleId: number,
    public isActive: boolean,
    public policyId: string,
    public perimeterId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class AppUserAdapter implements Adapter<AppUser> {
  adapt(item: any): AppUser {
    return new AppUser(
      APP_USER.databaseTableName,
      item[APP_USER.id],
      item[APP_USER.directoryId],
      item[APP_USER.code],
      item[APP_USER.title],
      item[APP_USER.firstName],
      item[APP_USER.lastName],
      item[APP_USER.roleId],
      item[APP_USER.isActive],
      item[APP_USER.policyId],
      item[APP_USER.perimeterId]
    );
  }

  encode(item: AppUser): ISingleBackendObj {
    const encoded = {
      TableName: APP_USER.databaseTableName,
      [APP_USER.id]: item.id,
      [APP_USER.directoryId]: item.directoryId,
      [APP_USER.code]: item.code,
      [APP_USER.title]: item.title,
      [APP_USER.firstName]: item.firstName,
      [APP_USER.lastName]: item.lastName,
      [APP_USER.roleId]: item.roleId,
      [APP_USER.isActive]: item.isActive,
      [APP_USER.policyId]: item.policyId,
      [APP_USER.perimeterId]: item.perimeterId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
