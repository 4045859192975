import {createSelector} from '@ngrx/store';
import * as fromRenderer from '../reducers/renderer.reducer';
import {selectSvgState} from '../reducers';
import {selectCurrentTaskId} from '../../project-management/selectors/task.selectors';
import {selectCurrentFloor} from '../../floor-store/selectors/floor.selectors';

export const selectRenderer = createSelector(
  selectSvgState,
  state => state[fromRenderer.rendererFeatureKey]
);

export const selectViewbox = (taskId: number) => createSelector(
  selectRenderer,
  (state) => state.viewBoxes[taskId]
);

export const selectCurrentTaskViewBox = createSelector(
  selectRenderer,
  selectCurrentTaskId,
  (state, currentTaskId) => state.viewBoxes[currentTaskId]
);

export const selectCurrentFloorViewbox = createSelector(
  selectRenderer,
  selectCurrentFloor,
  (state, currentFloor) => state.viewBoxes[currentFloor.taskId]
);

export const selectRoomContourProps = createSelector(
  selectRenderer,
  (state) => state.roomContourProps
);

export const selectGraphicsFloorLoaded = createSelector(
  selectRenderer,
  (state) => state.graphicsFloorLoaded
);

export const selectCurrentTaskGraphicStatus = createSelector(
  selectRenderer,
  (state) => state.currentTaskIsEmpty
);

export const selectLastKnownClickedPoint = createSelector(
  selectRenderer,
  (state) => state.lastKnownClickCoordinates
);

export const selectBlueprint = createSelector(
  selectRenderer,
  (state) => state.blueprint
);

export const selectPointerEvents = createSelector(
  selectRenderer,
  (state) => state.pointerEvents
);

export const selectActiveFloorModelId = createSelector(
  selectRenderer,
  (state) => state.activeFloorModelId
);

export const selectSelectionStatus = createSelector(
  selectRenderer,
  (state) => state.selection
);

export const selectGripDetectionMode = createSelector(
  selectRenderer,
  (state) => state.gripDetectionMode
);

export const selectWithPartitioningFrame = createSelector(
  selectRenderer,
  (state) => state.withPartitioningFrame
);
