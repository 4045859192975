import {Injectable} from '@angular/core';
import {Adapter} from '../adapter';
import {SvgGroup} from '../../svg-factory/models/svg-group.model';
import { TaskFloorModelAdapter} from '../business-extended/task-floor-model.model';
import {SvgEntity} from "../../svg-factory/models/svg-entity.model";

const FLOOR_DATA_CONTOUR = {
  svgPathDTO: 'SvgPathDTO',
  wallIds: 'WallIds',
};

export class ApiFloorDataContour {
  constructor(
    public svgPathDTO: SvgEntity,
    public wallIds: number[],
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiFloorDataContourAdapter implements Adapter<ApiFloorDataContour> {
  adapt(item: any): ApiFloorDataContour {
    return new ApiFloorDataContour(
      item[FLOOR_DATA_CONTOUR.svgPathDTO],
      item[FLOOR_DATA_CONTOUR.wallIds],
    );
  }
}
