import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
  GanttTaskActions,
  ProjectManagementActions,
  TaskActions,
  TaskContributorActions,
  TaskContributorDirectoryActions,
  TaskFloorActions,
  TaskLinkActions
} from '../actions';
import {map, switchMap} from 'rxjs/operators';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {GanttTaskAdapter} from '../../../project-management/models/gantt-task.model';
import {State} from '../../../reducers';


@Injectable()
export class ProjectManagementEffects {

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private ganttTaskAdapter: GanttTaskAdapter,
    private store: Store<State>
  ) {
  }

  beginLoadGanttTasks$ = createEffect(() => this.actions$.pipe(
    ofType(ProjectManagementActions.beginLoadProjectManagement),
    //withLatestFrom(this.store.select(TaskTypeSelectors.selectAllTaskTypes)),
    //filter(([action, taskTypes]) => taskTypes.length > 0),
    switchMap(action =>
      this.projectManagementService.loadTasks().pipe(
        //tap(d => console.log(d)),
        map(apiTasksRootItems => {
          let ganttTasks = apiTasksRootItems.tasks.map(t => this.ganttTaskAdapter.adaptFromTask(t))
          // ganttTasks.map(t => {
          //   t.color = taskTypes.find(type => type.id === t.type).color.toHexRgb();
          // });
          return {apiTasksRootItems: apiTasksRootItems, ganttTasks: ganttTasks};
        }),
        switchMap((data) =>
            [
              TaskActions.loadTasks({tasks: data.apiTasksRootItems.tasks}),
              GanttTaskActions.loadGanttTasks({
                ganttTasks: data.ganttTasks
              }),
              TaskLinkActions.loadTaskLinks({taskLinks: data.apiTasksRootItems.taskLinks}),
              TaskContributorDirectoryActions.addTaskContributorDirectoryItems({taskContributorDirectoryItems: data.apiTasksRootItems.taskContributorDirectoryItems}),
              TaskContributorActions.loadTaskContributors({taskContributors: data.apiTasksRootItems.taskContributors}),
              TaskFloorActions.loadTaskFloors({taskFloors: data.apiTasksRootItems.taskFloors}),
            ]
        ),
      )
    )));
}
