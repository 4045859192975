import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TaskActions} from '../actions';
import {Task} from 'src/app/domain-models/business/task.model';

export const taskFeatureKey = 'tasks';

export interface State extends EntityState<Task> {
  selectedTaskId: number | null;
}

export const adapter: EntityAdapter<Task> = createEntityAdapter<Task>();

export const initialState: State = adapter.getInitialState({
  selectedTaskId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskActions.addTask, (state, { task }) => {
    return adapter.addOne(task, state);
  }),
  on(TaskActions.setTask, (state, { task }) => {
    return adapter.setOne(task, state);
  }),
  on(TaskActions.upsertTask, (state, { task }) => {
    return adapter.upsertOne(task, state);
  }),
  on(TaskActions.addTasks, (state, { tasks }) => {
    return adapter.addMany(tasks, state);
  }),
  on(TaskActions.upsertTasks, (state, { tasks }) => {
    return adapter.upsertMany(tasks, state);
  }),
  on(TaskActions.updateTask, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskActions.updateTasks, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskActions.mapTasks, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskActions.deleteTask, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskActions.deleteTasks, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskActions.deleteTasksByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskActions.loadTasks, (state, { tasks }) => {
    return adapter.setAll(tasks, state);
  }),
  on(TaskActions.clearTasks, state => {
    return adapter.removeAll({ ...state, selectedTaskId: null });
  }),
  /** END OF CRUD **/
  on(TaskActions.updateSelectedTaskId, (state, {taskId}) => {
    return ({...state, selectedTaskId: taskId});
  }),
);

export const getSelectedTaskId = (state: State) => state.selectedTaskId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of task ids
export const selectTaskIds = selectIds;

// select the dictionary of task entities
export const selectTaskEntities = selectEntities;

// select the array of tasks
export const selectAllTasks = selectAll;

// select the total task count
export const selectTaskTotal = selectTotal;
