import {Injectable} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from '../../core/services/logging.service';
import {ROOM_LAYOUT_TYPE, RoomLayoutType, RoomLayoutTypeAdapter} from '../../domain-models/business/room-layout-type.model';
import {Observable} from 'rxjs';
import {API_ENDPOINTS} from '../api-endpoints';
import {map} from 'rxjs/operators';
import {ApiData} from '../../core/models/api-data.model';
import {
  ROOM_ACTIVITY_STATUS_TYPE,
  RoomActivityStatusType,
  RoomActivityStatusTypeAdapter
} from '../../domain-models/business/room-activity-status-type.model';
import {
  ROOM_ATTRIBUTION_TYPE,
  RoomAttributionType,
  RoomAttributionTypeAdapter
} from '../../domain-models/business/room-attribution-type.model';
import {PERIMETER, Perimeter, PerimeterAdapter} from '../../domain-models/business/perimeter.model';
import {BusinessUnit, BusinessUnitAdapter} from '../../domain-models/business/business-unit.model';
import {LanguageItem, LanguageItemAdapter} from '../../domain-models/core/z-language-item.model';
import {DbColumn, DbColumnAdapter} from '../../domain-models/core/z-db-column.model';
import {FLOOR_MODEL, FloorModel, FloorModelAdapter} from '../../domain-models/business/floor-model.model';
import {FLOOR_CATALOG, FloorCatalog, FloorCatalogAdapter} from '../../domain-models/business/floor-catalog.model';
import {FLOOR_DOOR_STYLE, FloorDoorStyle, FloorDoorStyleAdapter} from '../../domain-models/business/floor-door-style.model';
import {FLOOR_WALL_STYLE, FloorWallStyle, FloorWallStyleAdapter} from '../../domain-models/business/floor-wall-style.model';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends ApiService {

  constructor(http: HttpClient,
              loggingService: LoggingService,
              private roomLayoutTypeAdapter: RoomLayoutTypeAdapter,
              private roomActivityStatusTypeAdapter: RoomActivityStatusTypeAdapter,
              private roomAttributionTypeAdapter: RoomAttributionTypeAdapter,
              private perimeterAdapter: PerimeterAdapter,
              private businessUnitAdapter: BusinessUnitAdapter,
              private languageItemAdapter: LanguageItemAdapter,
              private dbColumnAdapter: DbColumnAdapter,
              private floorModelAdapter: FloorModelAdapter,
              private floorCatalogAdapter: FloorCatalogAdapter,
              private floorDoorStyleAdapter: FloorDoorStyleAdapter,
              private floorWallStyleAdapter: FloorWallStyleAdapter,
  ) {
    super(http, loggingService);
  }

  getAllRoomLayoutTypes(): Observable<RoomLayoutType[]> {
    return this.get(API_ENDPOINTS.dynT + `RoomLayoutType`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomLayoutTypeAdapter.adapt(item));
        })
      );
  }

  getAllRoomLayoutTypesLastItems(): Observable<RoomLayoutType[]> {
    return this.get(`${API_ENDPOINTS.dynH}${ROOM_LAYOUT_TYPE.databaseTableName}/lastitems`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomLayoutTypeAdapter.adapt(item));
        })
      );
  }

  getAllRoomActivityStatusTypes(): Observable<RoomActivityStatusType[]> {
    //console.log('SharedService.getAllRoomActivityStatusTypes');
    return this.get(`${API_ENDPOINTS.dynT}${ROOM_ACTIVITY_STATUS_TYPE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomActivityStatusTypeAdapter.adapt(item));
        })
      );
  }

  getAllFloorDoorStyles(): Observable<FloorDoorStyle[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_DOOR_STYLE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorDoorStyleAdapter.adapt(item));
        })
      );
  }

  getAllFloorWallStyles(): Observable<FloorWallStyle[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_WALL_STYLE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorWallStyleAdapter.adapt(item));
        })
      );
  }

  getAllRoomAttributionTypes(): Observable<RoomAttributionType[]> {
    //console.log('SharedService.getAllRoomAttributionTypes');
    return this.get(`${API_ENDPOINTS.dynT}${ROOM_ATTRIBUTION_TYPE.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomAttributionTypeAdapter.adapt(item));
        })
      );
  }

  getAllPerimeters(): Observable<Perimeter[]> {
    // console.log('SharedService.getAllPerimeters');
    return this.get(`${API_ENDPOINTS.dynT}${PERIMETER.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.perimeterAdapter.adapt(item));
        })
      );
  }

  getAllBusinessUnits(): Observable<BusinessUnit[]> {
    return this.get(API_ENDPOINTS.dynT + `business_unit`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.businessUnitAdapter.adapt(item));
        })
      );
  }

  getAllLanguageItems(): Observable<LanguageItem[]> {
    return this.get(API_ENDPOINTS.dynT + `z_language_item_view`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.languageItemAdapter.adapt(item));
        })
      );
  }

  getAllFloorModels(): Observable<FloorModel[]> {
    // console.log('SharedService.getAllFloorModels');
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_MODEL.databaseTableName}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorModelAdapter.adapt(item));
        })
      )
  }

  getFloorCatalogItems(): Observable<FloorCatalog[]> {
    return this.get(`${API_ENDPOINTS.dynT}${FLOOR_CATALOG.databaseTableName}`).pipe(
      map((data: ApiData) => data.payload.map(item => this.floorCatalogAdapter.adapt(item)))
    );
  }

  getAllDbColumns(): Observable<DbColumn[]> {
    //console.log('SharedService.getAllDbColumns');
    return this.get(API_ENDPOINTS.dynT + `z_db_column`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.dbColumnAdapter.adapt(item));
        })
      );
  }
}
