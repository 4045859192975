import {Action, combineReducers, createFeatureSelector} from '@ngrx/store';
import * as fromCore from '../reducers/core.reducer';
import * as fromRoot from '../../../reducers';

export const coreFeatureKey = 'core';

export interface CoreState {
  [fromCore.coreFeatureKey]: fromCore.State;
}

export interface State extends fromRoot.State {
  [coreFeatureKey]: CoreState;
}

/** Provide reducer in AoT-compilation happy way */
export function reducers(state: CoreState | undefined, action: Action) {
  return combineReducers({
    [fromCore.coreFeatureKey]: fromCore.reducer
  })(state, action);
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */
export const selectCoreState = createFeatureSelector<State, CoreState>(
  coreFeatureKey
);
