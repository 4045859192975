import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {first, map, switchMap} from 'rxjs/operators';
import * as RoomAllocationActions from '../actions/room-allocation.actions';
import {FloorService} from '../../../floor/services/floor.service';
import {Store} from '@ngrx/store';
import {TaskSelectors} from '../../project-management/selectors';
import {State} from '../../../reducers';
import {BuildingService} from '../../../building/services/building.service';
import {RoomAllocationSelectors} from '../selectors';
import {EMPTY} from 'rxjs';

@Injectable()
export class RoomAllocationEffects {

  // getCurrentTaskFloorRoomAllocations$ = createEffect(() => this.actions$.pipe(
  //   ofType(RoomAllocationActions.beginGetCurrentFloorTaskRoomAllocations),
  //   withLatestFrom(this.store.select(TaskSelectors.selectCurrentTaskId)),
  //   exhaustMap(([action, taskId]) =>
  //     this.floorService.getRoomAllocationsByTaskId(taskId).pipe(
  //       map((roomAllocations) => {
  //         return RoomAllocationActions.addRoomAllocations({ roomAllocations: roomAllocations });
  //       })
  //     )
  //   ))
  // );

  getCurrentTaskFloorRoomAllocations$ = createEffect(() => this.actions$.pipe(
    ofType(RoomAllocationActions.beginGetCurrentTaskRoomAllocations),
    switchMap((action) =>
      this.store.select(TaskSelectors.selectCurrentTaskId).pipe(
        switchMap(taskId => {
          if (taskId) {
            return this.floorService.getRoomAllocationsByTaskId(taskId).pipe(
              map((roomAllocations) => {
                return RoomAllocationActions.addRoomAllocations({roomAllocations: roomAllocations});
              })
            );
          } else {
            return EMPTY;
          }
        })
      )
    ))
  );

  getTaskRoomAllocationsByTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(RoomAllocationActions.beginGetTaskRoomAllocationsByTaskId),
    switchMap((action) =>
      this.floorService.getRoomAllocationsByTaskId(action.taskId).pipe(
        map((roomAllocations) => {
          return RoomAllocationActions.addRoomAllocations({roomAllocations: roomAllocations});
        })
      )
    ))
  );

  getRoomAllocationsByBuildingId$ = createEffect(() => this.actions$.pipe(
    ofType(RoomAllocationActions.beginGetRoomAllocationsByBuildingId),
    switchMap((action) =>
      this.buildingService.getRoomAllocationsByBuildingId(action.buildingId).pipe(
        map((roomAllocations) => {
          return RoomAllocationActions.addRoomAllocations({roomAllocations: roomAllocations});
        })
      )
    ))
  );

  deleteRoomAllocationsByFloorDataId$ = createEffect(() => this.actions$.pipe(
    ofType(RoomAllocationActions.deleteRoomAllocationsByFloorDataId),
    switchMap(action => this.store.select(RoomAllocationSelectors.selectRoomAllocationByFloorDataId(action.floorDataId)).pipe(
      first(),
      map(roomAllocations => RoomAllocationActions.deleteRoomAllocations({ids: roomAllocations.map(r => `${r.floorDataId}-${r.businessUnitId}`)}))
    )),
  ));

  deleteRoomAllocationsByFloorDataIds$ = createEffect(() => this.actions$.pipe(
    ofType(RoomAllocationActions.deleteRoomAllocationsByFloorDataIds),
    switchMap(action => this.store.select(RoomAllocationSelectors.selectRoomAllocationByFloorDataIds(action.floorDataIds)).pipe(
      first(),
      map(roomAllocations => RoomAllocationActions.deleteRoomAllocations({ids: roomAllocations.map(r => `${r.floorDataId}-${r.businessUnitId}`)}))
    )),
  ));

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private buildingService: BuildingService,
    private store: Store<State>
  ) {
  }
}
