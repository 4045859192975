<mat-toolbar color="primary" class="mat-elevation-z4">
  <mat-toolbar-row>
    <button mat-icon-button *ngIf="mobileQuery.matches" (click)="mobileMenuToggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a [routerLink]="''" class="logo" matRipple>
      <img src="assets/img/logo.png">
    </a>
    <ul *ngIf="!mobileQuery.matches" class="top-menu">
      <ng-container *ngFor="let item of (menuItems$ | async)">
        <li (menuClosed)="toggleMenuItem(item)" (menuOpened)="toggleMenuItem(item)" [matMenuTriggerFor]="appMenu">
          <a class="nav-link">
              <span [class.selected]="item.isOpened" class="nav-link-inner" matRipple>
                {{ item.label }}
                <ng-container *ngIf="item.items">
                <mat-icon *ngIf="!item.isOpened">expand_more</mat-icon>
                <mat-icon *ngIf="item.isOpened">expand_less</mat-icon>
              </ng-container>
              </span>
          </a>
        </li>
        <mat-menu #appMenu="matMenu">
          <button (click)="onMenuItemClick(sub)" *ngFor="let sub of item['items']"
                  mat-menu-item>{{sub.label}}</button>
        </mat-menu>
      </ng-container>
    </ul>
    <span class="spacer" *ngIf="mobileQuery.matches"></span>
    <div class="right-card" (click)="rightDrawerToggle()"
         [ngClass]="{'right-card-active' : toggleDrawer, 'mat-elevation-z4': toggleDrawer}">
      <echo-user-icon></echo-user-icon>
      <img *ngIf="(client$ | async)" class="client-logo" alt="logo client"
           [src]="'https://static.echo-on.fr/' + (client$ | async)?.CompanyId +'/logo.jpg'">
    </div>
  </mat-toolbar-row>
</mat-toolbar>
