import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserRoutingModule} from './user-routing.module';
import {UserIconComponent} from './user-icon/user-icon.component';
import {ProfileComponent} from './profile/profile.component';
import {UserPanelComponent} from './user-panel/user-panel.component';
import {SharedModule} from '../shared/shared.module';

@NgModule({
  declarations: [
    UserIconComponent,
    ProfileComponent,
    UserPanelComponent,
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    //FormsModule,
    //MaterialModule,
    //FlexLayoutModule,
    SharedModule
  ],
  exports: [
    ProfileComponent,
    UserIconComponent,
    UserPanelComponent
  ],
  providers: []
})
export class UserModule {
}
