import {createAction, props} from '@ngrx/store';
import {RoomAllocation} from '../../../domain-models/business/room-allocation.model';
import {EntityMap, Predicate, Update} from '@ngrx/entity';

/**
 * CRUD Actions
 */

export const loadRoomAllocations = createAction('[RoomAllocation/API] Load RoomAllocations', props<{ roomAllocations: RoomAllocation[] }>());
export const addRoomAllocation = createAction('[RoomAllocation/API] Add RoomAllocation', props<{ roomAllocation: RoomAllocation }>());
export const setRoomAllocation = createAction('[RoomAllocation/API] Set RoomAllocation', props<{ roomAllocation: RoomAllocation }>());
export const upsertRoomAllocation = createAction('[RoomAllocation/API] Upsert RoomAllocation', props<{ roomAllocation: RoomAllocation }>());
export const addRoomAllocations = createAction('[RoomAllocation/API] Add RoomAllocations', props<{ roomAllocations: RoomAllocation[] }>());
export const upsertRoomAllocations = createAction('[RoomAllocation/API] Upsert RoomAllocations', props<{ roomAllocations: RoomAllocation[] }>());
export const updateRoomAllocation = createAction('[RoomAllocation/API] Update RoomAllocation', props<{ update: Update<RoomAllocation> }>());
export const updateRoomAllocations = createAction('[RoomAllocation/API] Update RoomAllocations', props<{ updates: Update<RoomAllocation>[] }>());
export const mapRoomAllocations = createAction('[RoomAllocation/API] Map RoomAllocations', props<{ entityMap: EntityMap<RoomAllocation> }>());
export const deleteRoomAllocation = createAction('[RoomAllocation/API] Delete RoomAllocation', props<{ id: string }>());
export const deleteRoomAllocations = createAction('[RoomAllocation/API] Delete RoomAllocations', props<{ ids: string[] }>());
export const deleteRoomAllocationsByPredicate = createAction('[RoomAllocation/API] Delete RoomAllocations By Predicate', props<{ predicate: Predicate<RoomAllocation> }>());
export const clearRoomAllocations = createAction('[RoomAllocation/API] Clear RoomAllocations');

/** END of CRUD **/

export const beginGetCurrentTaskRoomAllocations = createAction('[API] - Get Current Floor Room Allocations by taskId');
export const beginGetTaskRoomAllocationsByTaskId = createAction('[API] - Get Room Allocations by taskId', props<{ taskId: number }>());
export const beginGetRoomAllocationsByBuildingId = createAction('[API] - Get Room Allocations by buildingId', props<{ buildingId: number }>());

export const errorGetAllRoomAllocations = createAction('[API] - Error Get All Room Allocations', props<Error>());

export const updateSelectedRoomAllocationId = createAction(
  '[Room/API] - Save Selected Room Id',
  props<{ roomId: number }>()
);

export const deleteRoomAllocationsByFloorDataId = createAction(
  '[RoomAllocations/API] - Delete RoomAllocations by FloorDataId',
  props<{ floorDataId: number }>()
);

export const deleteRoomAllocationsByFloorDataIds = createAction(
  '[RoomAllocations/API] - Delete RoomAllocations by FloorDataIds',
  props<{ floorDataIds: number[] }>()
);
