import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import * as RoomActions from '../actions/room.actions';
import {FloorService} from '../../../floor/services/floor.service';
import {TaskSelectors} from '../../project-management/selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';
import {FloorDataActions, RendererActions} from '../../svg-store/actions';
import {RoomActivityStatusTypeEnum} from '../../../domain-models/business/room-activity-status-type.model';
import {RoomAttributionTypeEnum} from '../../../domain-models/business/room-attribution-type.model';
import {FloorActions, RoomAllocationActions} from '../actions';
import {Update} from '@ngrx/entity';
import {SvgGroup} from '../../../svg-factory/models/svg-group.model';

@Injectable()
export class RoomEffects {

  getCurrentFloorRooms$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.beginGetCurrentFloorTaskRooms),
    withLatestFrom(this.store.select(TaskSelectors.selectCurrentTaskId)),
    switchMap(([action, taskId]) =>
      this.floorService.getRoomsByTaskId(taskId).pipe(
        map((rooms) => RoomActions.addRooms({rooms: rooms}))
      )
    ))
  );

  getRoomsByTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.beginGetFloorTaskRoomsByTaskId),
    concatMap((action) =>
      this.floorService.getRoomsByTaskId(action.taskId).pipe(
        map((rooms) => RoomActions.addRooms({rooms: rooms}))
      )
    ))
  );

  updateRoom$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoom),
    //debounceTime(50), // debounce in case of batch individual Room updates
    /**
     * Reload IDS to correctly re render FloorLayers in "Thématique"
     */
    map(action => FloorActions.beginGetFloorCurrentTaskAreaIds())
  ));

  updateRooms$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRooms),
    /**
     * Reload IDS to correctly re render FloorLayers in "Thématique"
     */
    map(action => FloorActions.beginGetFloorCurrentTaskAreaIds())
  ));

  updateRoomLabel$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomLabel),
    switchMap(action => this.floorService.updateRoomLabel(action.id, action.code).pipe(
      concatMap(svgGroup => [
          FloorDataActions.updateFloorData({
            svgGroup: {
              id: svgGroup.id,
              changes: svgGroup
            }
          }),
          RoomActions.updateRoom({
            update: {
              id: action.id,
              changes: {
                code: action.code
              }
            }
          }),
        ]
      ))
    ))
  );

  updateRoomLayoutType$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomLayoutType),
    /** mergeMap instead of switchMap to avoid race condition **/
    mergeMap(action => this.floorService.updateRoomLayoutType(action.id, action.roomLayoutTypeId).pipe(
      map(data => RoomActions.updateRoom({
        update: {
          id: action.id,
          changes: {
            layoutTypeId: action.roomLayoutTypeId
          }
        }
      }))
    ))
    )
  );

  updateRoomsLayoutType$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomsLayoutType),
    /** mergeMap instead of switchMap to avoid race condition **/
    mergeMap(action => this.floorService.updateRoomsLayoutType(action.ids, action.roomLayoutTypeId).pipe(
      map(data => {
        const updates = [];
        action.ids.map(id => {
          updates.push({
            id: id,
            changes: {
              layoutTypeId: action.roomLayoutTypeId
            }
          });
        });
        return RoomActions.updateRooms({updates: updates});
      })
    ))
    )
  );

  updateRoomAllocations$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomAllocations),
    tap(action => this.store.dispatch(RoomAllocationActions.deleteRoomAllocationsByFloorDataId({floorDataId: action.id}))),
    switchMap(action => this.floorService.updateRoomAllocations(action.id, action.roomAllocations).pipe(
      switchMap(roomAllocations => [
        RoomAllocationActions.addRoomAllocations({roomAllocations: roomAllocations}),
        RoomActions.updateRoom({
          update: {
            id: action.id,
            changes: {
              activityStatusTypeId: RoomActivityStatusTypeEnum.Usable,
              attributionTypeId: RoomAttributionTypeEnum.Allocation,
            }
          }
        })
      ])
      )
    )
  ));

  updateRoomsAllocations$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomsAllocations),
    tap(action => this.store.dispatch(RoomAllocationActions.deleteRoomAllocationsByFloorDataIds({floorDataIds: action.ids}))),
    switchMap(action => this.floorService.updateRoomsAllocations(action.ids, action.roomAllocations).pipe(
      switchMap(roomAllocations => {
        const updates = [];
        action.ids.map(id => {
          updates.push({
            id: id,
            changes: {
              activityStatusTypeId: RoomActivityStatusTypeEnum.Usable,
              attributionTypeId: RoomAttributionTypeEnum.Allocation,
            }
          });
        });
        return [
          RoomAllocationActions.addRoomAllocations({roomAllocations: roomAllocations}),
          RoomActions.updateRooms({updates: updates})
        ];
      }),
      )
    )
  ));

  updateRoomSharing$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomSharing),
    switchMap(action => this.floorService.updateRoomSharing(action.id, action.perimeterId).pipe(
      map(data => RoomActions.updateRoom({
        update: {
          id: action.id,
          changes: {
            activityStatusTypeId: RoomActivityStatusTypeEnum.Usable,
            attributionTypeId: RoomAttributionTypeEnum.Sharing,
          }
        }
      }))
    )),
  ));

  updateRoomsSharing$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.updateRoomsSharing),
    switchMap(action => this.floorService.updateRoomsSharing(action.ids, action.perimeterId).pipe(
      map(data => {
        const updates = [];
        action.ids.map(id => {
          updates.push({
            id: id,
            changes: {
              activityStatusTypeId: RoomActivityStatusTypeEnum.Usable,
              attributionTypeId: RoomAttributionTypeEnum.Sharing,
            }
          });
        });
        return RoomActions.updateRooms({updates: updates});
      })
    )),
  ));

  wipeRoom$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.wipeRoom),
    switchMap(action => this.floorService.wipeRoom(action.id).pipe(
      map(data => RoomActions.updateRoom({
        update: {
          id: action.id,
          changes: {
            activityStatusTypeId: RoomActivityStatusTypeEnum.Usable,
            attributionTypeId: RoomAttributionTypeEnum.Exploitation,
          }
        }
      }))
    )),
  ));

  wipeRooms$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.wipeRooms),
    switchMap(action => this.floorService.wipeRooms(action.ids).pipe(
      map(data => {
        const updates = [];
        action.ids.map(id => {
          updates.push({
            id: id,
            changes: {
              activityStatusTypeId: RoomActivityStatusTypeEnum.Usable,
              attributionTypeId: RoomAttributionTypeEnum.Exploitation,
            }
          });
        });
        return RoomActions.updateRooms({updates: updates});
      })
    )),
  ));

  discardRoom$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.discardRoom),
    switchMap(action => this.floorService.discardRoom(action.id, action.activityStatusTypeId).pipe(
      map(data => RoomActions.updateRoom({
        update: {
          id: action.id,
          changes: {
            activityStatusTypeId: action.activityStatusTypeId,
            attributionTypeId: RoomAttributionTypeEnum.None,
          }
        }
      }))
    )),
  ));

  discardRooms$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.discardRooms),
    switchMap(action => this.floorService.discardRooms(action.ids, action.activityStatusTypeId).pipe(
      map(data => {
        const updates = [];
        action.ids.map(id => {
          updates.push({
            id: id,
            changes: {
              activityStatusTypeId: action.activityStatusTypeId,
              attributionTypeId: RoomAttributionTypeEnum.None,
            }
          });
        });
        return RoomActions.updateRooms({updates: updates});
      })
    )),
  ));

  deleteRoomFromSvg$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.deleteRoomFromSvg),
    switchMap(action => this.floorService.deleteRoom(action.id).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        return [
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates}),
          RendererActions.resetSelection()
        ];
      })
    )),
  ));

  deleteRoomsFromSvg$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActions.deleteRoomsFromSvg),
    switchMap(action => this.floorService.deleteRooms(action.ids).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        return [
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates}),
          RendererActions.resetSelection()
        ];
      })
    )),
  ));


  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>,
  ) {
  }
}
