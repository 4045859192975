import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TaskTypeActions} from '../actions';
import {TaskType} from '../../../domain-models/business/task-type.model';

export const taskTypeFeatureKey = 'taskTypes';

export interface State extends EntityState<TaskType> {
  selectedTaskTypeId: number | null;
}

export const adapter: EntityAdapter<TaskType> = createEntityAdapter<TaskType>();

export const initialState: State = adapter.getInitialState({
  selectedTaskTypeId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskTypeActions.addTaskType, (state, { taskType }) => {
    return adapter.addOne(taskType, state);
  }),
  on(TaskTypeActions.setTaskType, (state, { taskType }) => {
    return adapter.setOne(taskType, state);
  }),
  on(TaskTypeActions.upsertTaskType, (state, { taskType }) => {
    return adapter.upsertOne(taskType, state);
  }),
  on(TaskTypeActions.addTaskTypes, (state, { taskTypes }) => {
    return adapter.addMany(taskTypes, state);
  }),
  on(TaskTypeActions.upsertTaskTypes, (state, { taskTypes }) => {
    return adapter.upsertMany(taskTypes, state);
  }),
  on(TaskTypeActions.updateTaskType, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskTypeActions.updateTaskTypes, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskTypeActions.mapTaskTypes, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskTypeActions.deleteTaskType, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskTypeActions.deleteTaskTypes, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskTypeActions.deleteTaskTypesByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskTypeActions.loadTaskTypes, (state, { taskTypes }) => {
    return adapter.setAll(taskTypes, state);
  }),
  on(TaskTypeActions.clearTaskTypes, state => {
    return adapter.removeAll({ ...state, selectedTaskTypeId: null });
  }),
  /** END OF CRUD **/
  on(TaskTypeActions.updateSelectedTaskTypeId, (state, {taskTypeId}) => {
    return ({...state, selectedTaskTypeId: taskTypeId});
  }),
);

export const getSelectedTaskTypeId = (state: State) => state.selectedTaskTypeId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskType ids
export const selectTaskTypeIds = selectIds;

// select the dictionary of taskType entities
export const selectTaskTypeEntities = selectEntities;

// select the array of taskTypes
export const selectAllTaskTypes = selectAll;

// select the total taskType count
export const selectTaskTypeTotal = selectTotal;
