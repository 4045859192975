import {createSelector} from '@ngrx/store';
import * as fromTaskContributorDirectory from '../reducers/task-contributor-directory.reducer';
import {selectProjectManagementState} from '../reducers';
import {selectCurrentTaskContributors} from './task-contributor.selectors';

/** Select store slice **/

export const selectTaskContributorDirectory = createSelector(
  selectProjectManagementState,
  state => state[fromTaskContributorDirectory.taskContributorDirectoryFeatureKey]
);

/** Basics selectors **/

export const selectTaskContributorDirectoryIds = createSelector(
  selectTaskContributorDirectory,
  fromTaskContributorDirectory.selectTaskContributorDirectoryIds
);
export const selectTaskContributorDirectoryEntities = createSelector(
  selectTaskContributorDirectory,
  fromTaskContributorDirectory.selectTaskContributorDirectoryEntities
);
export const selectAllTaskContributorDirectoryItems = createSelector(
  selectTaskContributorDirectory,
  fromTaskContributorDirectory.selectAllTaskContributorDirectoryItems
);
export const selectTaskContributorDirectoryTotal = createSelector(
  selectTaskContributorDirectory,
  fromTaskContributorDirectory.selectTaskContributorDirectoryTotal
);
export const selectCurrentTaskContributorDirectoryId = createSelector(
  selectTaskContributorDirectory,
  fromTaskContributorDirectory.getSelectedTaskContributorDirectoryId
);

export const selectCurrentTaskContributorDirectory = createSelector(
  selectTaskContributorDirectoryEntities,
  selectCurrentTaskContributorDirectoryId,
  (taskContributorEntities, taskContributorId) => taskContributorEntities[taskContributorId]
);

/** Advanced selectors **/

export const selectTaskContributorDirectoryByUserCode = (code: string) => createSelector(
  selectAllTaskContributorDirectoryItems,
  (directory) => directory.find(d => d.code === code)
);

export const selectTaskContributorDirectoryById = (id: number) => createSelector(
  selectTaskContributorDirectoryEntities,
  (entities) => entities[id]
);

export const selectTaskContributorDirectoryItemsById = (ids: number[]) => createSelector(
  selectAllTaskContributorDirectoryItems,
  (taskContributorDirectoryItems) =>
    taskContributorDirectoryItems.filter(taCrDi => ids.find(id => id === taCrDi.id))
);

export const selectCurrentTaskContributorDirectoryItems = createSelector(
  selectCurrentTaskContributors,
  selectAllTaskContributorDirectoryItems,
  (currentTaskContributors, taskContributorDirectoryItems) =>
    taskContributorDirectoryItems.filter(taCrDi => currentTaskContributors.find(taCr => taCrDi.id === taCr.contributorId))
);
