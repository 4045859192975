// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {create} from 'rxjs-spy';

export const environment = {
  production: false,
  postLogoutRedirectUri: 'http://localhost:4200',
  //apiUrl: 'https://34.77.114.189/',
  apiUrl: 'https://ecsy.echo-on.fr/api/',
  scope: 'openid profile ecsy.back policy.back ticket.service geometry.service offline_access',
};

// //we enable RXjs Spy on non production bulds only
// const spy = create();
// // we call show for two purposes: first is to log to the console an empty snapshot so we can see that everything is working as expected, then to suppress unused variable usage (the latter is a convention on mine)
// spy.show();


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
