import {createReducer, on} from '@ngrx/store';
import {BuildingUiActions} from '../actions';

export const buildingUiFeatureKey = 'buildingUi';

export interface State {
  displayThemeId: number | null;
  //selectedTaskId: number | null;

  roomLayoutTypeIds: number[];
  activityStatusTypeIds: number[];
  attributionTypeIds: number[];
  businessUnitIds: number[];
}

const initialState: State = {
  displayThemeId: 1,
  //selectedTaskId: null,

  roomLayoutTypeIds: [],
  activityStatusTypeIds: [],
  attributionTypeIds: [],
  businessUnitIds: [],

};

export const reducer = createReducer(
  initialState,
  on(BuildingUiActions.setCurrentBuildingRoomLayoutTypeIds, (state, {ids}) => {
    return {...state, roomLayoutTypeIds: ids};
  }),
  on(BuildingUiActions.setCurrentBuildingActivityStatusTypeIds, (state, {ids}) => {
    return {...state, activityStatusTypeIds: ids};
  }),
  on(BuildingUiActions.setCurrentBuildingAttributionTypeIds, (state, {ids}) => {
    return {...state, attributionTypeIds: ids};
  }),
  on(BuildingUiActions.setCurrentBuildingBusinessUnitIds, (state, {ids}) => {
    return {...state, businessUnitIds: ids};
  }),
  on(BuildingUiActions.clearBuildingUiContextState, (state) => {
    return initialState;
  }),
  // on(BuildingUiActions.updateSelectedTaskId, (state, {taskId}) => {
  //   return ({...state, selectedTaskId: taskId});
  // }),
);

// export function reducer(state: State | undefined, action: Action) {
//   return reducer(state, action);
// }
