import {createSelector} from '@ngrx/store';
import * as fromTaskType from '../reducers/task-type.reducer';
import {selectProjectManagementState} from '../reducers';

/** Select store slice **/

export const selectTaskTypes = createSelector(
  selectProjectManagementState,
  state => state[fromTaskType.taskTypeFeatureKey]
);

/** Basics selectors **/

export const selectTaskTypeIds = createSelector(
  selectTaskTypes,
  fromTaskType.selectTaskTypeIds
);
export const selectTaskTypeEntities = createSelector(
  selectTaskTypes,
  fromTaskType.selectTaskTypeEntities
);
export const selectAllTaskTypes = createSelector(
  selectTaskTypes,
  fromTaskType.selectAllTaskTypes
);
export const selectTaskTypeTotal = createSelector(
  selectTaskTypes,
  fromTaskType.selectTaskTypeTotal
);
export const selectCurrentTaskTypeId = createSelector(
  selectTaskTypes,
  fromTaskType.getSelectedTaskTypeId
);

export const selectCurrentTaskType = createSelector(
  selectTaskTypeEntities,
  selectCurrentTaskTypeId,
  (taskTypeEntities, taskTypeId) => taskTypeEntities[taskTypeId]
);

/** Advanced selectors **/

export const selectTaskTypeById = (id: number) => createSelector(
  selectTaskTypeEntities,
  (entities) => {
    return entities[id];
  }
);
