import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, of} from 'rxjs';
import {concatMap, distinct, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {FloorCatalogActions, FloorDataActions, GraphicsFloorActions, RendererActions, TaskFloorModelActions, UsesActions} from '../actions';
import {TaskActions} from '../../project-management/actions';
import {
  FloorActions,
  RoomActions,
  RoomAllocationActions,
  UiContextActions,
  WorkplaceActions,
  WorkplaceAllocationActions
} from '../../floor-store/actions';
import {FloorSelectors} from '../../floor-store/selectors';
import {FloorService} from '../../../floor/services/floor.service';
import {State} from '../../../reducers';
import {Store} from '@ngrx/store';
import {Floor} from '../../../domain-models/business/floor.model';
import {FloorModelEnum} from '../../../domain-models/business/floor-model.model';
import {DisplayThemeItemActions} from '../../shared-store/actions';
import {LegendContextEnum} from '../../../domain-models/models/legend-context.enum';

@Injectable()
export class GraphicsFloorEffects {

  getGraphicsFloorFromRouterState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphicsFloorActions.beginGetGraphicsFloorFromRouterState),
      withLatestFrom(this.store.select(state => state.router)),
      switchMap(([action, route]) => {
        /** Cast route.state.params String to Number */
        const floorId = Number(route.state.params['floorId']);
        this.store.dispatch(GraphicsFloorActions.clearSvgState());
        this.store.dispatch(FloorActions.updateSelectedFloorId({floorId: floorId}));
        return this.store.select(FloorSelectors.selectCurrentFloor).pipe(
          switchMap(floor => {
            if (floor !== undefined) {
              /** f.taskId is null only when for initial Blueprint Import tasks **/
              return [
                DisplayThemeItemActions.setDisplayThemeLegendContext({id: LegendContextEnum.Floor}),
                TaskActions.updateSelectedTaskId({taskId: floor.taskId}),
                UiContextActions.addDisplayedTask({id: floor.taskId}),
                UiContextActions.addDisplayedTaskVisibility({id: floor.taskId}),
                GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: floor.taskId})
              ];
            } else {
              return EMPTY;
            }
          }),
        );
      }),
    ),
  );

  // getGraphicsFloorFromRouterState$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(GraphicsFloorActions.beginGetGraphicsFloorFromRouterState),
  //     withLatestFrom(this.store.select(state => state.router)),
  //     mergeMap(([action, route]) => {
  //       /** Cast route.state.params String to Number */
  //       const floorId = Number(route.state.params['floorId']);
  //       this.store.dispatch(GraphicsFloorActions.clearSvgState());
  //       this.store.dispatch(FloorActions.updateSelectedFloorId({floorId: floorId}));
  //       return this.store.select(FloorSelectors.selectCurrentFloor).pipe(
  //         tap(a => console.log(a)),
  //         /** Wether FloorStore is loaded or not and currentfloor selected */
  //         filter(x => x !== undefined),
  //         /** Ensure action isn't triggered twice when floor changes (FLOOR RELATED ACTIONS <Update>) **/
  //         distinct((f: Floor) => f.id),
  //         concatMap((floor) => {
  //           /** f.taskId is null only when for initial Blueprint Import tasks **/
  //           return [
  //             DisplayThemeItemActions.setDisplayThemeLegendContext({id: LegendContextEnum.Floor}),
  //             TaskActions.updateSelectedTaskId({taskId: floor.taskId}),
  //             UiContextActions.addDisplayedTask({id: floor.taskId}),
  //             UiContextActions.addDisplayedTaskVisibility({id: floor.taskId}),
  //             GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: floor.taskId})
  //           ];
  //         }),
  //       );
  //     }),
  //   ),
  // );

  getGraphicsFloorByTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(GraphicsFloorActions.beginGetGraphicsFloorByTaskId),
    withLatestFrom(this.store.select(FloorSelectors.selectCurrentFloor)),
    switchMap(([action, currentFloor]) => this.floorService.getTaskFloorData(action.id).pipe(
      map(data => {
        if (currentFloor.taskId === action.id && data.floorModel.length === 0) {
          this.store.dispatch(GraphicsFloorActions.currentGraphicsFloorIsEmpty({isEmpty: true}));
        }
        /** Check if floorData comes from current floor's task or another one and check
         * if viewBox doesn't already exists eg. from study mode **/
        const floorDataBlueprintExtents = data.svgDTO.SvgGroupDTO.find(g => g.floorModelId === FloorModelEnum.BlueprintExtents)?.boundingBoxString;
        if (floorDataBlueprintExtents) {
          const parsedViewBox = this.parseViewBox(floorDataBlueprintExtents, true);
          const viewBox = `${parsedViewBox[0]} ${parsedViewBox[1]} ${parsedViewBox[2]} ${parsedViewBox[3]}`;
          this.store.dispatch(RendererActions.setViewBox({taskId: action.id, viewBox: viewBox}));
        }
        return data;
      }),
      switchMap((data) => {
        if (data.floorModel.length === 0) {
          return [
            GraphicsFloorActions.currentTaskIsEmpty({isEmpty: true})
          ];
        } else {
          return [
            TaskActions.beginGetTaskById({id: currentFloor.taskId}),
            TaskFloorModelActions.addTaskFloorModels({taskFloorModels: data.floorModel}),
            FloorCatalogActions.addFloorCatalogItems({svgGroups: data.floorCatalog}),
            FloorDataActions.addFloorDataItems({svgGroups: data.svgDTO.SvgGroupDTO}),
            UsesActions.addUses({svgUses: data.svgDTO.SvgUseDTO}),
            GraphicsFloorActions.currentTaskIsEmpty({isEmpty: false}),
            GraphicsFloorActions.successGetGraphicsFloorByTaskId()
          ];
        }
      }),
    )),
    ),
    /** Disable catchError beacause if not, effect won't trigger twice **/
    // catchError(err => of(GraphicsFloorActions.errorGetGraphicsFloorByTaskId(err)))
  );

  successGetGraphicsFloor$ = createEffect(() =>
    this.actions$.pipe(
      /** Refresh data on successGetGraphicsFloor and when selectedTaskId changes
       * allowing handling of floormodels reordering with correct ui context data**/
      ofType(GraphicsFloorActions.successGetGraphicsFloorByTaskId), //TaskActions.updateSelectedTaskId
      switchMap((action) => [
          RoomActions.beginGetCurrentFloorTaskRooms(),
          RoomAllocationActions.beginGetCurrentTaskRoomAllocations(),
          WorkplaceAllocationActions.beginGetCurrentTaskWorkplaceAllocations(),
          //FloorActions.beginGetCurrentFloorPerimeterIds(),
          FloorActions.beginGetFloorCurrentTaskAreaIds(),
          // FloorActions.beginGetCurrentFloorTaskLayoutTypeIds(),
          // FloorActions.beginGetCurrentFloorTaskBusinessUnitIds(),
          // FloorActions.beginGetCurrentFloorTaskAttributionTypeIds(),
          // FloorActions.beginGetCurrentFloorTaskActivityStatusTypeIds(),
          WorkplaceActions.beginGetCurrentFloorWorkplaces(),
          FloorActions.beginGetCurrentFloorPerimeterIds(),
          DisplayThemeItemActions.buildDisplayThemes(),
        ]
      ),
    ));

  /**
   * TODO vérifier si encore utilisé
   */

  createViewbox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RendererActions.createViewboxFromApi),
      mergeMap(action => {
          const parsedViewBox = this.parseViewBox(action.viewBox, true);
          const viewBox = `${parsedViewBox[0]} ${parsedViewBox[1]} ${parsedViewBox[2]} ${parsedViewBox[3]}`;
          //const viewBox = `${parsedViewBox[0]} ${Math.abs(parsedViewBox[1])} ${parsedViewBox[2]} ${parsedViewBox[3]}`;
          return of(RendererActions.setViewBox({taskId: action.taskId, viewBox: viewBox}));
        }
      ),
    ),
  );

  clearSvgState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphicsFloorActions.clearSvgState),
      switchMap(data => [
        FloorCatalogActions.clearFloorCatalog(),
        FloorDataActions.clearFloorDataItems(),
        TaskFloorModelActions.clearTaskFloorModels(),
        RendererActions.clearRenderer(),
        UsesActions.clearUses(),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }

  parseViewBox(viewBoxString, asNumbers = false) {
    let values = viewBoxString.split(/[ ;]/).filter(Boolean); // filter removes empty strings
    const width = values[2] - values[0]; // xmax - xmin
    const height = values[3] - values[1]; // ymax - ymin
    const newArray = [];
    newArray[0] = Number(values[0]);
    newArray[1] = Number(values[1]);
    newArray[2] = width;
    newArray[3] = height;
    return newArray;
  }
}
