import {createReducer, on} from '@ngrx/store';
import * as CoreActions from '../actions/core.actions';
import {EcSyFile} from '../../../domain-models/models/EcSyFile.model';
import {User} from '../../../domain-models/business-extended/user.model';

export const coreFeatureKey = 'core';

export interface State {
  client: EcSyFile;
  user: User;
}

export const initialState: State = {
  client: null,
  user: null,
};

export const reducer = createReducer(
  initialState,
  on(CoreActions.setClientFile, (state, {client}) => ({...state, client: client})),
  on(CoreActions.setUser, (state, {user}) => ({...state, user: user})),
  on(CoreActions.setAppUser, (state, {appUser}) => {
    const user = new User(state.user.name, state.user.preferred_username, state.user.role, state.user.sub);
    user.setAppUser(appUser);
    return {...state, user: user};
  })
);
