import {createSelector} from '@ngrx/store';
import * as fromRoomLayoutType from '../reducers/room-layout-type.reducer';
import {selectSharedState} from '../reducers';

export const selectRoomLayoutTypes = createSelector(
  selectSharedState,
  state => state[fromRoomLayoutType.roomLayoutTypesFeatureKey]
);

export const selectRoomLayoutTypeIds = createSelector(
  selectRoomLayoutTypes,
  fromRoomLayoutType.selectRoomLayoutTypeIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectRoomLayoutTypeEntities = createSelector(
  selectRoomLayoutTypes,
  fromRoomLayoutType.selectRoomLayoutTypeEntities
);
export const selectAllRoomLayoutTypes = createSelector(
  selectRoomLayoutTypes,
  fromRoomLayoutType.selectAllRoomLayoutTypes
);
export const selectRoomLayoutTypeTotal = createSelector(
  selectRoomLayoutTypes,
  fromRoomLayoutType.selectRoomLayoutTypeTotal
);

export const selectRoomLayoutTypeById = (id: number) => createSelector(
  selectRoomLayoutTypeEntities,
  (entities) => {
    //console.log(id);
    return entities[id];
  }
);

export const selectRoomLayoutTypeByIds = (ids: number[]) => createSelector(
  selectRoomLayoutTypeEntities,
  (entities) => ids.map(id => entities[id])
);
