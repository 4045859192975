import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreModule} from '@ngrx/store';
import * as fromCore from '../core-store/reducers';
import {EffectsModule} from '@ngrx/effects';
import {CoreEffects} from './effects/core.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCore.coreFeatureKey, fromCore.reducers),
    EffectsModule.forFeature([CoreEffects]),
  ]
})
export class CoreStoreModule {
}
