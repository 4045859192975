import {createAction, props} from '@ngrx/store';
import {Floor} from '../../../domain-models/business/floor.model';
import {EntityMap, Predicate, Update} from '@ngrx/entity';

/**
 * CRUD Actions
 */

export const loadFloors = createAction('[Floor/API] Load Users', props<{ floors: Floor[] }>());
export const addFloor = createAction('[Floor/API] Add Floor', props<{ floor: Floor }>());
export const setFloor = createAction('[Floor/API] Set Floor', props<{ floor: Floor }>());
export const upsertFloor = createAction('[Floor/API] Upsert Floor', props<{ floor: Floor }>());
export const addFloors = createAction('[Floor/API] Add Floors', props<{ floors: Floor[] }>());
export const upsertFloors = createAction('[Floor/API] Upsert Floors', props<{ floors: Floor[] }>());
export const updateFloor = createAction('[Floor/API] Update Floor', props<{ update: Update<Floor> }>());
export const updateFloors = createAction('[Floor/API] Update Floors', props<{ updates: Update<Floor>[] }>());
export const mapFloors = createAction('[Floor/API] Map Floors', props<{ entityMap: EntityMap<Floor> }>());
export const deleteFloor = createAction('[Floor/API] Delete Floor', props<{ id: string }>());
export const deleteFloors = createAction('[Floor/API] Delete Floors', props<{ ids: string[] }>());
export const deleteFloorsByPredicate = createAction('[Floor/API] Delete Floors By Predicate', props<{ predicate: Predicate<Floor> }>());
export const clearFloors = createAction('[Floor/API] Clear Floors');

/** END of CRUD **/

export const beginGetAllFloors = createAction('[Floor/API] - Begin Get All Floors');
export const errorGetAllFloors = createAction('[API] - Error Get All Floors', props<Error>());

export const updateSelectedFloorId = createAction(
  '[Floor/API] - Save Selected Floor Id',
  props<{ floorId: number }>()
);

export const beginGetFloorCurrentTaskAreaIds = createAction('[Floor/API] - Begin get Floor current task area ids');
export const beginGetFloorAreaIdsByTaskId = createAction('[Floor/API] - Begin get Floor area ids by taskId', props<{ taskId: number }>());
export const beginGetCurrentFloorPerimeterIds = createAction('[API] - Get Current Floor Perimeter Ids');

export const beginGetCurrentFloorTaskLayoutTypeIds = createAction('[API] - Get Current Floor Layout Type Ids');
export const beginGetCurrentFloorTaskActivityStatusTypeIds = createAction('[API] - Get Current Floor Activity Status Type Ids');
export const beginGetCurrentFloorTaskAttributionTypeIds = createAction('[API] - Get Current Floor Attribution Type Ids');
export const beginGetCurrentFloorTaskBusinessUnitIds = createAction('[API] - Get Current Floor Business Units Ids');

export const clearFloorState = createAction('[APP] - Clear Floor State');
