import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, withLatestFrom} from 'rxjs/operators';
import * as WorkplaceAllocationActions from '../actions/workplace-allocation.actions';
import {FloorService} from '../../../floor/services/floor.service';
import {TaskSelectors} from '../../project-management/selectors';
import {Store} from '@ngrx/store';
import {State} from '../../../reducers';

@Injectable()
export class WorkplaceAllocationEffects {

  getCurrentFloorPeopleAllocations$ = createEffect(() => this.actions$.pipe(
    ofType(WorkplaceAllocationActions.beginGetCurrentTaskWorkplaceAllocations),
    withLatestFrom(this.store.select(TaskSelectors.selectCurrentTaskId)),
    concatMap(([action, taskId]) =>
      this.floorService.getWorkplaceAllocationsByTaskId(taskId).pipe(
        map((workplaceAllocations) => {
          return WorkplaceAllocationActions.addWorkplaceAllocations({workplaceAllocations: workplaceAllocations});
        })
      )
    ))
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }
}
