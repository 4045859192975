import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const DISPLAY_THEME_ITEM = {
  databaseTableName: 'DisplayThemeItem',
  displayThemeId: 'DiThIt_DisplayThemeId',
  displayThemableId: 'DiThIt_DisplayThemableId'
};

export class DisplayThemeItem {
  constructor(
    public databaseTableName: string,
    public displayThemeId: number,
    public displayThemableId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DisplayThemeItemAdapter implements Adapter<DisplayThemeItem> {
  adapt(item: any): DisplayThemeItem {
    return new DisplayThemeItem(
      DISPLAY_THEME_ITEM.databaseTableName,
      item[DISPLAY_THEME_ITEM.displayThemeId],
      item[DISPLAY_THEME_ITEM.displayThemableId],
    );
  }

  encode(item: DisplayThemeItem): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DISPLAY_THEME_ITEM.displayThemeId]: item.displayThemeId,
      [DISPLAY_THEME_ITEM.displayThemableId]: item.displayThemableId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
