import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TaskValidationActions} from '../actions';
import {TaskValidation} from '../../../domain-models/business/task-validation.model';

export const taskValidationFeatureKey = 'taskValidations';

export interface State extends EntityState<TaskValidation> {
  selectedTaskValidationId: number | null;
}

export const adapter: EntityAdapter<TaskValidation> = createEntityAdapter<TaskValidation>();

export const initialState: State = adapter.getInitialState({
  selectedTaskValidationId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskValidationActions.addTaskValidation, (state, { taskValidation }) => {
    return adapter.addOne(taskValidation, state);
  }),
  on(TaskValidationActions.setTaskValidation, (state, { taskValidation }) => {
    return adapter.setOne(taskValidation, state);
  }),
  on(TaskValidationActions.upsertTaskValidation, (state, { taskValidation }) => {
    return adapter.upsertOne(taskValidation, state);
  }),
  on(TaskValidationActions.addTaskValidations, (state, { taskValidations }) => {
    return adapter.addMany(taskValidations, state);
  }),
  on(TaskValidationActions.upsertTaskValidations, (state, { taskValidations }) => {
    return adapter.upsertMany(taskValidations, state);
  }),
  on(TaskValidationActions.updateTaskValidation, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskValidationActions.updateTaskValidations, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskValidationActions.mapTaskValidations, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskValidationActions.deleteTaskValidation, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskValidationActions.deleteTaskValidations, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskValidationActions.deleteTaskValidationsByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskValidationActions.loadTaskValidations, (state, { taskValidations }) => {
    return adapter.setAll(taskValidations, state);
  }),
  on(TaskValidationActions.clearTaskValidations, state => {
    return adapter.removeAll({ ...state, selectedTaskValidationId: null });
  }),
  /** END OF CRUD **/
  on(TaskValidationActions.updateSelectedTaskValidationId, (state, {taskValidationId}) => {
    return ({...state, selectedTaskValidationId: taskValidationId});
  }),
);

export const getSelectedTaskValidationId = (state: State) => state.selectedTaskValidationId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskValidation ids
export const selectTaskValidationIds = selectIds;

// select the dictionary of taskValidation entities
export const selectTaskValidationEntities = selectEntities;

// select the array of taskValidations
export const selectAllTaskValidations = selectAll;

// select the total taskValidation count
export const selectTaskValidationTotal = selectTotal;
