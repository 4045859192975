import {Component} from '@angular/core';
//import {JwksValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {AuthService} from './core/services/auth.service';

@Component({
  selector: 'echo-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  constructor(/*private oAuthService: OAuthService,*/
              private authService: AuthService) {
    this.authService.runInitialLoginSequence();
    //this.configureOAuth();
  }

  // private configureOAuth() {
  //   this.oAuthService.configure(authConfig);
  //   this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
  //   this.oAuthService.setupAutomaticSilentRefresh();
  //   this.oAuthService.loadDiscoveryDocumentAndLogin();
  // }

}
