import {createSelector} from '@ngrx/store';
import * as fromLanguageItem from '../reducers/language-item.reducer';
import {selectSharedState} from '../reducers';

export const selectLanguageItems = createSelector(
  selectSharedState,
  state => state[fromLanguageItem.languageItemsFeatureKey]
);

export const selectLanguageItemsIds = createSelector(
  selectLanguageItems,
  fromLanguageItem.selectLanguageItemsIds
);
export const selectLanguageItemsEntities = createSelector(
  selectLanguageItems,
  fromLanguageItem.selectLanguageItemsEntities
);
export const selectAllLanguageItems = createSelector(
  selectLanguageItems,
  fromLanguageItem.selectAllLanguageItems
);
export const selectLanguageItemsTotal = createSelector(
  selectLanguageItems,
  fromLanguageItem.selectLanguageItemsTotal
);

export const allLanguageItemsLoaded = createSelector(
  selectLanguageItems,
  state => state.isLoaded
);

export const selectLanguageItemByDisplayNameId = (id: number) => createSelector(
  selectLanguageItemsEntities,
  entities => entities[id]
);
