import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../material/material.module';
import {SvgViewerComponent} from './svg-viewer/svg-viewer.component';
import {OrderByPipe} from './pipes/order-by.pipe';
import {ReversePipe} from './pipes/reverse.pipe';
import {CustomTooltipDirective} from './directives/custom-tooltip.directive';
import {CustomTooltipComponent} from './custom-tooltip/custom-tooltip.component';
import {ImageTooltipComponent} from './image-tooltip/image-tooltip.component';
import {ImageTooltipDirective} from './directives/image-tooltip.directive';
import {HoverClassDirective} from './directives/hover-class.directive';
import {LanguagePipe} from './pipes/language.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FilterPipe} from './pipes/filter.pipe';
import {FindPipe} from './pipes/find.pipe';
import {SumPipe} from './pipes/sum.pipe';
import {FloorLegendComponent} from './floor-legend/floor-legend.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RoomAreaPipe} from './pipes/room-area.pipe';
import {DirectoryNamePipe} from './pipes/directory-name.pipe';
import {IsNaNPipe} from './pipes/is-na-n.pipe';

@NgModule({
  declarations: [
    FloorLegendComponent,
    SvgViewerComponent,
    OrderByPipe,
    ReversePipe,
    CustomTooltipDirective,
    CustomTooltipComponent,
    ImageTooltipComponent,
    ImageTooltipDirective,
    HoverClassDirective,
    LanguagePipe,
    FilterPipe,
    FindPipe,
    SumPipe,
    RoomAreaPipe,
    DirectoryNamePipe,
    IsNaNPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
  ],
    exports: [
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SvgViewerComponent,
        OrderByPipe,
        ReversePipe,
        CustomTooltipDirective,
        ImageTooltipDirective,
        HoverClassDirective,
        LanguagePipe,
        FilterPipe,
        FindPipe,
        SumPipe,
        FloorLegendComponent,
        RoomAreaPipe,
        DirectoryNamePipe,
        IsNaNPipe
    ],
  // entryComponents: [
  //   CustomTooltipComponent,
  //   ImageTooltipComponent
  // ]
})
export class SharedModule {
}
