import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LayoutService} from '../services/layout.service';
import {MenuService} from '../services/menu.service';
import {MenuItem} from '../models/menu-item.model';
import {ClientService} from '../services/client.service';
import {Router} from '@angular/router';
import {EcSyFile} from '../../domain-models/models/EcSyFile.model';
import {MediaMatcher} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {State} from '../../reducers';
import {Store} from '@ngrx/store';
import {CoreSelectors} from '../../store/core-store/selectors';

@Component({
  selector: 'echo-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  toggleDrawer: boolean;
  toggleMobileMenu: boolean;

  @ViewChild('menu', {static: false}) public menu;

  public menuItems$: Observable<MenuItem[]>;
  public client$: Observable<EcSyFile>;

  constructor(private store: Store<State>,
              private layoutService: LayoutService,
              private menuService: MenuService,
              private clientService: ClientService,
              private router: Router,
              private changeDetectorRef: ChangeDetectorRef,
              private media: MediaMatcher
  ) {
    this.client$ = this.store.select(CoreSelectors.selectClient);
    this.mobileQuery = media.matchMedia('(max-width: 1048px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.menuItems$ = this.menuService.itemToDisplay$;
    //this.client$ = this.store.clientFile$;
    this.layoutService.rightDrawerState$.subscribe(closed => this.toggleDrawer = closed);
    this.layoutService.leftDrawerState$.subscribe(closed => this.toggleMobileMenu = closed);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onMenuItemClick(item: MenuItem) {
    if (item.component === 'list') {
      this.router.navigate([`/data-tables/${item.label}`], {
        queryParams: {'endpoint': item.endpoint, 'usageContextId': item.usageContextId, 'dataContext': item.dataContext},
      });
    } else if (item.component === 'tree') {
      this.router.navigate([`/trees/${item.label}`], {
        queryParams: {'tableName': item.dataContext},
      });
    } else {
      this.menuService.currentPage$.next(item.label);
      this.router.navigate([item.component]);
    }
  }

  rightDrawerToggle() {
    this.toggleDrawer = !this.toggleDrawer;
    this.layoutService.rightDrawer$.next(this.toggleDrawer);
  }

  mobileMenuToggle() {
    this.toggleMobileMenu = !this.toggleMobileMenu;
    this.layoutService.leftDrawer$.next(this.toggleMobileMenu);
  }

  toggleMenuItem(item: MenuItem) {
    item.isOpened = !item.isOpened;
  }

}
