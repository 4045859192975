import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const WORKPLACE_ALLOCATION = {
  databaseTableName: 'WorkplaceAllocation',
  directoryId: 'WoAl_DirectoryId',
  floorDataId: 'WoAl_FloorDataId',
  rate: 'WoAl_Ratio',
  inPattern: 'WoAl_InPattern',
};

export class WorkplaceAllocation {
  public databaseTableName: string;
  constructor(
    public directoryId: number,
    public floorDataId: number,
    public rate: number,
    public inPattern:  string,
  ) {
    this.databaseTableName = WORKPLACE_ALLOCATION.databaseTableName;
  }
}

@Injectable({
  providedIn: 'root'
})

export class WorkplaceAllocationAdapter implements Adapter<WorkplaceAllocation> {
  adapt(item: any): WorkplaceAllocation {
    return new WorkplaceAllocation (
      //WORKPLACE_ALLOCATION.databaseTableName,
      item[WORKPLACE_ALLOCATION.directoryId],
      item[WORKPLACE_ALLOCATION.floorDataId],
      item[WORKPLACE_ALLOCATION.rate],
      item[WORKPLACE_ALLOCATION.inPattern]
    );
  }
  encode(item: WorkplaceAllocation): ISingleBackendObj {
    const encoded = {
      TableName: WORKPLACE_ALLOCATION.databaseTableName,
      [WORKPLACE_ALLOCATION.directoryId]: item.directoryId,
      [WORKPLACE_ALLOCATION.floorDataId]: item.floorDataId,
      [WORKPLACE_ALLOCATION.rate]: item.rate,
      [WORKPLACE_ALLOCATION.inPattern]: item.inPattern
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
