import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {asyncScheduler, EMPTY, of} from 'rxjs';
import {catchError, concatMap, debounceTime, map, switchMap, withLatestFrom,} from 'rxjs/operators';
import * as FloorActions from '../actions/floor.actions';
import {FloorService} from '../../../floor/services/floor.service';
import {Floor} from '../../../domain-models/business/floor.model';
import {Store} from '@ngrx/store';
import {TaskSelectors} from '../../project-management/selectors';
import {FloorSelectors} from '../selectors';
import {State} from '../../../reducers';
import {UiContextActions} from '../actions';

@Injectable()
export class FloorEffects {
  getAllFloors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.beginGetAllFloors),
      switchMap((action) =>
        this.floorService.getAllFloors().pipe(
          map((data: Floor[]) => {
            return FloorActions.addFloors({ floors: data });
          }),
          catchError((error: Error) => {
            return of(FloorActions.errorGetAllFloors(error));
          })
        )
      )
    )
  );

  beginGetFloorCurrentTaskAreaIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.beginGetFloorCurrentTaskAreaIds),
      //withLatestFrom(),
      switchMap((action) =>
        this.store
          .select(TaskSelectors.selectCurrentTaskId)
          .pipe(
            switchMap((taskId) =>
              {
                if (taskId) {
                  return this.floorService
                    .getFloorCurrentTaskAreaIds(taskId)
                    .pipe(
                      concatMap((floorAreaIds) => [
                        UiContextActions.setCurrentTaskRoomLayoutTypeIds({
                          ids: floorAreaIds.roomLayoutType,
                        }),
                        UiContextActions.setCurrentTaskActivityStatusTypeIds({
                          ids: floorAreaIds.roomActivityStatusType,
                        }),
                        UiContextActions.setCurrentTaskAttributionTypeIds({
                          ids: floorAreaIds.roomAttributionType,
                        }),
                        UiContextActions.setCurrentTaskBusinessUnitIds({
                          ids: floorAreaIds.roomAllocation,
                        }),
                      ])
                    );
                } else {
                  return EMPTY;
                }
              }
            )
          )
      )
    )
  );

  beginGetFloorAreaIdsByTaskId$ = createEffect(
    () => ({ debounce = 300, scheduler = asyncScheduler } = {}) =>
      this.actions$.pipe(
        ofType(FloorActions.beginGetFloorAreaIdsByTaskId),
        debounceTime(debounce, scheduler),
        switchMap((action) =>
          this.floorService
            .getFloorCurrentTaskAreaIds(action.taskId)
            .pipe(
              concatMap((floorAreaIds) => [
                UiContextActions.setCurrentTaskRoomLayoutTypeIds({
                  ids: floorAreaIds.roomLayoutType,
                }),
                UiContextActions.setCurrentTaskActivityStatusTypeIds({
                  ids: floorAreaIds.roomActivityStatusType,
                }),
                UiContextActions.setCurrentTaskAttributionTypeIds({
                  ids: floorAreaIds.roomAttributionType,
                }),
                UiContextActions.setCurrentTaskBusinessUnitIds({
                  ids: floorAreaIds.roomAllocation,
                }),
              ])
            )
        )
      )
  );

  beginGetCurrentFloorPerimeterIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FloorActions.beginGetCurrentFloorPerimeterIds),
      withLatestFrom(this.store.select(FloorSelectors.selectCurrentFloorId)),
      switchMap(([action, currentFloorId]) =>
        this.floorService
          .getFloorPerimeterIds(currentFloorId)
          .pipe(
            map((floorPerimeterIds) =>
              UiContextActions.setFloorPerimetersIds({ ids: floorPerimeterIds })
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {}
}
