export const Z_APP_MENU = {
  databaseTableName: 'z_app_menu',
  id: 'ApMe_Id',
  displayNameId: 'ApMe_DisplayNameId',
  parentId: 'ApMe_ParentId',
  grantId: 'ApMe_GrantId',
  scopeId: 'ApMe_ScopeId',
  orderIndex: 'ApMe_OrderIndex',
  depth: 'ApMe_Depth',
  isCustomizable: 'ApMe_IsCustomizable',
  apiEndPoint: 'ApMe_ApiEndPoint',
  componentPath: 'ApMe_ComponentPath',
  dataContext: 'ApMe_DataContext',
  usageContextId: 'ApMe_ContextUIId',
};
