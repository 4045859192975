import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, concatMap, map, mergeMap,} from 'rxjs/operators';
import * as BuildingActions from '../actions/building.actions';
import {BuildingService} from '../../../building/services/building.service';
import {Building} from '../../../domain-models/business/building.model';
import {Store} from '@ngrx/store';
import {BuildingSelectors} from '../selectors';
import {State} from '../../../reducers';
import {BuildingUiActions} from '../actions';

@Injectable()
export class BuildingEffects {
  getAllBuildings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.beginGetAllBuildings),
      mergeMap((action) =>
        this.buildingService.getAllBuildings().pipe(
          map((data: Building[]) => {
            return BuildingActions.addBuildings({ buildings: data });
          }),
          catchError((error: Error) => {
            return of(BuildingActions.errorGetAllBuildings(error));
          })
        )
      )
    )
  );

  beginGetBuildingCurrentTaskAreaIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BuildingActions.beginGetBuildingAreaIds),
      concatMap((action) =>
        this.store.select(BuildingSelectors.selectCurrentBuildingId).pipe(
          concatMap((buildingId) =>
            this.buildingService.getBuildingAreaIds(buildingId).pipe(
              concatMap((buildingAreaIds) => [
                BuildingUiActions.setCurrentBuildingRoomLayoutTypeIds({
                  ids: buildingAreaIds.roomLayoutType,
                }),
                BuildingUiActions.setCurrentBuildingActivityStatusTypeIds({
                  ids: buildingAreaIds.roomActivityStatusType,
                }),
                BuildingUiActions.setCurrentBuildingAttributionTypeIds({
                  ids: buildingAreaIds.roomAttributionType,
                }),
                BuildingUiActions.setCurrentBuildingBusinessUnitIds({
                  ids: buildingAreaIds.roomAllocation,
                }),
              ])
            )
          )
        )
      ),
    )
  );

  constructor(
    private actions$: Actions,
    private buildingService: BuildingService,
    private store: Store<State>
  ) {}
}
