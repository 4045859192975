import {Injectable} from '@angular/core';
import {Adapter} from '../adapter';
import {SvgUse} from '../../svg-factory/models/svg-use.model';
import {SvgGroup} from '../../svg-factory/models/svg-group.model';
import {TaskFloorModel, TaskFloorModelAdapter} from '../business-extended/task-floor-model.model';

interface SvgDTO {
  SvgUseDTO: SvgUse[];
  SvgGroupDTO: SvgGroup[];
}

const GRAPHICS_TASK_FLOOR_DATA = {
  floorModel: 'FloorModel',
  svgDTO: 'SvgDTO',
  floorCatalog: 'FloorCatalog',
  blueprintExtentsLayerId: 'BlueprintExtentsLayerId',
};

export class ApiGraphicsTaskFloorData {
  constructor(
    public floorModel: TaskFloorModel[],
    public svgDTO: SvgDTO,
    public floorCatalog: SvgGroup[],
    public blueprintExtentsLayerId?: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiGraphicsTaskFloorDataAdapter implements Adapter<ApiGraphicsTaskFloorData> {
  adapt(item: any): ApiGraphicsTaskFloorData {

    const floorCatalog = [];
    item[GRAPHICS_TASK_FLOOR_DATA.floorCatalog].forEach(
      e => floorCatalog.push(e as SvgGroup)
    );

    const floorModel = [];
    item[GRAPHICS_TASK_FLOOR_DATA.floorModel].forEach(
      e => floorModel.push(new TaskFloorModelAdapter().adapt(e))
    );

    const svgDTO = item[GRAPHICS_TASK_FLOOR_DATA.svgDTO] as SvgDTO;

    return new ApiGraphicsTaskFloorData(
      floorModel,
      svgDTO,
      floorCatalog,
      item?.[GRAPHICS_TASK_FLOOR_DATA.blueprintExtentsLayerId]
    );
  }
}
