import {createAction, props} from '@ngrx/store';
import {EntityMap, Predicate, Update} from '@ngrx/entity';
import {TaskFloor} from '../../../domain-models/business/task-floor.model';

/**
 * CRUD Actions
 */

export const loadTaskFloors = createAction('[TaskFloor/API] Load Users', props<{ taskFloors: TaskFloor[] }>());
export const addTaskFloor = createAction('[TaskFloor/API] Add TaskFloor', props<{ taskFloor: TaskFloor }>());
export const setTaskFloor = createAction('[TaskFloor/API] Set TaskFloor', props<{ taskFloor: TaskFloor }>());
export const upsertTaskFloor = createAction('[TaskFloor/API] Upsert TaskFloor', props<{ taskFloor: TaskFloor }>());
export const addTaskFloors = createAction('[TaskFloor/API] Add TaskFloors', props<{ taskFloors: TaskFloor[] }>());
export const upsertTaskFloors = createAction('[TaskFloor/API] Upsert TaskFloors', props<{ taskFloors: TaskFloor[] }>());
export const updateTaskFloor = createAction('[TaskFloor/API] Update TaskFloor', props<{ update: Update<TaskFloor> }>());
export const updateTaskFloors = createAction('[TaskFloor/API] Update TaskFloors', props<{ updates: Update<TaskFloor>[] }>());
export const mapTaskFloors = createAction('[TaskFloor/API] Map TaskFloors', props<{ entityMap: EntityMap<TaskFloor> }>());
export const deleteTaskFloor = createAction('[TaskFloor/API] Delete TaskFloor', props<{ id: string }>());
export const deleteTaskFloors = createAction('[TaskFloor/API] Delete TaskFloors', props<{ ids: string[] }>());
export const deleteTaskFloorsByPredicate = createAction('[TaskFloor/API] Delete TaskFloors By Predicate', props<{ predicate: Predicate<TaskFloor> }>());
export const clearTaskFloors = createAction('[TaskFloor/API] Clear TaskFloors');

/** END of CRUD **/

export const beginGetAllTaskFloors = createAction('[TaskFloor/API] - Begin Get All TaskFloors');
export const errorGetAllTaskFloors = createAction('[API] - Error Get All TaskFloors', props<Error>());

export const updateSelectedTaskFloorId = createAction(
  '[Task/API] - Update selected TaskFloor Id',
  props<{ id: number }>()
);
