<!--<a mat-menu-item href="app/(debug//sidenav:debug)">Debug</a>
<button mat-menu-item (click)="navigate()">Debug Full</button>
<button mat-menu-item [routerLink]="[{ outlets: { sidenav: ['debug'] } }]">Debug Sidenav</button>-->
<!--<button mat-menu-item (click)="closeDrawer()" [routerLink]="['/clients']">Changer de client</button>-->
<div class="user-account-handler" *ngIf="(client$ | async) as client">
  <p><small>Ce compte est géré par <b>{{client.CompanyName}}</b>.</small><br/>
    <small><a [routerLink]="''">Contacter votre administrateur</a></small>
  </p>
</div>
<div class="user-panel-header">
  <div class="user">
    <div *ngIf="(user$ | async) as user" class="avatar">
      <span>{{ user.name | slice:0:1 | uppercase }}</span>
    </div>
    <div class="details">
      <h4 *ngIf="(user$ | async) as user">{{user.name}}</h4>
      <p *ngIf="(client$ | async) as client"><small>{{ client.CompanyName}}</small></p>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<mat-action-list>
  <button mat-list-item (click)="closeDrawer()" [routerLink]="['/clients']">
    <mat-icon>folder_shared</mat-icon>
    Clients
  </button>
  <button mat-list-item (click)="closeDrawer()" [routerLink]="['/user/profile']">
    <mat-icon>person</mat-icon>
    Profil
  </button>
  <button mat-list-item (click)="logout()">
    <mat-icon>no_meeting_room</mat-icon>
    Se déconnecter
  </button>
</mat-action-list>
<mat-divider></mat-divider>
<mat-action-list>
  <button mat-list-item onclick="window.open('https://docs.ecsy.fr');">
    <mat-icon>help</mat-icon>
    Accéder à l'aide
  </button>
</mat-action-list>



