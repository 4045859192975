import {createAction, props} from '@ngrx/store';
import {DbColumn} from '../../../domain-models/core/z-db-column.model';

export const beginGetAllDbColumns = createAction('[Shared/API] - Begin Get All DbColumns');

export const addDbColumn = createAction('[Shared/API] Add DbColumn', props<{ dbColumn: DbColumn }>());

export const addDbColumns = createAction(
  '[API] - Add DbColumns',
  props<{ dbColumns: DbColumn[] }>()
);

export const successGetAllDbColumns = createAction('[API] - Success Get All DbColumns');
export const errorGetAllDbColumns = createAction('[Shared/API] - Error Get All DbColumns', props<Error>());
