import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, concatMap, filter, map, switchMap} from 'rxjs/operators';
import * as RoomLayoutTypeActions from '../actions/room-layout-type.actions';
import * as RoomActivityStatusTypeActions from '../actions/room-activity-status-type.actions';
import * as RoomAttributionTypeActions from '../actions/room-attribution-type.actions';
import * as PerimetersActions from '../actions/perimeters.actions';
import * as LanguageItemActions from '../actions/language-item.actions';
import * as DbColumnActions from '../actions/db-column.actions';
import * as BusinessUnitActions from '../actions/business-unit.actions';
import {RoomLayoutType} from '../../../domain-models/business/room-layout-type.model';
import {SharedService} from '../../../shared/services/shared.service';
import {RoomActivityStatusType} from '../../../domain-models/business/room-activity-status-type.model';
import {Perimeter} from '../../../domain-models/business/perimeter.model';
import {BusinessUnit} from '../../../domain-models/business/business-unit.model';
import {LanguageItem} from '../../../domain-models/core/z-language-item.model';
import {DbColumn} from '../../../domain-models/core/z-db-column.model';
import {FloorCatalogActions, FloorDoorStyleActions, FloorModelActions, FloorWallStyleActions} from '../actions';
import {FloorModel} from '../../../domain-models/business/floor-model.model';
import {FloorCatalog} from '../../../domain-models/business/floor-catalog.model';
import {FloorDoorStyle} from '../../../domain-models/business/floor-door-style.model';
import {FloorWallStyle} from '../../../domain-models/business/floor-wall-style.model';

@Injectable()
export class SharedEffects {

  getAllRoomLayoutTypes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoomLayoutTypeActions.beginGetAllRoomLayoutTypes),
        switchMap(action =>
          this.sharedService.getAllRoomLayoutTypes().pipe(
            map((data: RoomLayoutType[]) => {
              return RoomLayoutTypeActions.successGetAllRoomLayoutTypes({roomLayoutTypes: data});
            }),
            catchError((error: Error) => {
              return of(RoomLayoutTypeActions.errorGetAllRoomLayoutTypes(error));
            })
          )))
  );

  getAllRoomLayoutTypesLastItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RoomLayoutTypeActions.beginGetAllRoomLayoutTypesLastItems),
        switchMap(action =>
          this.sharedService.getAllRoomLayoutTypesLastItems().pipe(
            map((data: RoomLayoutType[]) => {
              return RoomLayoutTypeActions.successGetAllRoomLayoutTypes({roomLayoutTypes: data});
            }),
            catchError((error: Error) => {
              return of(RoomLayoutTypeActions.errorGetAllRoomLayoutTypes(error));
            })
          )))
  );

  getAllRoomActivityStatusTypes$ = createEffect(() => this.actions$.pipe(
    ofType(RoomActivityStatusTypeActions.beginGetAllRoomActivityStatusTypes),
    switchMap(action =>
      this.sharedService.getAllRoomActivityStatusTypes().pipe(
        map((data: RoomActivityStatusType[]) => {
          return RoomActivityStatusTypeActions.successGetAllRoomActivityStatusTypes({roomActivityStatusTypes: data});
        }),
        // catchError((error: Error) => {
        //   return of(RoomActivityStatusTypeActions.errorGetAllRoomActivityStatusTypes(error));
        // })
      )))
  );

  getAllRoomAttributionTypes$ = createEffect(
    () => this.actions$.pipe(
      ofType(RoomAttributionTypeActions.beginGetAllRoomAttributionTypes),
      switchMap(action =>
        this.sharedService.getAllRoomAttributionTypes().pipe(
          map((data: RoomActivityStatusType[]) => {
            return RoomAttributionTypeActions.successGetAllRoomAttributionTypes({roomAttributionTypes: data});
          }),
          catchError((error: Error) => {
            return of(RoomAttributionTypeActions.errorGetAllRoomAttributionTypes(error));
          })
        )))
  );

  getAllPerimeters$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PerimetersActions.beginGetAllPerimeters),
        switchMap(action =>
          this.sharedService.getAllPerimeters().pipe(
            map((data: Perimeter[]) => {
              return PerimetersActions.successGetAllPerimeters({perimeters: data});
            }),
            catchError((error: Error) => {
              return of(PerimetersActions.errorGetAllPerimeters(error));
            })
          )))
  );

  getAllBusinessUnits$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BusinessUnitActions.beginGetAllBusinessUnits),
        switchMap(action =>
          this.sharedService.getAllBusinessUnits().pipe(
            map((data: BusinessUnit[]) => {
              return BusinessUnitActions.successGetAllBusinessUnits({businessUnits: data});
            }),
            catchError((error: Error) => {
              return of(BusinessUnitActions.errorGetAllBusinessUnits(error));
            })
          )))
  );

  getAllLanguageItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LanguageItemActions.beginGetAllLanguageItems),
        switchMap(action =>
          this.sharedService.getAllLanguageItems().pipe(
            concatMap((data: LanguageItem[]) => [
              LanguageItemActions.addLanguageItems({languageItems: data}),
              LanguageItemActions.successGetAllLanguageItems()
            ]),
            catchError((error: Error) => {
              return of(LanguageItemActions.errorGetAllLanguageItems(error));
            })
          )))
  );

  getAllFloorModels$ = createEffect(() => this.actions$.pipe(
    ofType(FloorModelActions.beginGetAllFloorModels),
    switchMap(action =>
      this.sharedService.getAllFloorModels().pipe(
        map((data: FloorModel[]) =>
          FloorModelActions.addFloorModels({floorModels: data}),
        ),
      )))
  );

  getAllFloorCatalogItems$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FloorCatalogActions.beginGetAllFloorCatalogItems),
        switchMap(action =>
          this.sharedService.getFloorCatalogItems().pipe(
            concatMap((data: FloorCatalog[]) => [
              FloorCatalogActions.addFloorCatalogItems({floorCatalogItems: data}),
            ]),
            catchError((error: Error) => {
              return of(FloorCatalogActions.errorGetAllFloorCatalogItems(error));
            })
          )))
  );

  getAllDbColumns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DbColumnActions.beginGetAllDbColumns),
        switchMap(action =>
          this.sharedService.getAllDbColumns().pipe(
            concatMap((data: DbColumn[]) => [
              DbColumnActions.addDbColumns({dbColumns: data}),
              DbColumnActions.successGetAllDbColumns()
            ]),
            catchError((error: Error) => {
              return of(DbColumnActions.errorGetAllDbColumns(error));
            })
          )))
  );

  getAllFloorDoorStyles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FloorDoorStyleActions.beginGetAllFloorDoorStyles),
        switchMap(action =>
          this.sharedService.getAllFloorDoorStyles().pipe(
            filter(data => data.length > 0),
            concatMap((data: FloorDoorStyle[]) => {
              return [
                FloorDoorStyleActions.addFloorDoorStyles({floorDoorStyles: data}),
                FloorDoorStyleActions.successGetAllFloorDoorStyles()
              ];
            }),
            catchError((error: Error) => {
              return of(FloorDoorStyleActions.errorGetAllFloorDoorStyles(error));
            })
          )))
  );

  getAllFloorWallStyles$ = createEffect(() => this.actions$.pipe(
    ofType(FloorWallStyleActions.beginGetAllFloorWallStyles),
    switchMap(action =>
      this.sharedService.getAllFloorWallStyles().pipe(
        filter(data => data.length > 0),
        concatMap((data: FloorWallStyle[]) => {
          return [
            FloorWallStyleActions.addFloorWallStyles({floorWallStyles: data}),
            FloorWallStyleActions.successGetAllFloorWallStyles()
          ];
        }),
        catchError((error: Error) => {
          return of(FloorWallStyleActions.errorGetAllFloorWallStyles(error));
        })
      )))
  );

  constructor(
    private actions$: Actions,
    private sharedService: SharedService
  ) {
  }
}
