import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const TASK_CHANGELOG = {
  databaseTableName: 'TaskChangeLog',
  id: 'TaChLo_Id',
  taskId: 'TaChLo_TaskId',
  userId: 'TaChLo_UserId',
  sourceStatus: 'TaChLo_SourceStatus',
  targetStatus: 'TaChLo_TargetStatus',
  timeStamp: 'TaChLo_TimeStamp',
};

export class TaskChangelog {
  constructor(
    public databaseTableName: string,
    public id: number,
    public taskId: number,
    public userId: string,
    public sourceStatus: number,
    public targetStatus: number,
    public timeStamp: Date,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskChangelogAdapter implements Adapter<TaskChangelog> {
  adapt(item: any): TaskChangelog {
    return new TaskChangelog(
      TASK_CHANGELOG.databaseTableName,
      item[TASK_CHANGELOG.id],
      item[TASK_CHANGELOG.taskId],
      item[TASK_CHANGELOG.userId],
      item[TASK_CHANGELOG.sourceStatus],
      item[TASK_CHANGELOG.targetStatus],
      new Date(item[TASK_CHANGELOG.timeStamp]),
    );
  }

  encode(item: TaskChangelog): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_CHANGELOG.id]: item.id,
      [TASK_CHANGELOG.taskId]: item.taskId,
      [TASK_CHANGELOG.userId]: item.userId,
      [TASK_CHANGELOG.sourceStatus]: item.sourceStatus,
      [TASK_CHANGELOG.targetStatus]: item.targetStatus,
      [TASK_CHANGELOG.timeStamp]: item.timeStamp,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
