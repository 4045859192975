import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';
import {Color, ColorUtils} from '../../core/utils/color';

export enum RoomAttributionTypeEnum {
  None,
  Allocation,
  Sharing,
  Exploitation,
}

export const ROOM_ATTRIBUTION_TYPE = {
  databaseTableName: 'RoomAttributionType',
  id: 'RoAtTy_Id',
  name: 'RoAtTy_Name',
  displayNameId: 'RoAtTy_DisplayNameId',
  color: 'RoAtTy_Color'
};

export class RoomAttributionType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color,
  ) {
  }

}

@Injectable({
  providedIn: 'root'
})
export class RoomAttributionTypeAdapter implements Adapter<RoomAttributionType> {
  adapt(item: any): RoomAttributionType {
    return new RoomAttributionType(
      ROOM_ATTRIBUTION_TYPE.databaseTableName,
      item[ROOM_ATTRIBUTION_TYPE.id],
      item[ROOM_ATTRIBUTION_TYPE.name],
      item[ROOM_ATTRIBUTION_TYPE.displayNameId],
      ColorUtils.colorFromHexARGB(item[ROOM_ATTRIBUTION_TYPE.color]),
    );
  }

  encode(item: RoomAttributionType): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [ROOM_ATTRIBUTION_TYPE.id]: item.id,
      [ROOM_ATTRIBUTION_TYPE.name]: item.name,
      [ROOM_ATTRIBUTION_TYPE.displayNameId]: item.displayNameId,
      [ROOM_ATTRIBUTION_TYPE.color]: item.color.toHexArgb(),
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
