import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, first, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {FloorService} from '../../../floor/services/floor.service';
import {State} from '../../../reducers';
import {Action, Store} from '@ngrx/store';
import {combineLatest, EMPTY, Observable, of} from 'rxjs';
import {
  FloorDataActions,
  GraphicsFloorActions,
  RendererActions,
  TaskFloorModelActions,
  UsesActions,
  WallActions
} from '../../svg-store/actions';
import {FloorSelectors, UiContextSelectors} from '../selectors';
import {RoomActions, UiContextActions} from '../actions';
import {deepCopy} from '../../../shared/utils';
import {TaskActions, TaskFloorActions, TaskValidationActions} from '../../project-management/actions';
import {FloorModelEnum} from '../../../domain-models/business/floor-model.model';
import {Update} from '@ngrx/entity';
import {TaskFloorModel} from '../../../domain-models/business-extended/task-floor-model.model';
import {TaskSelectors} from '../../project-management/selectors';
import {isCreatingEquipment} from '../actions/ui-context.actions';
import {RendererSelectors, UseSelectors} from '../../svg-store/selectors';

@Injectable()
export class UiContextEffects {

  beginRoomCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.beginRoomCreation),
      switchMap((action) =>
        /** Reset any previously selected item if room creation has started **/
        RendererActions.resetSelection),
      catchError(err => of(err)),
    ),
  );

  cancelRoomCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.cancelRoomCreation),
      switchMap((action) => of(RendererActions.clearRoomContour())),
      // catchError(err => of(err)),
    ),
  );

  createRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.createRoom),
      switchMap((action) => this.floorService.createRoom(action).pipe(
        concatMap(data => [
          /** Reload TaskFloorData for latest updated data **/
          GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: action.taskId}),
          UiContextActions.cancelRoomCreation(),
          /** Reset flags **/
          // UiContextActions.isSelectingContour({selecting: false}),
          // UiContextActions.beginRoomCreation({isCreatingRoom: false}),
          // RendererActions.clearRoomContour()
        ]),
        // catchError(err => of(GraphicsFloorActions.errorGetGraphicsFloorById(err)))
        catchError(err => of(UiContextActions.cancelRoomCreation())),
      )),
    ),
  );

  recalculateRoom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.recalculateRoom),
      switchMap((action) => this.floorService.reCalculateRoom(action.roomId, action.props).pipe(
        //withLatestFrom(),
        switchMap(data => this.store.select(TaskSelectors.selectCurrentTaskId).pipe(
          first(),
          switchMap(currentTaskId => [
            /** Reload TaskFloorData for latest updated data **/
            RoomActions.upsertRoom({room: data.room}),
            FloorDataActions.upsertFloorDataItems({svgGroups: data.svgGroups}),
            //GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: currentTaskId}),
            UiContextActions.cancelRoomRecalculateArea(),
          ]),
        )),
        // catchError(err => of(GraphicsFloorActions.errorGetGraphicsFloorById(err)))
        catchError(err => of(UiContextActions.cancelRoomRecalculateArea())),
      )),
    ),
  );

  enableStudyMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.enableStudyMode),
      switchMap(action => [
          /** Update selectedTaskId **/
          TaskActions.updateSelectedTaskId({taskId: action.taskId}),
          /** Add taskId to displayedTaskIds **/
          UiContextActions.addDisplayedTask({id: action.taskId}),
          UiContextActions.addDisplayedTaskVisibility({id: action.taskId}),
          /** Get FloorData **/
          GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: action.taskId}),
          /** Get current Task TaskValidation **/
          TaskValidationActions.getTaskValidationsByTaskId({taskId: action.taskId}),
          /** Clear any previously selected items **/
          RendererActions.resetSelection(),
        ]
      ),
      catchError(err => of(UiContextActions.studyModeError(err))),
    )
  );

  removeStudy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.removeStudy),
      mergeMap((action) =>
        this.store.select(UiContextSelectors.selectDisplayedTasksIds).pipe(
          /** VERY IMPORTANT : since we subscribe to selectDisplayedTasksIds, this effect is precisely
           * updating selectedDisplayedTaskId, using first rxjs operator avoid infinite loop
           **/
          first(),
          switchMap(displayedTaskIds => {
            /** Copy displayedTaskIds except the deleted taskId (the one which we are deleting) **/
            const arr = deepCopy(displayedTaskIds);
            arr.splice(arr.indexOf(action.taskId), 1);
            // const arr = deepCopy(displayedTaskIds.slice(0, displayedTaskIds.length - 1));
            if (arr.length > 1) {
              return [
                /** Remove data previously loaded when studyMode was enabled**/
                FloorDataActions.removeFloorDataItemsByTaskIds({taskIds: [action.taskId]}),
                TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds({taskIds: [action.taskId]}),
                /** Set back selectedTaskId to last displayedTaskIds element **/
                TaskActions.updateSelectedTaskId({taskId: arr[arr.length - 1]}),
                /** Replace displayedTasks remaining taskIds **/
                UiContextActions.setDisplayedTasks({ids: arr}),
                UiContextActions.setDisplayedTaskVisibility({ids: arr}),
              ];
            } else {
              return of(UiContextActions.disableStudyMode());
            }

          })
        )),
    )
  );

  disableStudyMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.disableStudyMode),
      mergeMap((action) =>
        combineLatest([this.store.select(UiContextSelectors.selectDisplayedTasksIds), this.store.select(FloorSelectors.selectCurrentFloor)]).pipe(
          /** VERY IMPORTANT : since we use combineLatest with selectDisplayedTasksIds, this effect is precisely
           * updating selectedDisplayedTaskId, using first rxjs operator avoid infinite loop
           **/
          first(),
          switchMap(([displayedTaskIds, currentFloor]) => [
            /** Remove data previously loaded when studyMode was enabled**/
            FloorDataActions.removeFloorDataItemsByTaskIds({taskIds: [...displayedTaskIds].splice(1)}),
            TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds({taskIds: [...displayedTaskIds].splice(1)}),
            /** Set back edited floor model to null**/
            UiContextActions.isEditingFloorModel({id: null}),
            /** Set back selectedTaskId to currentFloor taskId**/
            TaskActions.updateSelectedTaskId({taskId: currentFloor.taskId}),
            /** Replace displayedTasks with current floor taskId **/
            UiContextActions.setDisplayedTasks({ids: [currentFloor.taskId]}),
            UiContextActions.setDisplayedTaskVisibility({ids: [currentFloor.taskId]}),
            /** Set blueprint to false **/
            RendererActions.setBlueprint({blueprint: false}),
            /** Reload FloorData **/
            GraphicsFloorActions.beginGetGraphicsFloorByTaskId({id: currentFloor.taskId})
          ])
        )),
    )
  );

  /**
   *
   * CODE BELOW MAKEs WHOLE UI CRASH WHEN SWITCHING BETWEEN WALLS OVERLAY AND ROOM OVERLAY
   *
   ***/

    // isEditingFloorModel$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(UiContextActions.isEditingFloorModel),
    //     switchMap(action =>
    //       this.store.select(FloorSelectors.selectCurrentFloor).pipe(
    //         tap(a => console.log(a)),
    //         concatMap(currentFloor => {
    //             if (currentFloor) {
    //               console.log(currentFloor);
    //               /** If user is editing Walls floor model in partioning study, display needed technical layers /*/
    //               if (action.id === FloorModelEnum.Walls) {
    //                 return [
    //                   this.toggleContoursFloorModels(currentFloor.taskId, true),
    //                   WallActions.showPartitioningFrame(),
    //                 ];
    //               } else {
    //                 return [
    //                   this.toggleContoursFloorModels(currentFloor.taskId, false),
    //                   WallActions.hidePartitioningFrame(),
    //                 ];
    //               }
    //             } else {
    //               console.log(currentFloor);
    //               return EMPTY;
    //               //return of(action); /** NEVER DO THAT AGAIN **/
    //             }
    //           }
    //         )
    //       )),
    //   ),
    // );

  cancelRoomRecalculateArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.cancelRoomRecalculateArea),
      switchMap((action) => of(RendererActions.clearRoomContour())),
      // catchError(err => of(err)),
    ),
  );

  // isCreatingEquipment$ = createEffect(() => this.actions$.pipe(
  //   ofType(UiContextActions.beginEquipmentCreation),
  //   concatMap(action => this.store.select(UseSelectors.getSelectedUsesIds).pipe(
  //     first(),
  //     switchMap(selectionStatus => {
  //       console.log(selectionStatus);
  //       if (selectionStatus.selecting) {
  //         return of(RendererActions.resetSelection());
  //       } else {
  //         return EMPTY;
  //       }
  //     })
  //   )),
  // ));

  isCreatingEquipment$ = createEffect(() => this.actions$.pipe(
    ofType(UiContextActions.beginEquipmentCreation),
    switchMap(action => of(UsesActions.clearSelection())),
  ));

  createDirectTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.createDirectTask),
      switchMap((action) => this.floorService.createDirectTask(action.ids, action.name).pipe(
        concatMap(dto => [
          TaskActions.addTask({task: dto.task}),
          TaskFloorActions.addTaskFloor({taskFloor: dto.taskFloor}),
          /** Load study **/
          UiContextActions.enableStudyMode({taskId: dto.task.id}),
        ]),
        catchError(err => of(UiContextActions.disableStudyMode())),
      )),
    ),
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }

  // toggleContoursFloorModels(taskId: number, isVisible: boolean):  Action {
  //   return TaskFloorModelActions.updateTaskFloorModelItems({
  //     updates: [
  //       {
  //         id: `${FloorModelEnum.FloorCoreContours}-${taskId}`,
  //         changes: {
  //           isVisible: isVisible
  //         }
  //       },
  //       {
  //         id: `${FloorModelEnum.FloorInsideContours}-${taskId}`,
  //         changes: {
  //           isVisible: isVisible
  //         }
  //       }] as Update<TaskFloorModel>[]
  //   });
  // }
}
