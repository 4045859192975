import {createSelector} from '@ngrx/store';
import * as fromRooActvityStatusType from '../reducers/room-activity-status-type.reducer';
import {selectSharedState} from '../reducers';

export const selectRoomActivityStatusTypes = createSelector(
  selectSharedState,
  state => state[fromRooActvityStatusType.roomActivityStatusTypesFeatureKey]
);

export const selectRoomActivityStatusTypeIds = createSelector(
  selectRoomActivityStatusTypes,
  fromRooActvityStatusType.selectRoomActivityStatusTypeIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectRoomActivityStatusTypeEntities = createSelector(
  selectRoomActivityStatusTypes,
  fromRooActvityStatusType.selectRoomActivityStatusTypeEntities
);
export const selectAllRoomActivityStatusTypes = createSelector(
  selectRoomActivityStatusTypes,
  fromRooActvityStatusType.selectAllRoomActivityStatusTypes
);
export const selectRoomActivityStatusTypeTotal = createSelector(
  selectRoomActivityStatusTypes,
  fromRooActvityStatusType.selectRoomActivityStatusTypeTotal
);

export const selectRoomActivityStatusTypeById = (id: number) => createSelector(
  selectRoomActivityStatusTypeEntities,
  (entities) => entities[id]
);
