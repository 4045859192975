import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {exhaustMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {State} from '../../../reducers';
import {TaskSelectors} from '../selectors';
import {TaskValidationActions} from '../actions';
import {TaskValidationAdapter} from '../../../domain-models/business/task-validation.model';


@Injectable()
export class TaskValidationEffects {

  // addTask$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskValidationActions.addTask),
  //   switchMap((action) => {
  //       const backendObj = this.taskAdapter.encode(action.update.changes as Task);
  //       return this.projectManagementService.patch(`${API_ENDPOINTS.dynT}${TASK.databaseTableName}/${action.update.id}`, backendObj).pipe(
  //         concatMap(apiResult => {
  //           let ganttTaskUpdate = this.ganttTaskAdapter.adaptFromTask(action.update.changes);
  //           return of(GanttTaskValidationActions.updateGanttTask({update: {id:ganttTaskUpdate.id, changes:ganttTaskUpdate}}));
  //         }),
  //       );
  //     }
  //   )),
  // );

  getTaskValidationsByTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(TaskValidationActions.getTaskValidationsByTaskId),
    exhaustMap((action) =>
      this.projectManagementService.getAllTaskValidationsByTaskId(action.taskId).pipe(
        //tap(d => console.log(d)),
        switchMap((taskValidations) =>
          [
            TaskValidationActions.addTaskValidations({taskValidations: taskValidations}),
          ]
        ),
      )
    )));

  getCurrentTaskTaskValidations$ = createEffect(() => this.actions$.pipe(
    ofType(TaskValidationActions.getCurrentTaskValidations),
    withLatestFrom(this.store.select(TaskSelectors.selectCurrentTaskId)),
    exhaustMap(([action, currentTaskId]) =>
      this.projectManagementService.getAllTaskValidationsByTaskId(currentTaskId).pipe(
        //tap(d => console.log(d)),
        switchMap((taskValidations) =>
          [
            TaskValidationActions.addTaskValidations({taskValidations: taskValidations}),
          ]
        ),
      )
    )));

  // updateTaskValidation$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskValidationActions.updateTaskValidation),
  //   switchMap((action) => {
  //       const backendObj = this.taskAdapter.encode(action.update.changes as TaskValidation);
  //       return this.projectManagementService.patch(`${API_ENDPOINTS.dynT}${TASK.databaseTableName}/${action.update.id}`, backendObj).pipe(
  //         concatMap(apiResult => {
  //           let ganttTaskUpdate = this.ganttTaskAdapter.adaptFromTask(action.update.changes);
  //           return of(GanttTaskValidationActions.updateGanttTask({update: {id:ganttTaskUpdate.id, changes:ganttTaskUpdate}}));
  //         }),
  //       );
  //     }
  //   )),
  // );

  // deleteTask$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskValidationActions.deleteTask),
  //   switchMap((action) => {
  //       return this.projectManagementService.delete(`${API_URL}tasks/${action.id}`).pipe(
  //         concatMap(apiResult => {
  //           return of(GanttTaskValidationActions.deleteGanttTask({id: action.id}));
  //         }),
  //       );
  //     }
  //   )),
  // );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private taskValidationAdapter: TaskValidationAdapter,
    private store: Store<State>
  ) {
  }
}
