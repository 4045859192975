import {createAction, props} from '@ngrx/store';
import {CreateRoomFormProps} from '../../../floor/models/room-creation-tool-props.model';
import {CreateWallProps} from '../../../floor/models/create-wall-props.model';
import {CreateDoorProps} from '../../../floor/models/create-door-props.model';
import {SvgPoint} from '../../../svg-factory/models/svg-point.model';
import {CreateEquipmentProps} from '../../../floor/models/create-equipment-props.model';
import {RoomContourProps} from '../../../floor/models/room-contour-props.model';

/**
 * Toggles
 */
export const toggleIsCreatingRoom = createAction('[Floor] - Toggle Room Creation Mode');
export const toggleIsRecalculatingArea = createAction('[Floor/FloorRoomForm] - Toggle Room Recalculate Area Mode');
export const setAddFloorDataToTaskMode = createAction(
  '[Floor/StudyMode] - Toggle is adding FloorData to Task',
  props<{ isAdding: boolean }>()
);
export const toggleRoomEditionMode = createAction('[Floor/EditRoom] - Toggle Room Edit Mode');

/**
 * Set modes
 */

export const enableStudyMode = createAction('[Floor] - Enable studyMode', props<{ taskId: number }>());
export const disableStudyMode = createAction('[Floor] - Disable studyMode');
export const removeStudy = createAction('[Floor] - Disable study', props<{ taskId: number }>());
export const studyModeError = createAction('[Floor] - Enable/Disable studyMode error', props<Error>());

export const setEquipmentCreationMode = createAction(
  '[Floor] - Set Equipment Creation Mode',
  props<{ creation: boolean }>()
);

export const isCreatingEquipment = createAction(
  '[FloorCatalog] - Is Creating Equipment',
  props<{ creating: boolean }>()
);

export const setSelectedFloorCatalogId = createAction(
  '[Floor] - Set SelectedFloorCatalog Id',
  props<{ id: number }>()
);

export const setRoomEditionMode = createAction(
  '[Floor] - Set Room Edit Mode',
  props<{ edit: boolean }>()
);

export const setRoomIsSelectable = createAction(
  '[Floor] - Room Is Selectable',
  props<{ selectable: boolean }>()
);

export const clearCreateRoomFormProps = createAction('[Floor/RoomCreationMode] - Clear Room Creation Tool Properties');
export const setCreateRoomFormProps = createAction(
  '[Floor/FloorRoomForm] - Set Room Creation Tool Properties',
  props<CreateRoomFormProps>()
);

export const beginRoomCreation = createAction('[Floor/FloorRoomFormComponent] - Begin room creation (isCreatingRoom is true)');
export const cancelRoomCreation = createAction('[Floor/FloorRoomFormComponent] - Cancel room creation (isCreatingRoom is false)');
export const createRoom = createAction(
  '[Floor/RoomContourToolTip] - Create Room',
  props<CreateRoomFormProps>()
);

export const recalculateRoom = createAction(
  '[Floor/RoomContourToolTip] - Re Calculate Room',
  props<{ roomId: number, props: RoomContourProps }>()
);

export const cancelRoomRecalculateArea = createAction('[Floor/FloorRoomFormComponent] - Cancel room recalculation');


export const successCreateRoom = createAction('[Floor] - Create Room');

export const beginWallCreation = createAction(
  '[Floor/FloorWallForm] - Begin wall creation',
  props<{ props: CreateWallProps }>()
);
export const cancelWallCreation = createAction('[Floor/FloorWallForm] - Cancel wall creation');
export const createWall = createAction(
  '[Floor/FloorWallForm] - Create Wall',
  props<{ props: CreateWallProps }>()
);

export const beginDoorCreation = createAction(
  '[Floor/FloorDoorForm] - Begin door creation',
  props<{ props: CreateDoorProps }>()
);
export const cancelDoorCreation = createAction('[Floor/FloorDoorForm] - Cancel door creation');
export const createDoor = createAction(
  '[Floor/FloorDoorForm] - Create Door',
  props<{ wallId: number, insertionPoint: SvgPoint }>()
);

export const beginEquipmentCreation = createAction(
  '[Floor/FloorEquipmentEdition] - Begin equipment creation',
  props<{ props: CreateEquipmentProps }>()
);
export const cancelEquipmentCreation = createAction('[Floor/FloorEquipmentEdition] - Cancel equipment creation');
export const createEquipment = createAction(
  '[Floor/FloorEquipmentEdition] - Create equipment',
  props<{ insertionPoint: SvgPoint, multiple: boolean }>()
);

export const setToolbarTitle = createAction(
  '[Floor] - Set left sidenav toolbar title',
  props<{ title: string }>()
);

export const setTable = createAction(
  '[Floor] - Set Table',
  props<{ label: string; endpoint: string; primaryFilterId: number; usageContextId: number }>()
);


export const setLeftSidenavMainTabLabel = createAction(
  '[Floor] - Set Left Sidenav Main Tab Label',
  props<{ label: string }>()
);

export const setFloorDisplayContext = createAction(
  '[Floor] - Set Floor Display Context Id',
  props<{ id: number }>()
);

export const isSelectingContour = createAction(
  '[Floor] - Is Selecting Contour',
  props<{ selecting: boolean }>(),
);

export const isEditingFloorModel = createAction(
  '[Floor/Study] - Is Editing FlMo id :',
  props<{ id: number }>(),
);

export const clearFloorUiContextState = createAction('[Floor] - Clear Floor Ui Context State');

/** Floor related **/
export const setFloorPerimetersIds = createAction('[API] - Set Current Floor Perimeter Ids', props<{ ids: number[] }>());
export const setCurrentTaskRoomLayoutTypeIds = createAction('[API] - Set Current Task RoomLayoutTypes Ids', props<{ ids: number[] }>());
export const setCurrentTaskActivityStatusTypeIds = createAction('[API] - Set Current Task ActivityStatusTYpe Ids', props<{ ids: number[] }>());
export const setCurrentTaskAttributionTypeIds = createAction('[API] - Set Current Task AttributionType Ids', props<{ ids: number[] }>());
export const setCurrentTaskBusinessUnitIds = createAction('[API] - Set Current Task BusinessUnit Ids', props<{ ids: number[] }>());
export const addDisplayedTask = createAction('[FloorComponent] - Add displayed task id', props<{ id: number }>());
export const setDisplayedTasks = createAction('[FloorComponent] - Set displayed task id', props<{ ids: number[] }>());

export const clearDisplayedTasks = createAction('[FloorComponent] - Clear displayed tasks ids');
export const setDisplayedTaskVisibility = createAction('[FloorLayersContainer] - Set displayed taskVisibility', props<{ ids: number[] }>());
export const addDisplayedTaskVisibility = createAction('[FloorLayersContainer] - Add displayed taskVisibility', props<{ id: number }>());
export const toggleDisplayedTaskVisibility = createAction('[FloorLayersContainer] - Toggle displayed taskVisibility', props<{ id: number }>());

/** MISC **/
export const createDirectTask = createAction(
  '[Floor/AreaContextMenu] - Create direct task shortcut',
  props<{ids: number[], name: string}>()
);
