import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {asyncScheduler, combineLatest, Observable, of} from 'rxjs';
import {concatMap, debounceTime, map} from 'rxjs/operators';
import {DisplayThemeItemActions} from '../actions';
import {ROOM_LAYOUT_TYPE, RoomLayoutType} from '../../../domain-models/business/room-layout-type.model';
import {SharedService} from '../../../shared/services/shared.service';
import {ROOM_ACTIVITY_STATUS_TYPE, RoomActivityStatusTypeEnum} from '../../../domain-models/business/room-activity-status-type.model';
import {BusinessUnit} from '../../../domain-models/business/business-unit.model';
import {DisplayThemeMockup} from '../../../floor/models/display-theme-mockup.model';
import {DisplayThemable, DisplayThemableEnum} from '../../../domain-models/business/display-themable.model';
import {DisplayTheme, DisplayThemeEnum} from '../../../domain-models/business/display-theme.model';
import {ThemeItemMockup} from '../../../floor/models/theme-item-mockup.model';
import {ROOM_ATTRIBUTION_TYPE, RoomAttributionTypeEnum} from '../../../domain-models/business/room-attribution-type.model';
import {DisplayThemeItem} from '../../../domain-models/business/display-theme-item.model';
import {ROOM_ALLOCATION} from '../../../domain-models/business/room-allocation.model';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import {DisplayThemeService} from '../../../shared/services/display-theme.service';
import {LanguageService} from '../../../shared/services/language.service';
import {BusinessUnitSelectors, RoomLayoutTypeSelectors} from '../selectors';

@Injectable()
export class DisplayThemeItemEffects {

  allRoomLayoutTypes$: Observable<RoomLayoutType[]>;
  allBusinessUnits$: Observable<BusinessUnit[]>;

  themeSource$ = combineLatest([this.displayThemeService.getDisplayTheme(),
    this.displayThemeService.getDisplayThemables(),
    this.displayThemeService.getDisplayThemeItems(),
    this.store.select(RoomLayoutTypeSelectors.selectAllRoomLayoutTypes),
    this.store.select(BusinessUnitSelectors.selectAllBusinessUnits)]).pipe(
  );

  buildDisplayThemes$ = createEffect(
    () => ({debounce = 0, scheduler = asyncScheduler} = {}) =>
      this.actions$.pipe(
        ofType(DisplayThemeItemActions.buildDisplayThemes),
        debounceTime(debounce, scheduler),
        concatMap(action =>
          this.themeSource$.pipe(
            map(([displayThemes,
                   displayThemables,
                   displayThemeItems,
                   layoutTypes,
                   businessUnits
                 ]) => {
              const themes = this.buildThemes(displayThemes);
              this.store.dispatch(DisplayThemeItemActions.addDisplayThemes({themes: themes}));
              this.mapDisplayThemeItems(displayThemables, displayThemeItems, layoutTypes, businessUnits);
            }),
            concatMap(_ => of(DisplayThemeItemActions.buildDisplayThemesSuccess()))
          )
        ))
  );

  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private displayThemeService: DisplayThemeService,
    private languageService: LanguageService,
    private store: Store<State>
  ) {
    this.allRoomLayoutTypes$ = this.store.select(RoomLayoutTypeSelectors.selectAllRoomLayoutTypes);
    this.allBusinessUnits$ = this.store.select(BusinessUnitSelectors.selectAllBusinessUnits);
  }

  buildThemes(displayThemes: DisplayTheme[]): DisplayThemeMockup[] {
    const themes = [] as DisplayThemeMockup[];
    displayThemes.forEach(e => {
      const theme = {
        id: e.id,
        name: this.languageService.searchDisplayName(e.displayNameId),
      } as DisplayThemeMockup;
      themes.push(theme);
    });
    //console.log(themes);
    return themes;
  }

  mapDisplayThemeItems(displayThemables: DisplayThemable[], displayThemeItems: DisplayThemeItem[], layoutTypes: RoomLayoutType[], businessUnits: BusinessUnit[]) {
    const layoutTypesThemeItems: ThemeItemMockup[] = [];
    const businessUnitsThemeItems: ThemeItemMockup[] = [];

    layoutTypes.forEach(type => {
      const item = {
        displayThemeIds: [],
        resId: ROOM_LAYOUT_TYPE.databaseTableName + '-' + type.id,
        name: type.name,
        color: type.color,
        //svgPatternId: undefined,
        active: true,
      } as ThemeItemMockup;
      layoutTypesThemeItems.push(item);
    });

    businessUnits.forEach(bu => {
      const item = {
        displayThemeIds: [],
        resId: ROOM_ALLOCATION.databaseTableName + '-' + bu.id,
        name: bu.name,
        color: bu.color,
        //svgPatternId: undefined,
        active: true,
      } as ThemeItemMockup;
      businessUnitsThemeItems.push(item);
    });

    displayThemeItems.forEach((themeItem) => {
      let items: ThemeItemMockup[] = [];

      const displayThemable = displayThemables.find(dt => dt.id === themeItem.displayThemableId);

      //if (displayThemable && displayThemable.id !== DisplayThemableEnum.NotAllocated) {
      if (displayThemable) {
        //console.log(displayThemable);
        let item = {
          displayThemeIds: [themeItem.displayThemeId],
          name: this.languageService.searchDisplayName(displayThemable.displayNameId),
          color: displayThemable.color,
          svgPatternId: displayThemable.svgPatternId,
          active: true,
        } as ThemeItemMockup;
        item = {...this.displayThemeIdToResId(displayThemable, item)};
        // if (displayThemable.id === DisplayThemableEnum.NotAllocated) {
        //   console.log(themeItem);
        //   console.log(displayThemable);
        //   console.log(item);
        // }
        if (
          (themeItem.displayThemeId === DisplayThemeEnum.LayoutType && displayThemable.id === DisplayThemableEnum.LayoutType) ||
          (themeItem.displayThemeId === DisplayThemeEnum.Allocations && displayThemable.id === DisplayThemableEnum.Allocated) ||
          (themeItem.displayThemeId === DisplayThemeEnum.Mixed && displayThemable.id === DisplayThemableEnum.Allocated)
        ) {
          // Push mappedLayoutTypes and mappedBusinessUnits instead of themeItem
          if (displayThemable.id === DisplayThemableEnum.LayoutType) {

            const mappedLayoutTypes = layoutTypesThemeItems.map(e => {
              e = {...e, displayThemeIds: [...e.displayThemeIds, themeItem.displayThemeId], svgPatternId: null};
              return e;
            });

            items = items.concat(mappedLayoutTypes);
          }

          if (displayThemable.id === DisplayThemableEnum.Allocated && (themeItem.displayThemeId === DisplayThemeEnum.Allocations || themeItem.displayThemeId === DisplayThemeEnum.Mixed)) {

            // Mapping array to resolve immutalbility issues as seen here https://medium.com/front-end-weekly/immutability-in-array-of-objects-using-map-method-dd61584c7188
            const mappedBusinessUnits = businessUnitsThemeItems.map(e => {
              e = {...e, displayThemeIds: [...e.displayThemeIds, themeItem.displayThemeId, DisplayThemeEnum.Mixed], svgPatternId: null};
              return e;
            });

            items = items.concat(mappedBusinessUnits);
          }

        } else {
          items.push(item);
        }
      }
      if (items.length > 0) {
        this.store.dispatch(DisplayThemeItemActions.addDisplayThemeItems({displayThemeItems: items}));
      }
    });
  }

  displayThemeIdToResId(displayThemable: DisplayThemable, themeItem: ThemeItemMockup): ThemeItemMockup {
    switch (displayThemable.id) {
      case DisplayThemableEnum.LayoutType:
        themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-LayoutType';
        return themeItem;
      case DisplayThemableEnum.None:
        themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.None;
        return themeItem;
      case DisplayThemableEnum.Empty:
        themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + RoomActivityStatusTypeEnum.Empty;
        return themeItem;
      case DisplayThemableEnum.MultiAllocated:
        themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-MultiAllocated';
        return themeItem;
      case DisplayThemableEnum.NotAllocated:
        themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-NotAllocated';
        return themeItem;
      case DisplayThemableEnum.Used:
        //themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + RoomActivityStatusTypeEnum.Usable;
        return themeItem;
      case DisplayThemableEnum.UnderConstruction:
        themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + RoomActivityStatusTypeEnum.UnderConstruction;
        return themeItem;
      case DisplayThemableEnum.Shared:
        themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.Sharing;
        return themeItem;
      case DisplayThemableEnum.Allocated:
        //  themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.Allocation;
        return themeItem;
      case DisplayThemableEnum.Exploitation:
        themeItem.displayThemeIds.push(DisplayThemeEnum.Mixed);
        themeItem.resId = ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + RoomAttributionTypeEnum.Exploitation;
        return themeItem;
    }

  }


}
