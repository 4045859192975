import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {API_ENDPOINTS} from '../../shared/api-endpoints';
import {HttpClient} from '@angular/common/http';
import {catchError, concatMap, distinctUntilChanged, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {LoggingService} from './logging.service';
import {ApiData} from '../models/api-data.model';
import {APP_USER, AppUser, AppUserAdapter} from '../../domain-models/business/app-user.model';
import {IdentityClaims} from '../models/identity-claims.model';
import {State} from '../../reducers';
import {Store} from '@ngrx/store';
import {AuthService} from './auth.service';
import {CoreActions} from '../../store/core-store/actions';
import {User} from '../../domain-models/business-extended/user.model';

@Injectable({
  providedIn: 'root'
})

export class UserService extends ApiService {

  //public user$ = new ReplaySubject<User>(1);
  private user: User;
  private identityClaims: IdentityClaims;

  constructor(http: HttpClient,
              loggingService: LoggingService,
              private authservice: AuthService,
              private store: Store<State>,
              private appUserAdapter: AppUserAdapter) {
    super(http, loggingService);
    this.authservice.isAuthenticated$.pipe(
      filter(isAuthenticated => isAuthenticated),
      first(),
      distinctUntilChanged(),
      concatMap(isAuthenticated => this.setUserFromIdentity().pipe(
        tap(user => this.store.dispatch(CoreActions.setUser({user: user}))),
      )),
    ).subscribe();

    //this.identityClaims = this.authservice.identityClaims as IdentityClaims;
    //this.setUserFromIdentity().subscribe();
    //console.log(this.authService.identityClaims);
    // if (localStorage.getItem('id_token_claims_obj')) {
    //   this.setUserFromIdentity().then(() => {
    //     if (localStorage.getItem('client_file')) {
    //       this.setEcsyUser();
    //     }
    //   });
    // }
  }

  /** Set user based on ID token claims **/
  public setUserFromIdentity(): Observable<User> {
    return new Observable((observer) => {
      const user = {} as User;
      const idTokenClaims = JSON.parse(localStorage.getItem('id_token_claims_obj')) as IdentityClaims;

      if (idTokenClaims) {
        //this.user = user;
        //this.idTokenClaims = idTokenClaims;
        user.name = idTokenClaims.name;
        user.role = idTokenClaims.role;
        user.sub = idTokenClaims.sub;
        //user.admin = user.role === 'EchoAdmin';
        //this.store.dispatch(CoreActions.setUser({user: user}));
        observer.next(user);
      } else {
        observer.error('User is not available');
      }

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
        }
      };
    });
  }

  getEcsyUser(): Observable<AppUser> {
    return this.get(API_ENDPOINTS.appUsersMe)
      .pipe(
        map(data => {
          if (data.payload) {
            return this.appUserAdapter.adapt(data.payload);
          } else {
            this.loggingService.displayError(data.message);
          }
        }),
        catchError(this.handleError)
      );
  }

  getAppUsers(): Observable<AppUser[]> {
    return this.get(`${API_ENDPOINTS.dynT}${APP_USER.databaseTableName}`).pipe(
      map((data: ApiData) => data.payload.map(user => this.appUserAdapter.adapt(user)))
    );
  }
}
