import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const FLOOR_CATALOG = {
  databaseTableName: 'FloorCatalog',
  id: 'FlCg_Id',
  code: 'FlCg_Code',
  name: 'FlCg_Name',
  description: 'FlCg_Description',
  svgStatement: 'FlCg_SvgStatement',
  providerCompanyId: 'FlCg_ProviderCompanyId',
  floorModelId: 'FlCg_FloorModeld',
  svgBoundingBox: 'FlCg_SvgBoundingBox',
};

export class FloorCatalog {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public name: string,
    public description: string,
    public svgStatement: string,
    public providerCompanyId: number,
    public floorModelId: number,
    public svgBoundingBox: string,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class FloorCatalogAdapter implements Adapter<FloorCatalog> {
  adapt(item: any): FloorCatalog {
    return new FloorCatalog(
      FLOOR_CATALOG.databaseTableName,
      item[FLOOR_CATALOG.id],
      item[FLOOR_CATALOG.code],
      item[FLOOR_CATALOG.name],
      item[FLOOR_CATALOG.description],
      item[FLOOR_CATALOG.svgStatement],
      item[FLOOR_CATALOG.providerCompanyId],
      item[FLOOR_CATALOG.floorModelId],
      item[FLOOR_CATALOG.svgBoundingBox],
    );
  }

  encode(item: FloorCatalog): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_CATALOG.databaseTableName,
      [FLOOR_CATALOG.id]: item.id,
      [FLOOR_CATALOG.code]: item.code,
      [FLOOR_CATALOG.name]: item.name,
      [FLOOR_CATALOG.description]: item.description,
      [FLOOR_CATALOG.svgStatement]: item.svgStatement,
      [FLOOR_CATALOG.providerCompanyId]: item.providerCompanyId,
      [FLOOR_CATALOG.floorModelId]: item.floorModelId,
      [FLOOR_CATALOG.svgBoundingBox]: item.svgBoundingBox,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
