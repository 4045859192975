import {Pipe, PipeTransform} from '@angular/core';
import {ROOM_ALLOCATION} from '../../domain-models/business/room-allocation.model';
import {State} from '../../reducers';
import {Store} from '@ngrx/store';
import {RoomAllocationSelectors, RoomSelectors} from '../../store/floor-store/selectors';
import {EMPTY, Observable} from 'rxjs';
import {ROOM_LAYOUT_TYPE} from '../../domain-models/business/room-layout-type.model';
import {ROOM_ATTRIBUTION_TYPE, RoomAttributionTypeEnum} from '../../domain-models/business/room-attribution-type.model';
import {ROOM_ACTIVITY_STATUS_TYPE, RoomActivityStatusTypeEnum} from '../../domain-models/business/room-activity-status-type.model';

@Pipe({
  name: 'roomArea'
})
export class RoomAreaPipe implements PipeTransform {

  constructor(private store: Store<State>) {
  }

  transform(resId: string, taskId?: number, buildingId?: number): Observable<number> {
    try {
      /** Split ThemeItem resId in {tableName-id} **/
      const strArr = resId.split('-');
      const tableName = strArr[0];
      const id = strArr[1];

      switch (tableName) {
        case ROOM_LAYOUT_TYPE.databaseTableName: {
          /** id is RoLaTy_Id **/
          if (buildingId) {
            return this.store.select(RoomSelectors.selectBuildingRoomLayoutTypeAreaById(Number(id), buildingId));
          } else if (taskId) {
            return this.store.select(RoomSelectors.selectFloorRoomLayoutTypeAreaById(Number(id), taskId));
          }
          return EMPTY;
        }
        case ROOM_ALLOCATION.databaseTableName: {
          /** id is BuUn_Id **/
          if (buildingId) {
            return this.store.select(RoomAllocationSelectors.selectBuildingRoomAllocationAreaById(Number(id), buildingId));
          } else if (taskId) {
            return this.store.select(RoomAllocationSelectors.selectFloorRoomAllocationAreaById(Number(id), taskId));
          }
          return EMPTY;
        }
        case ROOM_ATTRIBUTION_TYPE.databaseTableName: {
          if (id === 'NotAllocated') {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingNotAllocatedArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorNotAllocatedArea(taskId));
            }
          } else if (id === 'MultiAllocated') {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingMultiAllocatedArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorMultiAllocatedArea(taskId));
            }
          } else if (Number(id) === RoomAttributionTypeEnum.Allocation) {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingAllocatedArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorAllocatedArea(taskId));
            }
          } else if (Number(id) === RoomAttributionTypeEnum.Sharing) {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingSharingdArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorSharingArea(taskId));
            }
          } else if (Number(id) === RoomAttributionTypeEnum.Exploitation) {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingExploitationArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorExploitationArea(taskId));
            }
          }
          return EMPTY;
        }
        case ROOM_ACTIVITY_STATUS_TYPE.databaseTableName: {
          if (Number(id) === RoomActivityStatusTypeEnum.Usable) {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingUsableArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorUsableArea(taskId));
            }
          } else if (Number(id) === RoomActivityStatusTypeEnum.Empty) {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingEmptyArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorEmptyArea(taskId));
            }
          } else if (Number(id) === RoomActivityStatusTypeEnum.UnderConstruction) {
            if (buildingId) {
              return this.store.select(RoomSelectors.selectBuildingUnderConstructionArea(buildingId));
            } else if (taskId) {
              return this.store.select(RoomSelectors.selectFloorUnderConstructionArea(taskId));
            }
          }
          return EMPTY;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

}
