import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {catchError, concatMap, first, map, switchMap, tap} from 'rxjs/operators';

import { FloorService } from '../../../floor/services/floor.service';
import { Store } from '@ngrx/store';
import {
  EquipmentActions,
  FloorCatalogActions, RendererActions,
  TaskFloorModelActions,
  UsesActions,
} from '../actions';
import { State } from '../../../reducers';
import { Update } from '@ngrx/entity';
import * as FloorDataActions from '../actions/floor-data.actions';
import { SvgUse } from '../../../svg-factory/models/svg-use.model';
import { UiContextActions } from '../../floor-store/actions';
import { UiContextSelectors } from '../../floor-store/selectors';
import { combineLatest, of } from 'rxjs';
import { TaskSelectors } from '../../project-management/selectors';

@Injectable()
export class EquipmentEffects {

  equipmentTransformUpdateError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.equipmentTransformUpdateError),
      switchMap(error => [RendererActions.resetSelection()]),
    )
  );

  translateEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.translateEquipment),
      switchMap((action) =>
        this.floorService
          .translateEquipment(action.id, action.translationVector)
          .pipe(
            switchMap((data) => [
              UsesActions.upsertUse({
                svgUse: data.svgUse,
              }),
              // FloorDataActions.upsertFloorDataItems({
              //   svgGroups: data.svgGroups,
              // }),
            ]),
            catchError((err) => of(EquipmentActions.equipmentTransformUpdateError()))
          )
      )
    )
  );

  translateEquipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.translateEquipments),
      switchMap((action) =>
        this.floorService
          .translateEquipments(action.ids, action.translationVector)
          .pipe(
            switchMap((data) =>
              [
                UsesActions.upsertUses({
                  svgUses: data.svgUses,
                }),
                // FloorDataActions.upsertFloorDataItems({
                //   svgGroups: data.svgGroups
                // })
              ]
            ),
            catchError((err) => of(EquipmentActions.equipmentTransformUpdateError()))
          )
      )
    )
  );

  rotateEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.rotateEquipment),
      switchMap((action) =>
        this.floorService.rotateEquipment(action.rotation).pipe(
          switchMap((data) =>
            [
              UsesActions.upsertUse({
                svgUse: data.svgUse,
              }),
              // FloorDataActions.upsertFloorDataItems({
              //   svgGroups: data.svgGroups
              // })
            ]
          ),
          catchError((err) => of(EquipmentActions.equipmentTransformUpdateError()))
        )
      )
    )
  );

  rotateEquipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.rotateEquipments),
      switchMap((action) =>
        this.floorService.rotateEquipments(action.rotations).pipe(
          switchMap((data) =>
            [
              UsesActions.upsertUses({
                svgUses: data.svgUses,
              }),
              // FloorDataActions.upsertFloorDataItems({
              //   svgGroups: data.svgGroups
              // })
            ]
          ),
          catchError((err) => of(EquipmentActions.equipmentTransformUpdateError()))
        )
      )
    )
  );

  deleteEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.deleteEquipment),
      switchMap((action) =>
        this.floorService.deleteEquipment(action.id).pipe(
          concatMap((data) => {
            const updates = [] as Update<SvgUse>[];
            const removeFloorDataIds = [] as number[];
            data.forEach((item) => {
              if (item.dataStateId) {
                updates.push({
                  id: item.floorDataId,
                  changes: {
                    dataStateId: item.dataStateId,
                  },
                });
              } else {
                removeFloorDataIds.push(item.floorDataId);
              }
            });
            return [
              UsesActions.deleteUses({ ids: removeFloorDataIds }),
              FloorDataActions.removeFloorDataItems({
                ids: removeFloorDataIds,
              }),
              UsesActions.updateUses({ svgUses: updates }),
              RendererActions.resetSelection(),
            ];
          })
        )
      )
    )
  );

  deleteEquipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EquipmentActions.deleteEquipments),
      switchMap((action) =>
        this.floorService.deleteEquipments(action.ids).pipe(
          concatMap((data) => {
            const updates = [] as Update<SvgUse>[];
            const removeFloorDataIds = [] as number[];
            data.forEach((item) => {
              if (item.dataStateId) {
                updates.push({
                  id: item.floorDataId,
                  changes: {
                    dataStateId: item.dataStateId,
                  },
                });
              } else {
                removeFloorDataIds.push(item.floorDataId);
              }
            });
            // const arr = [...removeFloorDataIds.map(id => FloorDataActions.removeFromSelection({id: id}))];
            return [
              // ...arr,
              FloorDataActions.removeFloorDataItems({
                ids: removeFloorDataIds,
              }),
              UsesActions.deleteUses({ ids: removeFloorDataIds }),
              UsesActions.updateUses({ svgUses: updates }),
              RendererActions.resetSelection()
          ];
          })
        )
      )
    )
  );

  createEquipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UiContextActions.createEquipment),
      concatMap((action) =>
        combineLatest([
          this.store.select(UiContextSelectors.selectCreateEquipmentProps),
          this.store.select(TaskSelectors.selectCurrentTaskId),
        ]).pipe(
          first(),
          concatMap(([createEquipmentProps, currentTaskId]) =>
            this.floorService
              .createEquipment(
                currentTaskId,
                createEquipmentProps.catalogId,
                action.insertionPoint
              )
              .pipe(
                concatMap((result) => {
                  const returnActions = [
                    TaskFloorModelActions.addTaskFloorModel({
                      taskFloorModel: result.floorModel,
                    }),
                    FloorCatalogActions.addFloorCatalog({
                      svgGroup: result.floorCatalog,
                    }),
                    UsesActions.addUse({ svgUse: result.svgUse }),
                  ];
                  if (action.multiple) {
                    return returnActions;
                  } else {
                    return [
                      ...returnActions,
                      UiContextActions.cancelEquipmentCreation(),
                    ];
                  }
                }),
                catchError((err) =>
                  of(UiContextActions.cancelEquipmentCreation())
                )
              )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {}
}
