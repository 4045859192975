import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isNaN'
})
export class IsNaNPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): boolean {
    return isNaN(Number(value));
  }

}
