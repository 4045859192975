import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {FloorModel} from '../../../domain-models/business/floor-model.model';
import {FloorModelActions} from '../actions';

export const floorModelsFeatureKey = 'floorModels';

export interface State extends EntityState<FloorModel> {}

export const adapter: EntityAdapter<FloorModel> = createEntityAdapter<FloorModel>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(FloorModelActions.addFloorModels, (state, {floorModels}) => {
    return adapter.addMany(floorModels, state);
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorModelsIds = selectIds;
export const selectFloorModelsEntities = selectEntities;
export const selectAllFloorModels = selectAll;
export const selectFloorModelsTotal = selectTotal;
