import {createSelector} from '@ngrx/store';
import * as fromWorkplaceAllocation from '../reducers/workplace-allocation.reducer';
import {selectFloorsState} from '../reducers';
import {State} from '../reducers/room.reducer';

/** Select store slice **/

export const selectWorkplaceAllocations = createSelector(
  selectFloorsState,
  state => state[fromWorkplaceAllocation.workplaceAllocationsFeatureKey]
);

/** Basics selectors **/

export const selectWorkplaceAllocationIds = createSelector(
  selectWorkplaceAllocations,
  fromWorkplaceAllocation.selectWorkplaceAllocationIds // shorthand for usersState => fromWorkplaceAllocation.selectWorkplaceAllocationIds(usersState)
);
export const selectWorkplaceAllocationEntities = createSelector(
  selectWorkplaceAllocations,
  fromWorkplaceAllocation.selectWorkplaceAllocationEntities
);
export const selectAllWorkplaceAllocations = createSelector(
  selectWorkplaceAllocations,
  fromWorkplaceAllocation.selectAllWorkplaceAllocations
);
export const selectWorkplaceAllocationTotal = createSelector(
  selectWorkplaceAllocations,
  fromWorkplaceAllocation.selectWorkplaceAllocationTotal
);
// export const selectCurrentWorkplaceAllocationId = createSelector(
//   selectWorkplaceAllocations,
//   fromWorkplaceAllocation.getSelectedWorkplaceAllocationId
// );

// export const selectCurrentWorkplaceAllocation = createSelector(
//   selectWorkplaceAllocationEntities,
//   selectCurrentWorkplaceAllocationId,
//   (workplaceAllocationEntities, workplaceAllocationId) => workplaceAllocationEntities[workplaceAllocationId]
// );

export const getSelectedWorkplaceAllocationIds = createSelector(
  selectWorkplaceAllocations,
  (state: State) => state.selectedIds
);

export const getSelectedRooms = createSelector(
  selectAllWorkplaceAllocations,
  getSelectedWorkplaceAllocationIds,
  (workplaceAllocations, ids) =>
    workplaceAllocations.filter(a => a.floorDataId === ids.find(e => e === a.floorDataId))
);

/** Advanced selectors **/

// export const getSelectedWorkplaceAllocationId = createSelector(
//   selectWorkplaceAllocations,
//   fromWorkplaceAllocation.getSelectedWorkplaceAllocationId
// );

