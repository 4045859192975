import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as RoomAttributionTypeActions from '../actions/room-attribution-type.actions';
import {RoomAttributionType} from '../../../domain-models/business/room-attribution-type.model';

export const roomAttributionTypesFeatureKey = 'roomAttributionTypes';

export interface State extends EntityState<RoomAttributionType> {}

export const adapter: EntityAdapter<RoomAttributionType> = createEntityAdapter<RoomAttributionType>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(RoomAttributionTypeActions.successGetAllRoomAttributionTypes, (state, {roomAttributionTypes}) => {
    return adapter.addMany(roomAttributionTypes, state);
  }),
  on(RoomAttributionTypeActions.addRoomAttributionType, (state, roomLayoutType) => {
    return adapter.addOne(roomLayoutType, state);
  })
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectRoomAttributionTypeIds = selectIds;
export const selectRoomAttributionTypeEntities = selectEntities;
export const selectAllRoomAttributionTypes = selectAll;
export const selectRoomAttributionTypeTotal = selectTotal;
