import {createSelector} from '@ngrx/store';
import * as fromTaskContributor from '../reducers/task-contributor.reducer';
import {selectProjectManagementState} from '../reducers';
import {selectCurrentTask} from './task.selectors';

/** Select store slice **/

export const selectTaskContributors = createSelector(
  selectProjectManagementState,
  state => state[fromTaskContributor.taskContributorFeatureKey]
);

/** Basics selectors **/

export const selectTaskContributorIds = createSelector(
  selectTaskContributors,
  fromTaskContributor.selectTaskContributorIds
);
export const selectTaskContributorEntities = createSelector(
  selectTaskContributors,
  fromTaskContributor.selectTaskContributorEntities
);
export const selectAllTaskContributors = createSelector(
  selectTaskContributors,
  fromTaskContributor.selectAllTaskContributors
);
export const selectTaskContributorTotal = createSelector(
  selectTaskContributors,
  fromTaskContributor.selectTaskContributorTotal
);
export const selectCurrentTaskContributorId = createSelector(
  selectTaskContributors,
  fromTaskContributor.getSelectedTaskContributorId
);

export const selectCurrentTaskContributor = createSelector(
  selectTaskContributorEntities,
  selectCurrentTaskContributorId,
  (taskContributorEntities, taskContributorId) => taskContributorEntities[taskContributorId]
);

/** Advanced selectors **/

export const selectTaskContributorById = (id: string) => createSelector(
  selectTaskContributorEntities,
  (entities) => entities[id]
);

export const selectTaskContributorsByTaskId = (taskId: number) => createSelector(
  selectAllTaskContributors,
  (taskContributors) => taskContributors.filter(c => c.taskId === taskId)
);

export const selectCurrentTaskContributors = createSelector(
  selectCurrentTask,
  selectAllTaskContributors,
  (currentTask, taskContributors) => {
    if (currentTask) {
      return taskContributors.filter(taCr => taCr.taskId === currentTask.id)
    } else {
      return null;
    }
  }
);
