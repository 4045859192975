import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const FLOOR = {
  databaseTableName: 'floor',
  id: 'Fl_Id',
  buildingId: 'Fl_BuildingId',
  code: 'Fl_Code',
  name: 'Fl_Name',
  isActive: 'Fl_IsActive',
  isFictive: 'Fl_IsFictive',
  lease: 'Fl_Lease',
  location: 'Fl_Location',
  area: 'Fl_Area',
  elevation: 'Fl_Elevation',
  taskId: 'Fl_TaskId',
  defaultPerimeterId: 'Fl_DefaultPerimeterId',
};

export class Floor {
  constructor(
    public databaseTableName: string,
    public id: number,
    public buildingId: number,
    public code: string,
    public name: string,
    public isActive: boolean,
    public isFictive: boolean,
    public lease: string,
    public location: string,
    public area: number,
    public elevation: number,
    public taskId: number,
    public defaultPerimeterId: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class FloorAdapter implements Adapter<Floor> {
  adapt(item: any): Floor {
    return new Floor(
      FLOOR.databaseTableName,
      item[FLOOR.id],
      item[FLOOR.buildingId],
      item[FLOOR.code],
      item[FLOOR.name],
      item[FLOOR.isActive],
      item[FLOOR.isFictive],
      item[FLOOR.lease],
      item[FLOOR.location],
      item[FLOOR.area],
      item[FLOOR.elevation],
      item[FLOOR.taskId],
      item[FLOOR.defaultPerimeterId]
    );
  }

  encode(item: Floor): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [FLOOR.id]: item.id,
      [FLOOR.buildingId]: item.buildingId,
      [FLOOR.code]: item.code,
      [FLOOR.name]: item.name,
      [FLOOR.isActive]: item.isActive,
      [FLOOR.isFictive]: item.isFictive,
      [FLOOR.lease]: item.lease,
      [FLOOR.location]: item.location,
      [FLOOR.area]: item.area,
      [FLOOR.elevation]: item.elevation,
      [FLOOR.taskId]: item.taskId,
      [FLOOR.defaultPerimeterId]: item.defaultPerimeterId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
