import {createAction, props} from '@ngrx/store';
import {Perimeter} from '../../../domain-models/business/perimeter.model';

export const beginGetAllPerimeters = createAction('[Shared/API] - Begin Get All Perimeters');

export const successGetAllPerimeters = createAction(
  '[Shared/API] - Success Get All Perimeters',
  props<{ perimeters: Perimeter[] }>()
);

export const errorGetAllPerimeters = createAction('[Shared/API] - Error Get All Perimeters', props<Error>());
