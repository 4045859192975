import {createAction, props} from '@ngrx/store';
import {GrabCircle} from '../../../svg-factory/components/overlays/svg-walls-overlay/svg-walls-overlay.component';
import {SvgPoint} from '../../../svg-factory/models/svg-point.model';

export const stretchWall = createAction(
  '[Floor/API] Stretch wall (SvgEntity)',
  props<{ newPosition: SvgPoint, grabCircle: GrabCircle }>()
);

export const setGripDetectionMode = createAction(
  '[Floor/WallOverlay] Set gripDetectionMode ',
  props<{ mode: number }>()
);

export const nextGripDetectionMode = createAction('[Floor/WallOverlay] Cycle over GripAlignmentTypes');

export const showPartitioningFrame = createAction('[Floor/WallOverlay] Show PartitioningFrame');
export const hidePartitioningFrame = createAction('[Floor/WallOverlay] Hide PartitioningFrame');

export const showFloorCoreContours = createAction('[Floor/WallOverlay] Show FloorCoreContours');
export const hideFloorCoreContours = createAction('[Floor/WallOverlay] Hide FloorCoreContours');

export const showFloorInsideContours = createAction('[Floor/WallOverlay] Show FloorInsideContours');
export const hideFloorInsideContours = createAction('[Floor/WallOverlay] Hide FloorInsideContours');


export const updateWallStyle = createAction(
  '[Floor/WallOverlay] - Update wall style',
  props<{ id: number, wallStyleId: number }>(),
);

export const updateWallsStyle = createAction(
  '[Floor/WallOverlay] - Update walls style',
  props<{ ids: number[], wallStyleId: number }>(),
);

export const updateWallWidth = createAction(
  '[Floor/WallOverlay] - Update wall width',
  props<{ id: number, width: number }>(),
);

export const updateWallsWidth = createAction(
  '[Floor/WallOverlay] - Update walls width',
  props<{ ids: number[], width: number }>(),
);

export const updateWallSpaceBound = createAction(
  '[Floor/WallOverlay] - Update wall space bound',
  props<{ id: number, spaceBound: boolean }>(),
);

export const updateWallsSpaceBound = createAction(
  '[Floor/WallOverlay] - Update walls space bound',
  props<{ ids: number[], spaceBound: boolean }>(),
);

export const deleteWall = createAction(
  '[Floor/WallOverlay] - Delete wall',
  props<{ id: number }>(),
);

export const deleteWalls = createAction(
  '[Floor/WallOverlay] - Delete walls',
  props<{ ids: number[] }>(),
);
