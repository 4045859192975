import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, switchMap, tap} from 'rxjs/operators';

import {FloorService} from '../../../floor/services/floor.service';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import {UsesActions} from '../actions';

@Injectable()
export class FloorUsesEffects {

  /** Add existing FlDa to Ta **/

  deleteUse$ = createEffect(
    () => this.actions$.pipe(
      ofType(UsesActions.deleteUse),
      concatMap(action => this.floorService.deleteFloorData(action.id)),
      tap(r => console.log(r)),
      switchMap((result) => [
        UsesActions.updateSelectedUseId({id: null})
      ]),
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store$: Store<State>
  ) {
  }
}
