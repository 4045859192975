import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as BusinessUnitsActions from '../actions/business-unit.actions';
import {BusinessUnit} from '../../../domain-models/business/business-unit.model';

export const businessUnitsFeatureKey = 'businessUnits';

export interface State extends EntityState<BusinessUnit> {
  allBusinessUnitsLoaded: boolean;
}

export const adapter: EntityAdapter<BusinessUnit> = createEntityAdapter<BusinessUnit>();

export const initialState: State = adapter.getInitialState({
  allBusinessUnitsLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(BusinessUnitsActions.successGetAllBusinessUnits, (state, {businessUnits}) => {
    state = {...state, allBusinessUnitsLoaded: true};
    return adapter.addMany(businessUnits, state);
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectBusinessUnitsIds = selectIds;
export const selectBusinessUnitsEntities = selectEntities;
export const selectAllBusinessUnits = selectAll;
export const selectBusinessUnitsTotal = selectTotal;
