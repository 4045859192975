import {createAction, props} from '@ngrx/store';
import {EntityMap, Predicate, Update} from '@ngrx/entity';
import {TaskBusinessType} from '../../../domain-models/business/task-business-type.model';

/**
 * CRUD Actions
 */

export const loadTaskBusinessTypes = createAction('[TaskBusinessType/API] Load TaskBusinessTypes', props<{ taskBusinessTypes: TaskBusinessType[] }>());
export const addTaskBusinessType = createAction('[TaskBusinessType/API] Add TaskBusinessType', props<{ taskBusinessType: TaskBusinessType }>());
export const setTaskBusinessType = createAction('[TaskBusinessType/API] Set TaskBusinessType', props<{ taskBusinessType: TaskBusinessType }>());
export const upsertTaskBusinessType = createAction('[TaskBusinessType/API] Upsert TaskBusinessType', props<{ taskBusinessType: TaskBusinessType }>());
export const addTaskBusinessTypes = createAction('[TaskBusinessType/API] Add TaskBusinessTypes', props<{ taskBusinessTypes: TaskBusinessType[] }>());
export const upsertTaskBusinessTypes = createAction('[TaskBusinessType/API] Upsert TaskBusinessTypes', props<{ taskBusinessTypes: TaskBusinessType[] }>());
export const updateTaskBusinessType = createAction('[TaskBusinessType/API] Update TaskBusinessType', props<{ update: Update<TaskBusinessType> }>());
export const updateTaskBusinessTypes = createAction('[TaskBusinessType/API] Update TaskBusinessTypes', props<{ updates: Update<TaskBusinessType>[] }>());
export const mapTaskBusinessTypes = createAction('[TaskBusinessType/API] Map TaskBusinessTypes', props<{ entityMap: EntityMap<TaskBusinessType> }>());
export const deleteTaskBusinessType = createAction('[TaskBusinessType/API] Delete TaskBusinessType', props<{ id: string }>());
export const deleteTaskBusinessTypes = createAction('[TaskBusinessType/API] Delete TaskBusinessTypes', props<{ ids: string[] }>());
export const deleteTaskBusinessTypesByPredicate = createAction('[TaskBusinessType/API] Delete TaskBusinessTypes By Predicate', props<{ predicate: Predicate<TaskBusinessType> }>());
export const clearTaskBusinessTypes = createAction('[TaskBusinessType/API] Clear TaskBusinessTypes');

/** END of CRUD **/

export const beginGetAllTaskBusinessTypes = createAction('[TaskBusinessType/API] - Begin Get All TaskBusinessTypes');
export const errorGetAllTaskBusinessTypes = createAction('[API] - Error Get All TaskBusinessTypes', props<Error>());

export const updateSelectedTaskBusinessTypeId = createAction(
  '[TaskBusinessType/API] - Save Selected TaskBusinessType Id',
  props<{ taskBusinessTypeId: number }>()
);
