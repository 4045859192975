import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs';
import {FloorService} from '../../floor/services/floor.service';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'directoryName'
})
export class DirectoryNamePipe implements PipeTransform {

  constructor(private floorService: FloorService) {
  }

  transform(directoryId: number, /**directory: Directory[]**/): Observable<string> {
    try {
      return this.floorService.getDirectory().pipe(
        map(directory => {
          const directoryItem = directory.find(item => item.id === directoryId);
          return `${directoryItem.firstName} ${directoryItem.lastName}`;
        })
      );
    } catch (e) {
      console.log(e);
    }
  }

}
