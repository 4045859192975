import {Injectable} from '@angular/core';

import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from '../../floor-store/reducers';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';


@Injectable()
export class GanttTaskEffects {

  // addGanttTask$ = createEffect(() => this.actions$.pipe(
  //   ofType(GanttTaskActions.createGanttTask),
  //   switchMap((action) => {
  //       //const backendObj = this.taskAdapter.encode(action.update.changes as Task);
  //       return this.projectManagementService.post(`${API_URL}/tasks`, action.taskCreationDto).pipe(
  //         concatMap(apiResult => {
  //           //let ganttTaskUpdate = this.ganttTaskAdapter.adaptFromTask(action.update.changes);
  //           return of(GanttTaskActions.updateGanttTask({update: {id:ganttTaskUpdate.id, changes:ganttTaskUpdate}}));
  //         }),
  //       );
  //     }
  //   )),
  // );

  // updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskActions.updateSelectedTaskId),
  //   )
  // );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }
}
