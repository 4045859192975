import {createAction, props} from '@ngrx/store';
import {DisplayThemeMockup} from '../../../floor/models/display-theme-mockup.model';
import {ThemeItemMockup} from '../../../floor/models/theme-item-mockup.model';
import {Update} from '@ngrx/entity';

export const addDisplayThemes = createAction(
  '[API] - Add DisplayTheme',
  props<{themes: DisplayThemeMockup[]}>()
);

export const updateDisplayThemeId = createAction(
  '[API] - Select DisplayTheme Id',
  props<{id: number}>()
);

export const updateDisplayThemeItem = createAction(
  '[API] - Update DisplayThemes',
  props<{displayThemeItem: Update<DisplayThemeMockup>}>()
);

export const addDisplayThemeItems = createAction(
  '[API] - Add DisplayThemeItems',
  props<{ displayThemeItems: ThemeItemMockup[] }>()
);

export const buildDisplayThemes = createAction(
  '[API] - Build DisplayThemes'
);

export const buildDisplayThemesSuccess = createAction(
  '[API] - Succeed Build DisplayThemes'
);

export const setDisplayThemeLegendContext = createAction(
  '[API] - Set display theme legend context',
  props<{ id: number }>()
);

export const setHoveredThemeItemId = createAction(
  '[API] - Set hovered theme item id',
  props<{ id: string }>()
);
