import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const DIRECTORY = {
  databaseTableName: 'directory',
  id: 'Di_Id',
  code: 'Di_Code',
  title: 'Di_Title',
  firstName: 'Di_FirstName',
  lastName: 'Di_LastName',
  businessUnitId: 'Di_BusinessUnitId',
  floor: 'Di_Floor',
  room: 'Di_Room',
  workplace: 'Di_Workplace',
  locker: 'Di_Locker',
  occupancyRatio: 'Di_OccupancyRatio'
};

export class Directory {
  constructor(
    public databaseTableName: string,
    public id: number,
    public code: string,
    public title: string,
    public firstName: string,
    public lastName: string,
    public businessUnitId: number,
    public floor: number,
    public room: number,
    public workplace: number,
    public locker: number,
    public occupancyRatio: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class DirectoryAdapter implements Adapter<Directory> {
  adapt(item: any): Directory {
    return new Directory (
      DIRECTORY.databaseTableName,
      item[DIRECTORY.id],
      item[DIRECTORY.code],
      item[DIRECTORY.title],
      item[DIRECTORY.firstName],
      item[DIRECTORY.lastName],
      item[DIRECTORY.businessUnitId],
      item[DIRECTORY.floor],
      item[DIRECTORY.room],
      item[DIRECTORY.workplace],
      item[DIRECTORY.locker],
      item[DIRECTORY.occupancyRatio],
    );
  }
  encode(item: Directory): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [DIRECTORY.id]: item.id,
      [DIRECTORY.code]: item.code,
      [DIRECTORY.title]: item.title,
      [DIRECTORY.firstName]: item.firstName,
      [DIRECTORY.lastName]: item.lastName,
      [DIRECTORY.businessUnitId]: item.businessUnitId,
      [DIRECTORY.floor]: item.floor,
      [DIRECTORY.room]: item.room,
      [DIRECTORY.workplace]: item.workplace,
      [DIRECTORY.locker]: item.locker,
      [DIRECTORY.occupancyRatio]: item.occupancyRatio,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
