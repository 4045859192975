import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as DisplayThemeItemActions from '../actions/display-theme-item.actions';
import {ThemeItemMockup} from '../../../floor/models/theme-item-mockup.model';
import {DisplayThemeMockup} from '../../../floor/models/display-theme-mockup.model';

export const displayThemeItemFeatureKey = 'displayThemeItems';

export interface State extends EntityState<ThemeItemMockup> {
  themes: DisplayThemeMockup[];
  selectedDisplayThemeId: number;
  hoveredThemeItemId: string;
  legendContext: number;
}

export function generateId(a: ThemeItemMockup): string {
  return a.resId;
  //return a.displayThemeId + '-' + a.resId;
}

export const adapter: EntityAdapter<ThemeItemMockup> = createEntityAdapter<ThemeItemMockup>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  themes: [],
  selectedDisplayThemeId: 1,
  hoveredThemeItemId: null,
  legendContext: null,
});

export const reducer = createReducer(
  initialState,
  on(DisplayThemeItemActions.addDisplayThemeItems, (state, {displayThemeItems}) => {
    return adapter.addMany(displayThemeItems, state);
  }),
  on(DisplayThemeItemActions.updateDisplayThemeItem, (state, {displayThemeItem}) => {
    return adapter.updateOne(displayThemeItem, state);
  }),
  on(DisplayThemeItemActions.addDisplayThemes, (state, {themes}) => {
    return {...state, themes: themes};
  }),
  on(DisplayThemeItemActions.updateDisplayThemeId, (state, {id}) => {
    return {...state, selectedDisplayThemeId: id};
  }),
  on(DisplayThemeItemActions.setDisplayThemeLegendContext, (state, {id}) => {
    return {...state, legendContext: id};
  }),
  on(DisplayThemeItemActions.setHoveredThemeItemId, (state, {id}) => {
    return {...state, hoveredThemeItemId: id};
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectDisplayThemeItemsIds = selectIds;
export const selectDisplayThemeItemsEntities = selectEntities;
export const selectAllDisplayThemeItems = selectAll;
export const selectDisplayThemeItemsTotal = selectTotal;
