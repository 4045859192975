import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {WorkplaceAllocationActions} from '../actions';
import {WorkplaceAllocation} from '../../../domain-models/business/workplace-allocation.model';


export const workplaceAllocationsFeatureKey = 'workplaceAllocations';

export interface State extends EntityState<WorkplaceAllocation> {
  //selectedWorkplaceAllocationId: number | null;
  selectedIds: number[];
}

export function generateId(a: WorkplaceAllocation): number {
  return a.floorDataId;
}

export const adapter: EntityAdapter<WorkplaceAllocation> = createEntityAdapter<WorkplaceAllocation>({
  selectId: generateId
});

export const initialState: State = adapter.getInitialState({
  //selectedWorkplaceAllocationId: null,
  selectedIds: [],
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(WorkplaceAllocationActions.addWorkplaceAllocation, (state, {workplaceAllocation}) => {
    return adapter.addOne(workplaceAllocation, state);
  }),
  on(WorkplaceAllocationActions.setWorkplaceAllocation, (state, {workplaceAllocation}) => {
    return adapter.setOne(workplaceAllocation, state);
  }),
  on(WorkplaceAllocationActions.upsertWorkplaceAllocation, (state, {workplaceAllocation}) => {
    return adapter.upsertOne(workplaceAllocation, state);
  }),
  on(WorkplaceAllocationActions.addWorkplaceAllocations, (state, {workplaceAllocations}) => {
    return adapter.addMany(workplaceAllocations, state);
  }),
  on(WorkplaceAllocationActions.upsertWorkplaceAllocations, (state, {workplaceAllocations}) => {
    return adapter.upsertMany(workplaceAllocations, state);
  }),
  on(WorkplaceAllocationActions.updateWorkplaceAllocation, (state, {update}) => {
    return adapter.updateOne(update, state);
  }),
  on(WorkplaceAllocationActions.updateWorkplaceAllocations, (state, {updates}) => {
    return adapter.updateMany(updates, state);
  }),
  on(WorkplaceAllocationActions.mapWorkplaceAllocations, (state, {entityMap}) => {
    return adapter.map(entityMap, state);
  }),
  on(WorkplaceAllocationActions.deleteWorkplaceAllocation, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(WorkplaceAllocationActions.deleteWorkplaceAllocations, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(WorkplaceAllocationActions.deleteWorkplaceAllocationsByPredicate, (state, {predicate}) => {
    return adapter.removeMany(predicate, state);
  }),
  on(WorkplaceAllocationActions.loadWorkplaceAllocations, (state, {workplaceAllocations}) => {
    return adapter.setAll(workplaceAllocations, state);
  }),
  on(WorkplaceAllocationActions.clearWorkplaceAllocations, state => {
    return adapter.removeAll({...state, selectedIds: []});
  }),
  /** END of CRUD **/
  // on(WorkplaceAllocationActions.updateSelectedWorkplaceAllocationId, (state, {id}) => {
  //   return {...state, selectedWorkplaceAllocationId: id};
  // }),
  on(WorkplaceAllocationActions.clearSelection, (state) => {
    return {...state, selectedIds: state.selectedIds.slice(0, 0)};
  }),
  on(WorkplaceAllocationActions.updateSelection, (state, {addedId, removedId}) => {
    const alreadySelectedId = state.selectedIds.find(e => e === addedId);
    const idToRemoveIndex = state.selectedIds.indexOf(removedId, 0);

    let cases: number;
    if (addedId && !removedId) {
      if (!alreadySelectedId) {
        cases = 1;
      } else {
        cases = 0;
      }
    } else if (!addedId && removedId) {
      if (idToRemoveIndex > -1) {
        cases = 2;
      } else {
        cases = 0;
      }
    } else if (addedId && removedId) {
      if (!alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 3;
        } else {
          cases = 1;
        }
      } else if (alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 2;
        } else {
          cases = 0;
        }
      }
    } else {
      cases = 0;
    }
    switch (cases) {
      case 0:
        return state;
      case 1:
        return {...state, selectedIds: [...state.selectedIds, addedId]};
      case 2:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1)]};
      case 3:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1), addedId ]};
      default: {
        return state;
      }
    }
  }),
);

//export const getSelectedWorkplaceAllocationId = (state: State) => state.selectedWorkplaceAllocationId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectWorkplaceAllocationIds = selectIds;
export const selectWorkplaceAllocationEntities = selectEntities;
export const selectAllWorkplaceAllocations = selectAll;
export const selectWorkplaceAllocationTotal = selectTotal;
