import {createSelector} from '@ngrx/store';
import * as fromDbColumn from '../reducers/db-column.reducer';
import {selectSharedState} from '../reducers';

export const selectDbColumns = createSelector(
  selectSharedState,
  state => state[fromDbColumn.dbColumnFeatureKey]
);

export const selectDbColumnIds = createSelector(
  selectDbColumns,
  fromDbColumn.selectDbColumnIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectDbColumneEntities = createSelector(
  selectDbColumns,
  fromDbColumn.selectDbColumnEntities
);
export const selectAllDbColumns = createSelector(
  selectDbColumns,
  fromDbColumn.selectAllDbColumns
);

export const selectDbColumnTotal = createSelector(
  selectDbColumns,
  fromDbColumn.selectDbColumnTotal
);

export const allDbColumnsLoaded = createSelector(
  selectDbColumns,
  state => state.isLoaded
);

export const selectDbColumnById = (id: string) => createSelector(
  selectDbColumneEntities,
  (entities) => entities[id]
);
