import {createReducer, on} from '@ngrx/store';
import * as RendererActions from '../actions/renderer.actions';
import * as GraphicsFloorActions from '../actions/graphics-floor.actions';
import {Dictionary} from '@ngrx/entity';
import {WallActions} from '../actions';
import {GripAlignmentTypeEnum} from '../../../svg-factory/models/grip-alignment-type.model';
import {RoomContourProps} from '../../../floor/models/room-contour-props.model';

export interface RendererSelection {
  selecting: boolean;
  multiple: boolean;
  floorModelId: number;
}

export const rendererFeatureKey = 'renderer';

export interface State {
  graphicsFloorLoaded: boolean;
  currentTaskIsEmpty: boolean;
  blueprint: boolean;
  lastKnownClickCoordinates: { x: number, y: number };
  viewBoxes: Dictionary<string>; // {taskId: viewBox}/
  pointerEvents: boolean;
  selection: RendererSelection;
  activeFloorModelId: number; // Active FlMo_Id, also applied to descendants
  gripDetectionMode: number;
  withPartitioningFrame: boolean;
  roomContourProps: RoomContourProps;
}

const initialState: State = {
  graphicsFloorLoaded: false,
  currentTaskIsEmpty: false,
  blueprint: false,
  viewBoxes: {},
  lastKnownClickCoordinates: null,
  pointerEvents: true,
  selection: {selecting: false, multiple: false, floorModelId: null},
  activeFloorModelId: null,
  gripDetectionMode: GripAlignmentTypeEnum.EndPoint,
  withPartitioningFrame: null,
  roomContourProps: null,
};

export const reducer = createReducer(
  initialState,
  on(RendererActions.setViewBox, (state, {taskId, viewBox}) => (
    {...state, viewBoxes: {...state.viewBoxes, [taskId]: viewBox}}
  )),
  on(RendererActions.successGetRoomContour,
    (state, {props}) => ({...state, roomContourProps: props})),
  on(RendererActions.lastClickedSVGPoint, (state, point) => ({...state, lastKnownClickCoordinates: point})),
  on(RendererActions.clearRoomContour, (state) => ({...state, roomContourProps: null})),
  // on(RendererActions.setRoomContourProps, (state, {props}) => {
  //   return {...state, roomContourProps: props};
  // }),
  on(RendererActions.setBlueprint, (state, {blueprint}) => ({...state, blueprint: blueprint})),
  on(GraphicsFloorActions.beginGetGraphicsFloorFromRouterState, (state) => ({...state, graphicsFloorLoaded: false})),
  on(GraphicsFloorActions.successGetGraphicsFloorByTaskId, (state) => ({...state, graphicsFloorLoaded: true})),
  on(GraphicsFloorActions.currentTaskIsEmpty, (state, {isEmpty}) => ({...state, currentTaskIsEmpty: isEmpty})),
  on(GraphicsFloorActions.currentGraphicsFloorIsEmpty, (state, {isEmpty}) => ({...state, graphicsFloorLoaded: !isEmpty})),
  on(RendererActions.togglePointerEvents, (state) => ({...state, pointerEvents: !state.pointerEvents})),
  on(RendererActions.clearRenderer, state => {
    return initialState;
  }),
  on(RendererActions.updateActiveFloorModel, (state, {id}) => ({...state, activeFloorModelId: id})),
  on(RendererActions.setSelection, (state, {selection}) => ({...state, selection: selection})),
  on(RendererActions.resetSelection, (state) => ({...state, selection: initialState.selection})),
  on(WallActions.setGripDetectionMode, (state, {mode}) => ({...state, gripDetectionMode: mode})),
  on(WallActions.showPartitioningFrame, (state) => ({...state, withPartitioningFrame: true})),
  on(WallActions.hidePartitioningFrame, (state) => ({...state, withPartitioningFrame: false})),
);
