import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from '../../floor-store/reducers';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {exhaustMap, switchMap} from 'rxjs/operators';
import {TaskTypeActions} from '../actions';
import {of} from 'rxjs';


@Injectable()
export class TaskTypeEffects {

  // updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskActions.updateSelectedTaskId),
  //   )
  // );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }

  beginGetAllTaskTypes = createEffect(() => this.actions$.pipe(
    ofType(TaskTypeActions.beginGetAllTaskTypes),
    exhaustMap((action) =>
      this.projectManagementService.getAllTaskTypes().pipe(
        switchMap(taskTypes =>
          of(TaskTypeActions.addTaskTypes({taskTypes: taskTypes }))
        ),
    )
  )));
}
