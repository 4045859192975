import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {AuthGuard} from './auth.guard';
import {LayoutComponent} from './layout/layout.component';
import {RoleGuard} from './role.guard';
import {ClientsComponent} from './clients/clients.component';
import {NgrxRouterStoreModule} from '../store/router-store/ngrx-router.module';
import {TicketGuard} from './ticket.guard';
import {CoreStatesGuard} from './core-states.guard';
import {ProjectManagementStateGuard} from '../project-management/project-management-state.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'clients',
        component: ClientsComponent
      },
      {
        path: '',
        canActivate: [RoleGuard, CoreStatesGuard],
        canActivateChild: [RoleGuard], // TicketGuard
        children: [
          {
            path: '',
            //canActivateChild: [RoleGuard, TicketGuard, CoreStatesGuard],
            loadChildren: () => import('../pages/pages.module').then(m => m.PagesModule),
          },
          {
            path: 'user',
            loadChildren: () => import('../user/user.module').then(m => m.UserModule)
          },
          {
            path: 'simulations',
            loadChildren: () => import('../simulation/simulation.module').then(m => m.SimulationModule)
          },
          {
            path: 'floor',
            //path: 'floor/:floorId',
            //canActivate: [FloorStateGuard],
            loadChildren: () => import('../floor/floor.module').then(m => m.FloorModule),
          },
          {
            path: 'building',
            //canActivate: [BuildingStateGuard],
            loadChildren: () => import('../building/building.module').then(m => m.BuildingModule)
          },
          {
            path: 'project-management',
            canActivate: [ProjectManagementStateGuard],
            loadChildren: () => import('../project-management/project-management.module').then(m => m.ProjectManagementModule)
          },
          {
            path: 'data-tables',
            loadChildren: () => import('../data-tables/data-tables.module').then(m => m.DataTablesModule)
          },
          {
            path: 'upload',
            loadChildren: () => import('../upload/upload.module').then(m => m.UploadModule)
          },
        ]
      },
      {
        path: '**', component: NotFoundComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, enableTracing: false, relativeLinkResolution: 'legacy' }), NgrxRouterStoreModule],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
