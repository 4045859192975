import {createAction, props} from '@ngrx/store';
import {RoomLayoutType} from '../../../domain-models/business/room-layout-type.model';

export const beginGetAllRoomLayoutTypes = createAction('[Shared/API] - Begin Get All RoomLayoutTypes');
export const beginGetAllRoomLayoutTypesLastItems = createAction('[Shared/API] - Begin Get All RoomLayoutTypes Last Items');

export const successGetAllRoomLayoutTypes = createAction(
  '[API] - Success Get All RoomLayoutTypes',
  props<{ roomLayoutTypes: RoomLayoutType[] }>()
);

export const errorGetAllRoomLayoutTypes = createAction('[Shared/API] - Error Get All RoomLayoutTypes', props<Error>());

export const addRoomLayoutType = createAction('[Shared/API] Add RoomLayoutType', props<RoomLayoutType>());
