import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const TASK_LINK = {
  databaseTableName: 'task_link',
  id: 'TaLi_Id',
  sourceTaskId: 'TaLi_SourceTaskId',
  targetTaskId: 'TaLi_TargetTaskId',
  linkType: 'TaLi_LinkType'
};

export class TaskLink {
  constructor(
    public databaseTableName: string,
    public id: number,
    public sourceTaskId: number,
    public targetTaskId: number,
    public linkType: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskLinkAdapter implements Adapter<TaskLink> {
  adapt(item: any): TaskLink {
    return new TaskLink(
      TASK_LINK.databaseTableName,
      item[TASK_LINK.id],
      item[TASK_LINK.sourceTaskId],
      item[TASK_LINK.targetTaskId],
      item[TASK_LINK.linkType],
    );
  }

  encode(item: TaskLink): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [TASK_LINK.id]: item.id,
      [TASK_LINK.sourceTaskId]: item.sourceTaskId,
      [TASK_LINK.targetTaskId]: item.targetTaskId,
      [TASK_LINK.linkType]: item.linkType + 1, // add 1, DHTMLX enum start at 0 DB model starts at 1
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
