import {createSelector} from '@ngrx/store';
import * as fromRoomAttributionType from '../reducers/room-attribution-type.reducer';
import {selectSharedState} from '../reducers';

export const selectRoomAttributionTypes = createSelector(
  selectSharedState,
  state => state[fromRoomAttributionType.roomAttributionTypesFeatureKey]
);

export const selectRoomAttributionTypeIds = createSelector(
  selectRoomAttributionTypes,
  fromRoomAttributionType.selectRoomAttributionTypeIds // shorthand for usersState => fromUser.selectUserIds(usersState)
);
export const selectRoomAttributionTypeeEntities = createSelector(
  selectRoomAttributionTypes,
  fromRoomAttributionType.selectRoomAttributionTypeEntities
);
export const selectAllRoomAttributionTypes = createSelector(
  selectRoomAttributionTypes,
  fromRoomAttributionType.selectAllRoomAttributionTypes
);
export const selectRoomAttributionTypeTotal = createSelector(
  selectRoomAttributionTypes,
  fromRoomAttributionType.selectRoomAttributionTypeTotal
);

export const selectRoomAttributionTypeById = (id: number) => createSelector(
  selectRoomAttributionTypeeEntities,
  (entities) => entities[id]
);

export const selectRoomAttributionTypeByIds = (ids: number[]) => createSelector(
  selectRoomAttributionTypeeEntities,
    (entities) => ids.map(id => entities[id])
);
