import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import {
  TaskBusinessTypeSelectors,
  TaskContributorDirectorySelectors,
  TaskFloorSelectors,
  TaskFunctionSelectors,
  TaskSelectors,
  TaskStatusSelectors,
  TaskTypeSelectors
} from '../store/project-management/selectors';
import {
  ProjectManagementActions,
  TaskBusinessTypeActions,
  TaskContributorDirectoryActions,
  TaskFunctionActions,
  TaskStatusActions,
  TaskTypeActions
} from '../store/project-management/actions';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementStateGuard implements CanActivate {
  constructor(private store: Store<State>) {
  }

  /**
   * TODO optimize action dispatch (multiple requests are fired since all totals are not > 0 at the same time)
   */

  // wrapping the logic so we can .switchMap() it
  getFromStore(): Observable<any> {
    this.store.dispatch(ProjectManagementActions.beginLoadProjectManagement());
    this.store.dispatch(TaskBusinessTypeActions.beginGetAllTaskBusinessTypes());
    this.store.dispatch(TaskTypeActions.beginGetAllTaskTypes());
    this.store.dispatch(TaskStatusActions.beginGetAllTaskStatusItems());
    this.store.dispatch(TaskFunctionActions.beginGetAllTaskFunctions());
    this.store.dispatch(TaskContributorDirectoryActions.beginGetAllTaskContributorDirectoryItems());

    // return an Observable stream from the store
    return combineLatest([
      this.store.select(TaskSelectors.selectTaskTotal),
      this.store.select(TaskBusinessTypeSelectors.selectTaskBusinessTypeTotal),
      this.store.select(TaskFloorSelectors.selectTaskFloorTotal),
      this.store.select(TaskStatusSelectors.selectTaskStatusTotal),
      this.store.select(TaskTypeSelectors.selectTaskTypeTotal),
      this.store.select(TaskContributorDirectorySelectors.selectTaskContributorDirectoryTotal),
      this.store.select(TaskFunctionSelectors.selectTaskFunctionTotal),

    ]).pipe(
      //tap(a => console.log(a)),
      // the tap() operator allows for a side effect, at this
      // point, we're checking if items total are different of zero
      // a: FloorTotal<number>
      tap(([taskTotal,
             taskBusinessTypeTotal,
             taskFloorTotal,
             taskStatusTotal,
             taskTypeTotal,
             taskContributorDirectoryTotal,
             taskFunctionTotal,
             /*taskFloorTotal*/]) => {
        // if there are no items (0 items), dispatch an action to hit the backend
        // if (taskTotal === 0) {
        //   this.store.dispatch(ProjectManagementActions.beginLoadProjectManagement());
        // }
        // if (taskBusinessTypeTotal === 0) {
        //   this.store.dispatch(TaskBusinessTypeActions.beginGetAllTaskBusinessTypes());
        // }
        // if (taskTypeTotal === 0) {
        //   this.store.dispatch(TaskTypeActions.beginGetAllTaskTypes());
        // }
        // if (taskStatusTotal === 0) {
        //   this.store.dispatch(TaskStatusActions.beginGetAllTaskStatusItems());
        // }
        // if (taskFunctionTotal === 0) {
        //   this.store.dispatch(TaskFunctionActions.beginGetAllTaskFunctions());
        // }
        // if (taskContributorDirectoryTotal === 0) {
        //   this.store.dispatch(TaskContributorDirectoryActions.beginGetAllTaskContributorDirectoryItems());
        // }
      }),
      //tap(r => console.log(r)),
      // filter out zero values, no value === empty!
      filter(([taskTotal,
                taskBusinessTypeTotal,
                taskFloorTotal,
                taskStatusTotal,
                taskTypeTotal,
                taskContributorDirectoryTotal,
                taskFunctionTotal]) =>
        taskContributorDirectoryTotal !== 0
        && taskTypeTotal !== 0
        && taskStatusTotal !== 0
        && taskFunctionTotal !== 0
        && taskTotal !== 0
      ),
      // which if empty, we will never take()
      // this is the same as first() which will only
      // take 1 value from the Observable then complete
      // which does our unsubscribing, technically.
      take(1),
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    // return our Observable stream from above
    return this.getFromStore().pipe(
      //tap(r => console.log(r)),
      // if it was successful, we can return Observable.of(true)
      switchMap(() => of(true)),
      // otherwise, something went wrong
      catchError(() => of(false))
    );
  }

}
