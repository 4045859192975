import {createAction, props} from '@ngrx/store';
import {EntityMap, Predicate, Update} from '@ngrx/entity';
import {TaskType} from '../../../domain-models/business/task-type.model';

/**
 * CRUD Actions
 */

export const loadTaskTypes = createAction('[TaskType/API] Load Users', props<{ taskTypes: TaskType[] }>());
export const addTaskType = createAction('[TaskType/API] Add TaskType', props<{ taskType: TaskType }>());
export const setTaskType = createAction('[TaskType/API] Set TaskType', props<{ taskType: TaskType }>());
export const upsertTaskType = createAction('[TaskType/API] Upsert TaskType', props<{ taskType: TaskType }>());
export const addTaskTypes = createAction('[TaskType/API] Add TaskTypes', props<{ taskTypes: TaskType[] }>());
export const upsertTaskTypes = createAction('[TaskType/API] Upsert TaskTypes', props<{ taskTypes: TaskType[] }>());
export const updateTaskType = createAction('[TaskType/API] Update TaskType', props<{ update: Update<TaskType> }>());
export const updateTaskTypes = createAction('[TaskType/API] Update TaskTypes', props<{ updates: Update<TaskType>[] }>());
export const mapTaskTypes = createAction('[TaskType/API] Map TaskTypes', props<{ entityMap: EntityMap<TaskType> }>());
export const deleteTaskType = createAction('[TaskType/API] Delete TaskType', props<{ id: string }>());
export const deleteTaskTypes = createAction('[TaskType/API] Delete TaskTypes', props<{ ids: string[] }>());
export const deleteTaskTypesByPredicate = createAction('[TaskType/API] Delete TaskTypes By Predicate', props<{ predicate: Predicate<TaskType> }>());
export const clearTaskTypes = createAction('[TaskType/API] Clear TaskTypes');

/** END of CRUD **/

export const beginGetAllTaskTypes = createAction('[TaskType/API] - Begin Get All TaskTypes');
export const errorGetAllTaskTypes = createAction('[API] - Error Get All TaskTypes', props<Error>());

export const updateSelectedTaskTypeId = createAction(
  '[TaskStatus/API] - Save Selected TaskType Id',
  props<{ taskTypeId: number }>()
);
