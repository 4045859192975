import {createSelector} from '@ngrx/store';
import * as fromFloorModels from '../reducers/task-floor-models.reducer';
import {selectSvgState} from '../reducers';
import {selectDisplayedTasksIds} from '../../floor-store/selectors/ui-context.selectors';
import {selectRootState} from '../../../reducers';
import {selectActiveFloorModelId} from './renderer.selectors';

export const selectFloorModels = createSelector(
  selectSvgState,
  state => state[fromFloorModels.taskFloorModelsFeatureKey]
);

export const selectFloorModelsIds = createSelector(
  selectFloorModels,
  fromFloorModels.selectFloorModelsIds
);
export const selectFloorModelsEntities = createSelector(
  selectFloorModels,
  fromFloorModels.selectFloorModelsEntities
);
export const selectAllFloorModels = createSelector(
  selectFloorModels,
  fromFloorModels.selectAllFloorModels
);

export const selectAllFloorModelsOrdered = createSelector(
  selectRootState,
  selectAllFloorModels,
  selectDisplayedTasksIds,
  (state, floorModels, displayedTaskIds) => {
    /** https://stackoverflow.com/questions/13304543/javascript-sort-array-based-on-another-array **/
    return floorModels.sort((a, b) => {
      return displayedTaskIds.indexOf(a.taskId) - displayedTaskIds.indexOf(b.taskId);
    });
  }
);

export const selectFloorModelsTotal = createSelector(
  selectFloorModels,
  fromFloorModels.selectFloorModelsTotal
);

export const selectFloorModelById = (id: string) => createSelector(
  selectFloorModelsEntities,
  (floorModelsEntities => floorModelsEntities[id])
);

export const selectActiveFloorModelsIds = createSelector(
  selectAllFloorModels,
  floorModels => floorModels.filter(f => f.isActive).map(f => f.id + '-' + f.taskId)
);

export const selectFloorModelsByTaskId = (taskId: number) => createSelector(
  selectAllFloorModels,
  floorModelItems => floorModelItems.filter(item => item.taskId === taskId)
);

export const selectActiveFloorModel = createSelector(
  selectFloorModels,
  selectActiveFloorModelId,
  (floorModels, activeFloorModelId) => floorModels.find(f => f.id === activeFloorModelId)
);
