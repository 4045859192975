import {Injectable} from '@angular/core';
import {Adapter} from '../adapter';
import {Floor, FloorAdapter} from '../business/floor.model';
import {RoomSharing, RoomSharingAdapter} from '../business/room-sharing.model';
import {Room, RoomAdapter} from '../business/room.model';
import {SvgUse} from '../../svg-factory/models/svg-use.model';
import {SvgGroup} from '../../svg-factory/models/svg-group.model';
import {FloorModel, FloorModelAdapter} from '../business/floor-model.model';

interface SvgDTO {
  SvgUseDTO: SvgUse[];
  SvgGroupDTO: SvgGroup[];
}

const GRAPHICS_FLOOR = {
  floor: 'floor',
  blueprintExtentsLayerId: 'BlueprintExtentsLayerId',
  thumbnailUrl: 'ThumbnailUrl',
  roomSharings: 'RoomSharingView',
  rooms: 'RoomView',
  floorModel: 'FloorModel',
  svgDTO: 'SvgDTO',
  floorCatalog: 'FloorCatalog',
};

export class ApiGraphicsFloor {
  constructor(
    public floor: Floor,
    public blueprintExtentsLayerId: number,
    public thumbnailUrl: string,
    public roomSharings: RoomSharing[],
    public rooms: Room[],
    public floorModel: FloorModel[],
    public svgDTO: SvgDTO,
    public floorCatalog: SvgGroup[],
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiGraphicsFloorAdapter implements Adapter<ApiGraphicsFloor> {
  adapt(item: any): ApiGraphicsFloor {
    const floor = new FloorAdapter().adapt(item[GRAPHICS_FLOOR.floor]);


    // const roomAllocation = [];
    // item[GRAPHICS_FLOOR.roomAllocations].forEach(
    //   e => roomAllocation.push(new RoomAllocationAdapter().adapt(e))
    // );
    //
    const roomSharing = [];
    item[GRAPHICS_FLOOR.roomSharings].forEach(
      e => roomSharing.push(new RoomSharingAdapter().adapt(e))
    );

    const room = [];
    item[GRAPHICS_FLOOR.rooms].forEach(
      e => room.push(new RoomAdapter().adapt(e))
    );

    const floorCatalog = [];
    item[GRAPHICS_FLOOR.floorCatalog].forEach(
      e => floorCatalog.push(e as SvgGroup)
    );

    const floorModel = [];
    item[GRAPHICS_FLOOR.floorModel].forEach(
      e => floorModel.push(new FloorModelAdapter().adapt(e))
    );

    const svgDTO = item[GRAPHICS_FLOOR.svgDTO] as SvgDTO;

    return new ApiGraphicsFloor(
      floor,
      item[GRAPHICS_FLOOR.blueprintExtentsLayerId],
      item[GRAPHICS_FLOOR.thumbnailUrl],
      roomSharing,
      room,
      floorModel,
      svgDTO,
      floorCatalog,
    );
  }
}
