import {createSelector} from '@ngrx/store';
import * as fromGanttTask from '../reducers/gantt-task.reducer';
import {selectProjectManagementState} from '../reducers';

/** Select store slice **/

export const selectGanttTasks = createSelector(
  selectProjectManagementState,
  state => state[fromGanttTask.ganttTaskFeatureKey]
);

/** Basics selectors **/

export const selectGanttTaskIds = createSelector(
  selectGanttTasks,
  fromGanttTask.selectGanttTaskIds
);
export const selectGanttTaskEntities = createSelector(
  selectGanttTasks,
  fromGanttTask.selectGanttTaskEntities
);
export const selectAllGanttTasks = createSelector(
  selectGanttTasks,
  fromGanttTask.selectAllGanttTasks
);
export const selectGanttTaskTotal = createSelector(
  selectGanttTasks,
  fromGanttTask.selectGanttTaskTotal
);
export const selectCurrentGanttTaskId = createSelector(
  selectGanttTasks,
  fromGanttTask.getSelectedGanttTaskId
);

export const selectCurrentGanttTask = createSelector(
  selectGanttTaskEntities,
  selectCurrentGanttTaskId,
  (ganttTaskEntities, ganttTaskId) => ganttTaskEntities[ganttTaskId]
);

/** Advanced selectors **/

export const selectGanttTaskById = (id: number) => createSelector(
  selectGanttTaskEntities,
  (entities) => entities[id]
);
