import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, distinct, filter, map, switchMap, withLatestFrom,} from 'rxjs/operators';
import {
  FloorCatalogActions,
  FloorDataActions,
  GraphicsBuildingActions,
  RendererActions,
  TaskFloorModelActions,
  UsesActions,
} from '../actions';
import {FloorActions, RoomActions, RoomAllocationActions,} from '../../floor-store/actions';
import {FloorService} from '../../../floor/services/floor.service';
import {State} from '../../../reducers';
import {Store} from '@ngrx/store';
import {BuildingSelectors} from '../../building-store/selectors';
import {Building} from '../../../domain-models/business/building.model';
import {BuildingService} from '../../../building/services/building.service';
import {BuildingActions} from '../../building-store/actions';
import {FloorModelEnum} from '../../../domain-models/business/floor-model.model';
import {DisplayThemeItemActions} from '../../shared-store/actions';
import {LegendContextEnum} from '../../../domain-models/models/legend-context.enum';
import {EMPTY} from 'rxjs';

@Injectable()
export class GraphicsBuildingEffects {
  getGraphicsBuildingFromRouterState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphicsBuildingActions.beginGetGraphicsBuildingFromRouterState),
      withLatestFrom(this.store.select((state) => state.router)),
      switchMap(([action, route]) => {
        /** Cast route.state.params String to Number */
        const buildingId = Number(route.state.params['buildingId']);
        this.store.dispatch(GraphicsBuildingActions.clearSvgState());
        this.store.dispatch(
          BuildingActions.updateSelectedBuildingId({ buildingId: buildingId })
        );
        return this.store.select(BuildingSelectors.selectCurrentBuilding).pipe(
          /** Wether BuildingStore is loaded or not and currentBuilding selected */
          filter((x) => x !== undefined),
          /** Ensure action isn't triggered twice when floor changes (FLOOR RELATED ACTIONS <Update>) **/
          distinct((b: Building) => b.id),
          switchMap((building) => [
            DisplayThemeItemActions.setDisplayThemeLegendContext({
              id: LegendContextEnum.Building,
            }),
            RoomAllocationActions.beginGetRoomAllocationsByBuildingId({
              buildingId: building.id,
            }),
            /** Set Renderer pointerEvents to false to disable SvgRenderer mouse events **/
            RendererActions.togglePointerEvents(),
            GraphicsBuildingActions.beginGetGraphicsBuildingById({
              id: building.id,
            }),
          ])
        );
      })
    )
  );

  getGraphicsBuildingId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GraphicsBuildingActions.beginGetGraphicsBuildingById),
        concatMap((action) =>
          this.buildingService.getBuildingFloors(action.id).pipe(
            map((data) => {
              data.floors.forEach((f) => {
                const floorBlueprintExtents = data.floorDataItems.find(
                  (g) =>
                    g.floorModelId === FloorModelEnum.BlueprintExtents &&
                    g.taskId === f.taskId
                )?.boundingBoxString;
                if (floorBlueprintExtents !== undefined /* && !viewBox*/) {
                  this.store.dispatch(
                    RendererActions.createViewboxFromApi({
                      taskId: f.taskId,
                      viewBox: floorBlueprintExtents,
                    })
                  );
                }
              });
              return data;
            })
          )
        ),
        concatMap((data) => [
          FloorActions.addFloors({ floors: data.floors }),
          TaskFloorModelActions.addTaskFloorModels({
            taskFloorModels: data.floorModels,
          }),
          FloorDataActions.addFloorDataItems({
            svgGroups: data.floorDataItems,
          }),
          GraphicsBuildingActions.successGetGraphicsBuildingById(),
        ])
      )
    /** Disable catchError beacause if not, effect won't trigger twice **/
    // catchError(err => of(GraphicsFloorActions.errorGetGraphicsFloorByTaskId(err)))
  );

  // getGraphicsBuildingFloorByTaskId$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(GraphicsBuildingActions.beginGetGraphicsBuildingFloorByTaskId),
  //     //withLatestFrom(this.store.select(selectViewbox)),
  //     switchMap((action) => this.floorService.getTaskFloorData(
  //       action.id,
  //       [FloorModelEnum.Rooms]
  //     )),
  //     /** Filter data in case of empty floor, array are empty [] therefore there is no task id available in dataset **/
  //     filter(data => data.floorModel.length > 0),
  //     switchMap((data) => [
  //       TaskFloorModelActions.addTaskFloorModels({taskFloorModels: data.floorModel}),
  //       FloorCatalogActions.addFloorCatalogItems({floorCatalog: data.floorCatalog}),
  //       FloorDataActions.addFloorDataItems({svgGroups: data.svgDTO.SvgGroupDTO}),
  //       UsesActions.addUses({svgUses: data.svgDTO.SvgUseDTO}),
  //       GraphicsBuildingActions.successGetGraphicsBuildingFloorByTaskId({taskId: data.floorModel[0].taskId})
  //     ]),
  //   ),
  // );

  getGraphicsBuildingFloorByTaskId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphicsBuildingActions.beginGetGraphicsBuildingFloorByTaskId),
      //withLatestFrom(this.store.select(selectViewbox)),
      concatMap((action) =>
        this.floorService.getTaskFloorData(action.id, [FloorModelEnum.Rooms])
      ),
      /** Filter data in case of empty floor, array are empty [] therefore there is no task id available in dataset **/
      //filter(data => data.floorModel.length > 0),
      concatMap((data) => {
        if (data.floorModel.length > 0) {
          return [
            TaskFloorModelActions.addTaskFloorModels({
              taskFloorModels: data.floorModel,
            }),
            FloorCatalogActions.addFloorCatalogItems({
              svgGroups: data.floorCatalog,
            }),
            FloorDataActions.addFloorDataItems({
              svgGroups: data.svgDTO.SvgGroupDTO,
            }),
            UsesActions.addUses({ svgUses: data.svgDTO.SvgUseDTO }),
            GraphicsBuildingActions.successGetGraphicsBuildingFloorByTaskId({
              taskId: data.floorModel[0].taskId,
            }),
          ];
        } else {
          return EMPTY;
        }
      })
    )
  );

  successGetGraphicsBuildingById$ = createEffect(() =>
    this.actions$.pipe(
      /** Refresh data on successGetGraphicsBuildingById **/
      ofType(GraphicsBuildingActions.successGetGraphicsBuildingById),
      switchMap((action) => [
        BuildingActions.beginGetBuildingAreaIds(),
      ])
    )
  );

  successGetGraphicsBuildingFloorByTaskId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphicsBuildingActions.successGetGraphicsBuildingFloorByTaskId),
      filter(a => a.taskId !== undefined),
      concatMap((action) => {
        return [
          RoomActions.beginGetFloorTaskRoomsByTaskId({ taskId: action.taskId }),
          RoomAllocationActions.beginGetTaskRoomAllocationsByTaskId({
            taskId: action.taskId,
          }),
          DisplayThemeItemActions.buildDisplayThemes(),
        ];
      })
    )
  );

  clearSvgState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GraphicsBuildingActions.clearSvgState),
      switchMap((data) => [
        FloorCatalogActions.clearFloorCatalog(),
        FloorDataActions.clearFloorDataItems(),
        TaskFloorModelActions.clearTaskFloorModels(),
        RendererActions.clearRenderer(),
        UsesActions.clearUses(),
      ])
    )
  );

  constructor(
    private actions$: Actions,
    private buildingService: BuildingService,
    private floorService: FloorService,
    private store: Store<State>
  ) {}
}
