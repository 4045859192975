import {Dictionary} from '../core/models/dictionary.model';

export function normalizeArray<T>(innerData: T[]): Dictionary<T> {
  // type InnerType = T & { id: number | string };
  return innerData.reduce((acc: Dictionary<any>, curr: any) => {
    return {
      ...acc,
      [curr.id]: curr
    };
  }, {});
}

export function uniqueArray<T>(array: Array<T>): Array<T> {
  return array.filter((elem, pos, arr) => {
    return arr.indexOf(elem) === pos;
  });
}

/**https://stackoverflow.com/questions/2218999/remove-duplicates-from-an-array-of-objects-in-javascript**/
export function removeDuplicates<T>(array: Array<T>): Array<T> {
  // slow for large arrays
  return array.filter((v, i, a) => a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i);
}

/** https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another/6470794#6470794 **/
export function arrayMove(arr: Array<any>, fromIndex: number, toIndex: number) {
  const element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
}
/**
 * Sort array of objects based on another array
 * https://gist.github.com/ecarter/1423674
 */

export function mapOrder(array: Array<any>, order: Array<string | number>, key: string) {

  array.sort( function (a, b) {
    const A = a[key], B = b[key];

    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }

  });

  return array;
}

// // source: https://github.com/jashkenas/underscore/blob/master/underscore.js#L1320
// function isObject(obj) {
//   const type = typeof obj;
//   return type === 'function' || type === 'object' && !!obj;
// }
// export function deepCopy(src) {
//   const target = {};
//   for (const prop in src) {
//     if (src.hasOwnProperty(prop)) {
//       // if the value is a nested object, recursively copy all it's properties
//       if (isObject(src[prop])) {
//         target[prop] = deepCopy(src[prop]);
//       } else {
//         target[prop] = src[prop];
//       }
//     }
//   }
//   return target;
// }

/**
 * JavaScript function that will make a deep copy of nested objects or arrays.
 * https://medium.com/javascript-in-plain-english/how-to-deep-copy-objects-and-arrays-in-javascript-7c911359b089
 * @param inObject
 */
export function deepCopy (inObject) {
  let outObject, value, key;

  // TODO check if isDate "checking" is correct and doesn't do shallow copy of date object instead of deep copy...

  if (typeof inObject !== 'object' || inObject === null || isDate(inObject)) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value);
  }

  return outObject;
}

// /**
//  * Deep copy function for TypeScript.
//  * @param T Generic type of target/copied value.
//  * @param target Target value to be copied.
//  * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
//  * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
//  */
// export const deepCopy = <T>(target: T): T => {
//   if (target === null) {
//     return target;
//   }
//   if (target instanceof Date) {
//     return new Date(target.getTime()) as any;
//   }
//   if (target instanceof Array) {
//     const cp = [] as any[];
//     (target as any[]).forEach((v) => { cp.push(v); });
//     return cp.map((n: any) => deepCopy<any>(n)) as any;
//   }
//   if (typeof target === 'object' && target !== {}) {
//     const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
//     Object.keys(cp).forEach(k => {
//       cp[k] = deepCopy<any>(cp[k]);
//     });
//     return cp as T;
//   }
//   return target;
// };

/**
 * Function to check whether an 'input' is a date object or not.
 * https://www.w3resource.com/javascript-exercises/javascript-date-exercise-1.php
 * @param input
 */

export function isDate(input) {
  return Object.prototype.toString.call(input) === '[object Date]';
}

/** Returns true if the all records of a collection are equal and false otherwise
 * https://dev.to/rajnishkatharotiya/function-to-check-if-all-records-are-equal-in-array-javascript-3mo3
 * @param arr
 */
export const allEqual = arr => arr.every(val => val === arr[0]);
