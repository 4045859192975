<mat-toolbar>
  <span>Mes clients</span>
</mat-toolbar>
<!--<mat-card>
  <mat-list>
    <h3 mat-subheader>Clients</h3>
    <mat-list-item (click)="select(client)" *ngFor="let client of clientFiles">
      <img matListAvatar class="client-logo" [src]="'https://static.echo-on.fr/' + client.companyId + '/logo.jpg'"
           [alt]="client.companyName + ' logo'">
      <mat-icon mat-list-icon>folder</mat-icon>
      <h4 mat-line>{{client.companyName}}</h4>
      <p mat-line> {{client.lastAccess | date}} </p>
    </mat-list-item>
  </mat-list>
</mat-card>-->

<!--<mat-grid-list *ngIf="clientFiles" [cols]="6" rowHeight="250px" gutterSize="12px">
  <mat-grid-tile (click)="select(client)" *ngFor="let client of clientFiles">
    <mat-card class="client">
      <mat-card-content>
        <img class="client-logo" [src]="'https://static.echo-on.fr/' + client.companyId + '/logo.jpg'"
             [alt]="client.companyName + ' logo'">
        <h2>{{client.companyName}}</h2>
      </mat-card-content>
    </mat-card>

  </mat-grid-tile>
</mat-grid-list>-->
<div fxLayout="row" class="client-list">
  <mat-card *ngFor="let client of (clients$ | async)" class="client" (click)="select(client)" fxLayout="column">
    <mat-card-header>
      <!--<div mat-card-avatar class="example-header-image"></div>-->
      <mat-card-title>{{client.CompanyName}}</mat-card-title>
      <mat-card-subtitle>Consulting</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <img mat-card-image class="client-logo" [src]="'https://static.echo-on.fr/' + client.CompanyId + '/logo.jpg'"
           [alt]="client.CompanyName + ' logo'">
    </mat-card-content>
    <!--<mat-card-header>
      <img class="client-logo" [src]="'https://static.echo-on.fr/' + client.companyId + '/logo.jpg'"
           [alt]="client.companyName + ' logo'">
      <mat-card-title>{{client.companyName}}</mat-card-title>
    </mat-card-header>-->
    <!--<mat-card-content>
      <h3>{{client.companyName}}</h3>
      <p><mat-icon>access_time</mat-icon>-</p>
    </mat-card-content>-->
  </mat-card>
</div>
