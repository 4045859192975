import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {FloorService} from '../../../floor/services/floor.service';
import {Store} from '@ngrx/store';
import {TaskSelectors} from '../../project-management/selectors';
import * as WorkplaceActions from '../actions/workplace.actions';
import {State} from '../../../reducers';
import {Update} from '@ngrx/entity';
import {SvgGroup} from '../../../svg-factory/models/svg-group.model';
import * as FloorDataActions from '../../svg-store/actions/floor-data.actions';
import {EMPTY} from 'rxjs';

@Injectable()
export class WorkplaceEffects {

  getCurrentFloorWorkplaces$ = createEffect(() => this.actions$.pipe(
    ofType(WorkplaceActions.beginGetCurrentFloorWorkplaces),
    switchMap((action) =>
      this.store.select(TaskSelectors.selectCurrentTaskId).pipe(
        switchMap(taskId => {
          if (taskId) {
            return this.floorService.getWorkplacesByTaskId(taskId).pipe(
              map((workplaces) => WorkplaceActions.addWorkplaces({workplaces: workplaces}))
            );
          } else {
            return EMPTY;
          }
        })
      )
    ))
  );

  deleteWorkplace$ = createEffect(() => this.actions$.pipe(
    ofType(WorkplaceActions.deleteWorkplaceFromFloorMap),
    switchMap(action => this.floorService.deleteWorkplace(action.id).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        return [
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates})
        ];
      })
    )),
  ));

  deleteWorkplaces$ = createEffect(() => this.actions$.pipe(
    ofType(WorkplaceActions.deleteWorkplacesFromFloorMap),
    switchMap(action => this.floorService.deleteDoors(action.ids).pipe(
      concatMap(data => {
        const updates = [] as Update<SvgGroup>[];
        const removeFloorDataIds = [] as number[];
        data.forEach(item => {
          if (item.dataStateId) {
            updates.push({
              id: item.floorDataId,
              changes: {
                dataStateId: item.dataStateId
              }
            });
          } else {
            removeFloorDataIds.push(item.floorDataId);
          }
        });
        //const arr = [...removeFloorDataIds.map(id => FloorDataActions.removeFromSelection({id: id}))];
        return [
          //...arr,
          FloorDataActions.removeFloorDataItems({ids: removeFloorDataIds}),
          FloorDataActions.updateFloorDataItems({svgGroups: updates})
        ];
      })
    )),
  ));

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store: Store<State>
  ) {
  }
}
