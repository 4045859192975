import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {CoreRoutingModule} from './core-routing.module';
import {RouterModule} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {HeaderComponent} from './header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {LayoutComponent} from './layout/layout.component';
import {authModuleConfig} from './core-module.config';
import {UserModule} from '../user/user.module';
import {SharedModule} from '../shared/shared.module';
import {EchoCompanyInterceptor} from './http-interceptors/echo-company.interceptor';
import {ClientsComponent} from './clients/clients.component';
import {MobileMenuComponent} from './mobile-menu/mobile-menu.component';
import {FloorStoreModule} from '../store/floor-store/floor-store.module';
import {SvgStoreModule} from '../store/svg-store/svg-store.module';
import {SharedStoreModule} from '../store/shared-store/shared-store.module';
import {authConfig} from './auth.config';
import {ProjectManagementStoreModule} from '../store/project-management/project-management-store.module';
import {BuildingStoreModule} from '../store/building-store/building-store.module';
import {CoreStoreModule} from '../store/core-store/core-store.module';
import {LoaderInterceptor} from './http-interceptors/loader.interceptor';
import {LoadingProgressBarComponent} from './loading-progress-bar/loading-progress-bar.component';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     'swipe': {direction: Hammer.DIRECTION_ALL}, // override default settings
//     'pan': {direction: Hammer.DIRECTION_ALL}
//   };
// }


@NgModule({
  declarations: [
    NotFoundComponent,
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,
    ClientsComponent,
    MobileMenuComponent,
    LoadingProgressBarComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule, // Only import once otherwise HttpInterceptors won't work
    //HammerModule,
    CoreRoutingModule,
    OAuthModule.forRoot(),
    CoreStoreModule,
    BuildingStoreModule,
    FloorStoreModule,
    SvgStoreModule,
    SharedStoreModule,
    ProjectManagementStoreModule,
    //FlexLayoutModule,
    //FormsModule,
    //ReactiveFormsModule,
    //MaterialModule,
    SharedModule,
    UserModule,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: EchoCompanyInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    //{provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    // AuthService,
    // AuthGuard,
    // RoleGuard,
    // UserService,
    // LayoutService,

  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {provide: AuthConfig, useValue: authConfig},
        {provide: OAuthModuleConfig, useValue: authModuleConfig},
        {provide: OAuthStorage, useFactory: storageFactory},
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
