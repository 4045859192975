import {Injectable} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from '../../core/services/logging.service';
import {API_ENDPOINTS} from '../api-endpoints';
import {map} from 'rxjs/operators';
import {DisplayTheme, DisplayThemeAdapter} from '../../domain-models/business/display-theme.model';
import {ApiData} from '../../core/models/api-data.model';
import {Observable} from 'rxjs';
import {DisplayThemeItem, DisplayThemeItemAdapter} from '../../domain-models/business/display-theme-item.model';
import {DisplayThemable, DisplayThemableAdapter} from '../../domain-models/business/display-themable.model';

@Injectable({
  providedIn: 'root'
})
export class DisplayThemeService extends ApiService {

  constructor(http: HttpClient,
              loggingService: LoggingService,
              private displayThemeItemAdapter: DisplayThemeItemAdapter,
              private displayThemableAdapter: DisplayThemableAdapter,
              private displayThemeAdapter: DisplayThemeAdapter) {
    super(http, loggingService);
  }

  getDisplayTheme(): Observable<DisplayTheme[]> {
    return this.get(API_ENDPOINTS.dynT + 'DisplayTheme').pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemeAdapter.adapt(item));
      })
    );
  }

  getDisplayThemables(): Observable<DisplayThemable[]> {
    return this.get(API_ENDPOINTS.dynT + 'DisplayThemable').pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemableAdapter.adapt(item));
      })
    );
  }

  getDisplayThemeItems(): Observable<DisplayThemeItem[]> {
    return this.get(API_ENDPOINTS.dynT + 'DisplayThemeItem').pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.displayThemeItemAdapter.adapt(item));
      })
    );
  }

}
