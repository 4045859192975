import {Injectable} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LoggingService} from '../../core/services/logging.service';
import {Floor, FloorAdapter} from '../../domain-models/business/floor.model';
import {EMPTY, Observable} from 'rxjs';
import {ApiData} from '../../core/models/api-data.model';
import {API_ENDPOINTS, API_URL} from '../../shared/api-endpoints';
import {concatMap, first, map, shareReplay} from 'rxjs/operators';
import {ApiGraphicsFloorAdapter} from '../../domain-models/endpoint-specific/graphics.floor.model';
import {ROOM, Room, RoomAdapter} from '../../domain-models/business/room.model';
import {FLOOR_MODEL, FloorModel, FloorModelAdapter, FloorModelEnum} from '../../domain-models/business/floor-model.model';
import {CreateRoomFormProps} from '../models/room-creation-tool-props.model';
import {SvgGroup} from '../../svg-factory/models/svg-group.model';
import {ROOM_ALLOCATION, RoomAllocation, RoomAllocationAdapter} from '../../domain-models/business/room-allocation.model';
import {RoomAllocationPartial} from '../models/room-allocation-partial.model';
import {FLOOR_DATA} from '../../domain-models/business/floor-data.model';
import {RoomSharing, RoomSharingAdapter} from '../../domain-models/business/room-sharing.model';
import {Store} from '@ngrx/store';
import {addFloorData, addFloorDataItems, upsertFloorDataItems} from '../../store/svg-store/actions/floor-data.actions';
import {Workplace, WorkplaceAdapter} from '../../domain-models/business/workplace.model';
import {addWorkplace} from '../../store/floor-store/actions/workplace.actions';
import {Directory, DirectoryAdapter} from '../../domain-models/business/directory.model';
import {
  WORKPLACE_ALLOCATION,
  WorkplaceAllocation,
  WorkplaceAllocationAdapter
} from '../../domain-models/business/workplace-allocation.model';
import {SvgPoint} from '../../svg-factory/models/svg-point.model';
import {SvgTransform} from '../../svg-factory/models/svg-transform.model';
import {State} from '../../reducers';
import {TASK, Task, TaskAdapter} from '../../domain-models/business/task.model';
import {upsertTasks} from '../../store/project-management/actions/task.actions';
import {
  ApiGraphicsTaskFloorData,
  ApiGraphicsTaskFloorDataAdapter
} from '../../domain-models/endpoint-specific/graphics.task-floor-data.model';
import {ApiFloorsAreaIds} from '../../domain-models/endpoint-specific/floors.areaids.model';
import {TASK_FLOOR_MODEL, TaskFloorModel, TaskFloorModelAdapter} from '../../domain-models/business-extended/task-floor-model.model';
import {FLOOR_CATALOG, FloorCatalog, FloorCatalogAdapter} from '../../domain-models/business/floor-catalog.model';
import {SvgUse} from '../../svg-factory/models/svg-use.model';
import {TaskBusinessType, TaskBusinessTypeAdapter} from '../../domain-models/business/task-business-type.model';
import {ApiCadProjectsAddRoom, ApiCadProjectsAddRoomAdapter} from '../../domain-models/endpoint-specific/cad-projects-add-room.model';
import {FloorSelectors} from '../../store/floor-store/selectors';
import {ROOM_LAYOUT_TYPE, RoomLayoutType, RoomLayoutTypeAdapter} from '../../domain-models/business/room-layout-type.model';
import {ApiFloorDataContour, ApiFloorDataContourAdapter} from '../../domain-models/endpoint-specific/floor-data.contour.model';
import {UiContextActions, WorkplaceAllocationActions} from '../../store/floor-store/actions';
import {RoomActivityStatusTypeEnum} from '../../domain-models/business/room-activity-status-type.model';
import {FLOOR_WALL_STYLE} from '../../domain-models/business/floor-wall-style.model';
import {CreateDoorProps} from '../models/create-door-props.model';
import {ApiFloorDataEquipmentRotate} from '../../domain-models/endpoint-specific/floordata.equipment.rotate.model';
import {ApiFloorDataEquipmenCreate} from '../../domain-models/endpoint-specific/floordata.equipment.create.model';
import {RoomContourProps} from '../models/room-contour-props.model';
import {FloorCatalogActions, TaskFloorModelActions} from '../../store/svg-store/actions';
import {TASK_FLOOR, TaskFloor, TaskFloorAdapter} from '../../domain-models/business/task-floor.model';
import * as FloorDataActions from '../../store/svg-store/actions/floor-data.actions';

@Injectable({
  providedIn: 'root'
})

export class FloorService extends ApiService {

  constructor(http: HttpClient,
              loggingService: LoggingService,
              private store: Store<State>,
              private floorAdapter: FloorAdapter,
              private floorModelAdapter: FloorModelAdapter,
              private roomAdapter: RoomAdapter,
              private taskBusinessTypeAdapter: TaskBusinessTypeAdapter,
              private roomAllocationAdapter: RoomAllocationAdapter,
              private graphicsFloorAdapter: ApiGraphicsFloorAdapter,
              private graphicsTaskFloorData: ApiGraphicsTaskFloorDataAdapter,
              private roomSharingAdapter: RoomSharingAdapter,
              private workplaceAdapter: WorkplaceAdapter,
              private directoryAdapter: DirectoryAdapter,
              private floorCatalogAdapter: FloorCatalogAdapter,
              private taskAdapter: TaskAdapter,
              private taskFloorModelAdapter: TaskFloorModelAdapter,
              private workplaceAllocationAdapter: WorkplaceAllocationAdapter,
              private cadProjectsAddRoomAdapter: ApiCadProjectsAddRoomAdapter,
              private roomLayoutTypeAdapter: RoomLayoutTypeAdapter,
              private taskFloorAdapter: TaskFloorAdapter,
              private apiFloorDataContourAdapter: ApiFloorDataContourAdapter,
  ) {
    super(http, loggingService);
  }

  getTaskFloorData(taskId: number, floorModelIds?: number[]): Observable<ApiGraphicsTaskFloorData> {
    let params = new HttpParams();
    if (floorModelIds) {
      floorModelIds.forEach(id => {
        params = params.append('layerIds', id.toString());
      });
    }
    return this.get(API_URL + `graphics/TaskFloorData/${taskId}`, 1, params)
      .pipe(
        map((data: ApiData) => {
          return this.graphicsTaskFloorData.adapt(data.payload);
        }),
      );
  }

  getAllFloors(): Observable<Floor[]> {
    return this.get(API_ENDPOINTS.dynT + `floor`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.floorAdapter.adapt(item));
        })
      );
  }

  getAllFloorModels(): Observable<FloorModel[]> {
    return this.get(API_URL + `floormodel`)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.floorModelAdapter.adapt(item)))
      );
  }

  getAllFloorModelHoldingUseItems(): Observable<FloorModel[]> {
    return this.get(API_URL + `floormodel/holdinguseitems`)
      .pipe(
        map((data: ApiData) => {
          console.log(data);
          return data.payload.map((item) => this.floorModelAdapter.adapt(item));
        })
      );
  }

  getRoomsByTaskId(taskId: number): Observable<Room[]> {
    return this.get(API_URL + `floors/rooms/` + taskId)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomAdapter.adapt(item));
        })
      );
  }

  getRoomAllocationsByTaskId(taskId: number): Observable<RoomAllocation[]> {
    return this.get(API_URL + `floors/roomallocations/` + taskId)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomAllocationAdapter.adapt(item));
        })
      );
  }

  getWorkplaceAllocationsByTaskId(taskId: number): Observable<WorkplaceAllocation[]> {
    return this.get(`${API_URL}floors/WorkplaceAllocations/${taskId}`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.workplaceAllocationAdapter.adapt(item));
        })
      );
  }

  getFloorPerimeterIds(floorId: number): Observable<number[]> {
    return this.get(API_URL + `perimeters/floor/` + floorId)
      .pipe(
        map((data: ApiData) => {
          return data.payload as number[];
        })
      );
  }

  getRoomSharing(floorDataId: number): Observable<RoomSharing | never> {
    return this.get(API_ENDPOINTS.dynT + 'RoomSharing/' + floorDataId).pipe(
      map((data: ApiData) => {
        if (data.payload) {
          return this.roomSharingAdapter.adapt(data.payload);
        } else {
          return null;
        }
      })
    );
  }

  getRoomsSharing(floorDataIds: number[]): Observable<RoomSharing[] | null> {
    let params = new HttpParams();
    floorDataIds.forEach(id => {
      params = params.append(`id`, String(id));
    });
    return this.get(API_URL + 'RoomSharing', 1, params).pipe(
      map((data: ApiData) => {
        if (data.payload) {
          return data.payload.map(item => this.roomSharingAdapter.adapt(item));
        } else {
          return null;
        }
      })
    );
  }

  // getFloorLayoutTypeIds(taskId: number): Observable<number[]> {
  //   return this.get(API_URL + `floors/LayoutTypes/` + taskId)
  //     .pipe(
  //       map((data: ApiData) => {
  //         return data.payload as number[];
  //       })
  //     );
  // }
  //
  // getFloorActivityStatusTypeIds(taskId: number): Observable<number[]> {
  //   return this.get(API_URL + `floors/ActivityStatusTypes/` + taskId)
  //     .pipe(
  //       map((data: ApiData) => {
  //         return data.payload as number[];
  //       })
  //     );
  // }
  //
  // getFloorAttributionTypeIds(taskId: number): Observable<number[]> {
  //   return this.get(API_URL + `floors/AttributionTypes/` + taskId)
  //     .pipe(
  //       map((data: ApiData) => {
  //         return data.payload as number[];
  //       })
  //     );
  // }
  //
  // getFloorBusinessUnitIds(taskId: number): Observable<number[]> {
  //   return this.get(API_URL + `floors/BusinessUnits/` + taskId)
  //     .pipe(
  //       map((data: ApiData) => {
  //         return data.payload as number[];
  //       })
  //     );
  // }

  getFloorCurrentTaskAreaIds(taskId: number): Observable<ApiFloorsAreaIds> {
    // console.log('FloorService.getFloorCurrentTaskAreaIds');
    return this.get(API_URL + `floors/areaids/` + taskId)
      .pipe(
        map((data: ApiData) => {
          return data.payload as ApiFloorsAreaIds;
        })
      );
  }

  createRoom(roomCreationToolProps: CreateRoomFormProps): Observable<{ room: Room, svgGroups: SvgGroup[] }> {
    const dto = {};
    dto[FLOOR_DATA.taskId] = roomCreationToolProps.taskId;
    dto[ROOM.code] = roomCreationToolProps.code;
    dto[FLOOR_DATA.svgStatement] = roomCreationToolProps.svgStatement;
    dto['WallIds'] = roomCreationToolProps.wallIds;
    dto['x'] = roomCreationToolProps.x;
    dto['y'] = roomCreationToolProps.y;

    return this.post(API_ENDPOINTS.floorDataRoom, dto, new HttpParams().set('fromCurrent', String(roomCreationToolProps.fromFloorPartitioning))).pipe(
      //&fromCurrent=false étude ou true plan courrant
      map((data: ApiData) => {
        const result = {} as { room: Room, svgGroups: SvgGroup[] };
        result.svgGroups = data.payload['SvgGroupDTO'] as SvgGroup[];
        if (data.payload?.[TASK_FLOOR_MODEL.databaseTableName]) {
          const taskFloorModels = data.payload[TASK_FLOOR_MODEL.databaseTableName].map(e => this.taskFloorModelAdapter.adapt(e));
          this.store.dispatch(TaskFloorModelActions.addTaskFloorModels({taskFloorModels: taskFloorModels}));
        }
        result.room = this.roomAdapter.adapt(data.payload['Room']) as Room;
        return result;
      })
    );
  }

  reCalculateRoom(roomId: number, roomContourProps: RoomContourProps): Observable<{ room: Room, svgGroups: SvgGroup[] }> {
    const dto = {};
    dto[FLOOR_DATA.svgStatement] = roomContourProps.svgStatement;
    dto['WallIds'] = roomContourProps.wallIds;
    dto['x'] = roomContourProps.x;
    dto['y'] = roomContourProps.y;

    return this.patch(`${API_ENDPOINTS.floorDataRoom}/${roomId}/updatecontour`, dto).pipe(
      //&fromCurrent=false étude ou true plan courrant
      map((data: ApiData) => {
        const result = {} as { room: Room, svgGroups: SvgGroup[] };
        result.svgGroups = data.payload['SvgGroupDTO'] as SvgGroup[];
        result.room = this.roomAdapter.adapt(data.payload['Room']) as Room;
        return result;
      })
    );
  }

  deleteRoom(roomId: number): Observable<[{floorDataId: number, dataStateId: number}]> {
    return this.delete(`${API_ENDPOINTS.floorDataRoom}/${roomId}`).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  deleteRooms(roomIds: number[]): Observable<[{floorDataId: number, dataStateId: number}]> {
    let params = new HttpParams();
    roomIds.forEach(id => {
      params = params.append(`id`, String(id));
    });
    return this.delete(`${API_ENDPOINTS.floorDataRoom}`, params).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  updateRoomLabel(floorDataId: number, code: string): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/${floorDataId}/label`, code).pipe(
      map((data: ApiData) => data.payload as SvgGroup)
    );
  }

  updateRoomLayoutType(floorDataId: number, layoutTypeId: number): Observable<ApiData> {
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/${floorDataId}/layout`, layoutTypeId).pipe(
      map((data: ApiData) => {
        return data;
      })
    );
  }

  updateRoomsLayoutType(floorDataIds: number[], layoutTypeId: number): Observable<ApiData> {
    const dto = {};
    dto['RoomIds'] = floorDataIds;
    dto['UpdateValue'] = layoutTypeId;
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/layout`, dto).pipe(
      map((data: ApiData) => {
        return data;
      })
    );
  }

  updateRoomSharing(floorDataId: number, perimeterId: number): Observable<ApiData> {
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/${floorDataId}/share`, perimeterId).pipe(
      map((data: ApiData) => {
        return data;
      })
    );
  }

  updateRoomsSharing(floorDataIds: number[], perimeterId: number): Observable<ApiData> {
    const dto = {};
    dto['RoomIds'] = floorDataIds;
    dto['UpdateValue'] = perimeterId;
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/share`, dto).pipe(
      map((data: ApiData) => {
        return data;
      })
    );
  }

  updateRoomAllocations(floorDataId: number, roomAllocations: RoomAllocationPartial[]): Observable<RoomAllocation[]> {
    if (roomAllocations.length > 0) {
      const dto = [];
      roomAllocations.forEach((e: RoomAllocationPartial) => {
        const item = {};
        item[ROOM_ALLOCATION.businessUnitId] = e.businessUnitId;
        item[ROOM_ALLOCATION.rate] = e.rate;
        dto.push(item);
      });
      return this.patch(`${API_ENDPOINTS.floorDataRoom}/${floorDataId}/allocate`, dto).pipe(
        map((data: ApiData) => {
          return data.payload.map(item => this.roomAllocationAdapter.adapt(item));
        })
      );
    }
  }

  updateRoomsAllocations(floorDataIds: number[], roomAllocations: RoomAllocationPartial[]): Observable<RoomAllocation[]> {
    if (roomAllocations.length > 0) {
      const allocationsArr = [];
      roomAllocations.forEach((e: RoomAllocationPartial) => {
        const item = {};
        item[ROOM_ALLOCATION.businessUnitId] = e.businessUnitId;
        item[ROOM_ALLOCATION.rate] = e.rate;
        allocationsArr.push(item);
      });
      //console.log(dto);
      const dto = {};
      dto['RoomIds'] = floorDataIds;
      dto['UpdateValue'] = allocationsArr;
      return this.patch(`${API_ENDPOINTS.floorDataRoom}/allocate`, dto).pipe(
        map((data: ApiData) => {
          return data.payload[ROOM_ALLOCATION.databaseTableName].map(item => this.roomAllocationAdapter.adapt(item));
        })
      );
    }
  }

  discardRoom(floorDataId: number, activityStatusTypeId: number): Observable<ApiData> {
    if (activityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction
      || activityStatusTypeId === RoomActivityStatusTypeEnum.Empty) {
      return this.patch(`${API_ENDPOINTS.floorDataRoom}/${floorDataId}/discard`, activityStatusTypeId).pipe(
        map((data: ApiData) => {
          return data;
        })
      );
    } else {
      return EMPTY;
    }
  }

  discardRooms(floorDataIds: number[], activityStatusTypeId: number): Observable<ApiData> {
    if (activityStatusTypeId === RoomActivityStatusTypeEnum.UnderConstruction
      || activityStatusTypeId === RoomActivityStatusTypeEnum.Empty) {
      const dto = {};
      dto['RoomIds'] = floorDataIds;
      dto['UpdateValue'] = activityStatusTypeId;
      return this.patch(`${API_ENDPOINTS.floorDataRoom}/discard`, dto).pipe(
        map((data: ApiData) => {
          return data;
        })
      );
    } else {
      return EMPTY;
    }
  }

  wipeRoom(floorDataId: number): Observable<ApiData> {
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/${floorDataId}/wipe`, null).pipe(
      map((data: ApiData) => {
        return data;
      })
    );
  }

  wipeRooms(floorDataIds: number[]): Observable<ApiData> {
    const dto = {};
    dto['RoomIds'] = floorDataIds;
    return this.patch(`${API_ENDPOINTS.floorDataRoom}/wipe`, dto).pipe(
      map((data: ApiData) => {
        return data;
      })
    );
  }

  getWorkplacesByTaskId(taskId: number): Observable<Workplace[]> {
    return this.get(API_URL + `floors/workplaces/` + taskId)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.workplaceAdapter.adapt(item));
        })
      );
  }

  createWorkplace(dto: any): Observable<any> {
    return this.post(API_ENDPOINTS.floorDataWorkplace, dto)
      .pipe(
        map((data: ApiData) => {
          this.store.dispatch(addFloorDataItems({svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]}));
          this.store.dispatch(addWorkplace({workplace: this.workplaceAdapter.adapt(data.payload['Workplace'])}));
          if (data.payload?.[TASK_FLOOR_MODEL.databaseTableName]) {
            const taskFloorModel = this.taskFloorModelAdapter.adapt(data.payload[TASK_FLOOR_MODEL.databaseTableName]);
              this.store.dispatch(TaskFloorModelActions.addTaskFloorModel({taskFloorModel: taskFloorModel}));
          }
          //return {svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]};
          // return data.payload['SvgGroupDTO'] as SvgGroup[];
        })
      );
  }

  deleteWorkplace(workplaceId: number): Observable<[{floorDataId: number, dataStateId: number}]> {
    return this.delete(`${API_ENDPOINTS.floorDataWorkplace}/${workplaceId}`).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  deleteWorkplaces(workplaceIds: number[]): Observable<[{floorDataId: number, dataStateId: number}]> {
    let params = new HttpParams();
    workplaceIds.forEach(id => {
      params = params.append(`id`, String(id));
    });
    return this.delete(`${API_ENDPOINTS.floorDataWorkplace}`, params).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  // deleteWorkplace(id: number): Observable<ApiData> {
  //   return this.delete(`${API_ENDPOINTS.floorDataWorkplace}/${id}`).pipe(
  //     tap(data => {
  //       console.log(data);
  //       this.store.dispatch(RendererActions.resetSelection());
  //       // this.store.dispatch(WorkplaceActions.updateSelectedWorkplaceId({id: null}));
  //       this.store.dispatch(FloorDataActions.removeFloorDataItem({id: id}));
  //         //this.store.dispatch(FloorDataActions.removeFloorDataItem({id: id}));
  //     })
  //   );
  // }

  getWorkplaceAllocationsByWorkplaceId(id: number): Observable<WorkplaceAllocation[]> {
    return this.get(API_URL + `Workplaces/${id}/WorkplaceAllocations`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.workplaceAllocationAdapter.adapt(item));
        })
      );
  }

  createWorkplaceAllocation(dto: any): Observable<WorkplaceAllocation> {
    return this.post(API_ENDPOINTS.floorDataWorkplaceAllocation, dto)
      .pipe(
        map((data: ApiData) => {
          console.log(data);
          this.store.dispatch(addFloorData({svgGroup: data.payload['SvgGroupDTO'] as SvgGroup}));
          this.store.dispatch(WorkplaceAllocationActions.addWorkplaceAllocation({workplaceAllocation: this.workplaceAllocationAdapter.adapt(data.payload[WORKPLACE_ALLOCATION.databaseTableName])}));
          if (data.payload?.[TASK_FLOOR_MODEL.databaseTableName]) {
            const taskFloorModel = this.taskFloorModelAdapter.adapt(data.payload[TASK_FLOOR_MODEL.databaseTableName]);
            this.store.dispatch(TaskFloorModelActions.addTaskFloorModel({taskFloorModel: taskFloorModel}));
          }
          return this.workplaceAllocationAdapter.adapt(data.payload[WORKPLACE_ALLOCATION.databaseTableName]);
        })
      );
  }

  updateWorkplaceAllocation(peopleAllocation: WorkplaceAllocation): Observable<any> {
    return this.patch(API_ENDPOINTS.floorDataWorkplaceAllocation, this.workplaceAllocationAdapter.encode(peopleAllocation));
  }

  deleteWorkplaceAllocation(floorDataId: number): Observable<any> {
    /** floorDataId of people allocation label **/
    return this.delete(`${API_ENDPOINTS.floorDataWorkplaceAllocation}/${floorDataId}`);
  }

  deleteFloorData(floorDataId: number): Observable<any> {
    return this.delete(API_ENDPOINTS.dynT + 'FloorData/' + floorDataId);
  }

  updateLabelPosition(floorDataId: number, point: SvgPoint): Observable<any> {
    const dto = {};
    //dto[FLOOR_DATA.id] = floorDataId;
    dto['x'] = point.x;
    dto['y'] = point.y;
    return this.patch(API_URL + `floordata/movelabel/` + floorDataId, dto) //`floordata/labelposition?floordataid=${floorDataId}&x=${point.x}&y=${point.y}`
      .pipe(
        // map((data: ApiData) => {
        //   //this.store.dispatch(addFloorDataItems({svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]}));
        //   //this.store.dispatch(addPeopleAllocations({peopleAllocations: data.payload['PeopleAllocation'] as PeopleAllocation[]}));
        //   //return {svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]};
        //   // return data.payload['SvgGroupDTO'] as SvgGroup[];
        // })
      );
    //floordata/labelposition?floordataid=&x=&y=
  }

  updateEquipmentPosition(floorDataId: number, svgTransform: SvgTransform) {
    //const dto = {};
    //dto[FLOOR_DATA.id] = floorDataId;
    return this.patch(API_URL + `floordata/MoveEquipment/` + floorDataId, svgTransform) //`floordata/labelposition?floordataid=${floorDataId}&x=${point.x}&y=${point.y}`
      .pipe(
        map((data: ApiData) => {
          //this.store.dispatch(addFloorDataItems({svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]}));
          //this.store.dispatch(addPeopleAllocations({peopleAllocations: data.payload['PeopleAllocation'] as PeopleAllocation[]}));
          //return {svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]};
          // return data.payload['SvgGroupDTO'] as SvgGroup[];
        })
      );
  }

  createEquipment(taskId: number, floorCatalogId: number, insertionPoint: SvgPoint): Observable<ApiFloorDataEquipmenCreate> {
    const dto = {};
    dto[FLOOR_DATA.taskId] = taskId;
    dto[FLOOR_CATALOG.id] = floorCatalogId;
    dto['x'] = insertionPoint.x;
    dto['y'] = insertionPoint.y;

    return this.post(API_ENDPOINTS.floorDataEquipment, dto)
      .pipe(
        map((data: ApiData) => {
          const result = {} as ApiFloorDataEquipmenCreate;
          result.svgUse = data.payload['SvgUseDTO'] as SvgUse;
          result.floorCatalog = data.payload['FloorCatalog'] as SvgGroup;
          result.floorModel = this.taskFloorModelAdapter.adapt(data.payload['FloorModel']);
          result.floorModel.taskId = taskId;
          result.floorModel.attributes = data.payload['FloorModel']['attributes'];
          return result;
        })
      );
  }

  getSvgContour(taskId: number, x: number, y: number, isUpdate: boolean): Observable<ApiFloorDataContour> {
    let params = new HttpParams();
    params = params.append('taskId', String(taskId));
    params = params.append('x', String(x));
    params = params.append('y', String(y));
    params = params.append('isUpdate', String(isUpdate));
    return this.get(API_ENDPOINTS.svgContour, 1, params)
      .pipe(
        map((data: ApiData) => this.apiFloorDataContourAdapter.adapt(data.payload))
      );
  }

  getDirectory(): Observable<Directory[]> {
    return this.get(API_ENDPOINTS.dynT + 'Directory').pipe(
      map((data: ApiData) => {
        return data.payload.map((item) => this.directoryAdapter.adapt(item));
      }),
      shareReplay(1), // https://dev.to/angular/how-to-cache-http-requests-in-angular-5c8i
      /** WARNING share replay avoids multiple HTTP Calls, therefore it may be not up to date**/
    );
  }

  /** TASK **/

  getFloorActiveProjects(): Observable<Task[]> {
    return this.store.select(FloorSelectors.selectCurrentFloor).pipe(
      first(),
      concatMap(floor => this.get(API_URL + `projects/flooractiveprojects/${floor.id}`)),
      map((data: ApiData) => {
        const tasks = data.payload.map((item) => this.taskAdapter.adapt(item));
        this.store.dispatch(upsertTasks({tasks: tasks}));
        return tasks;
      })
    );
  }

  getFloorActiveTaks(activeProjectId: number): Observable<Task[]> {
    /** Return EMPTY Observable avoiding undefined error if activeProjectId is null or undefined**/
    if (activeProjectId) {
      return this.store.select(FloorSelectors.selectCurrentFloor).pipe(
        first(),
        map(floor => {
            return new HttpParams()
              .set('projectId', activeProjectId.toString())
              .set('floorId', floor.id.toString());
          }
        ),
        concatMap(httpParams => this.get(API_URL + `projects/FloorActiveTasks`, null, httpParams)),
        map((data: ApiData) => {
          const tasks = data.payload.map((item) => this.taskAdapter.adapt(item));
          this.store.dispatch(upsertTasks({tasks: tasks}));
          return tasks;
        })
      );
    } else {
      return null;
    }

  }

  addFloorDataToTask(floorDataId: number, taskId: number): Observable<ApiCadProjectsAddRoom> {
    const dto = {
      [FLOOR_DATA.id]: floorDataId,
      [TASK.id]: taskId,
    };
    /**
     * FloorCatalog: []
     FloorModel: []
     SvgDTO: {SvgUseDTO: [],…}
     SvgGroupDTO: []
     SvgUseDTO: []
     */
    return this.post(API_URL + 'cadprojects/addroom', dto)
      .pipe(
        map(data => this.cadProjectsAddRoomAdapter.adapt(data.payload))
      );
  }

  /** WALLS **/

  getEditableWalls(taskId: number): Observable<any> {
    return this.get(API_URL + `floordata/editablewalls/${taskId}`)
      .pipe(
        map((data: ApiData) => {
          this.store.dispatch(upsertFloorDataItems({svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]}));
        })
      );
  }

  createFloorModel(dto: any): Observable<FloorModel> {
    return this.post(API_URL + 'FloorModel', dto)
      .pipe(
        map((data: ApiData) => this.floorModelAdapter.adapt(data.payload)),
      );
  }

  createTaskBusinessType(dto: any): Observable<TaskBusinessType> {
    return this.post(API_URL + 'taskbusinesstypes', dto)
      .pipe(
        map((data: ApiData) => this.taskBusinessTypeAdapter.adapt(data.payload))
      );
  }

  getFloorCatalogLayers(): Observable<FloorModel[]> {
    return this.get(API_URL + 'FloorCatalog/layers').pipe(
      map((data: ApiData) => data.payload.map(floorModel => this.floorModelAdapter.adapt(floorModel)))
    );
  }

  getFloorCatalogByFloorModelId(floorModelId: number): Observable<FloorCatalog[]> {
    return this.get(API_URL + `FloorCatalog/layer/${floorModelId}`).pipe(
      map((data: ApiData) => data.payload.map(item => this.floorCatalogAdapter.adapt(item)))
    );
  }

  getRoomLayoutTypesLastItems(): Observable<RoomLayoutType[]> {
    return this.get(`${API_ENDPOINTS.dynH}${ROOM_LAYOUT_TYPE.databaseTableName}/lastitems`).pipe(
      map((data: ApiData) => data.payload.map(item => this.roomLayoutTypeAdapter.adapt(item)))
    );
  }

  /**
   * WALLS
   */

  createWall(taskId: number, startPoint: SvgPoint, endPoint: SvgPoint, wallStyleId: number, wallWidth: number, spaceBound: boolean): Observable<SvgGroup> {
    const dto = {};
    dto[FLOOR_DATA.taskId] = taskId;
    dto['StartPoint'] = {
      X: startPoint.x,
      Y: startPoint.y
    };
    dto['EndPoint'] = {
      X: endPoint.x,
      Y: endPoint.y
    };
    dto[FLOOR_WALL_STYLE.id] = wallStyleId;
    dto['Width'] = wallWidth;
    dto['SpaceBound'] = spaceBound;
    return this.post(`${API_URL}floordata/walls`, dto).pipe(
      map((data: ApiData) => data.payload['SvgGroupDTO'] as SvgGroup)
    );
  }

  deleteWall(wallId: number): Observable<[{floorDataId: number, dataStateId: number}]> {
    return this.delete(`${API_ENDPOINTS.floorDataWalls}/${wallId}`).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  deleteWalls(wallIds: number[]): Observable<[{floorDataId: number, dataStateId: number}]> {
    let params = new HttpParams();
    wallIds.forEach(id => {
      params = params.append(`id`, String(id));
    });
    return this.delete(`${API_ENDPOINTS.floorDataWalls}`, params).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  stretchWall(wallId: number, newPosition: SvgPoint, startPoint: boolean): Observable<{svgGroup: SvgGroup[]; removedFloorDataIds: number[]}> {
    const params = new HttpParams()
      .set('wallId', String(wallId))
      .set('x', String(newPosition.x))
      .set('y', String(newPosition.y))
      .set('startPoint', String(startPoint));
    return this.patch(`${API_URL}floordata/walls/stretch`, null, 1, params).pipe(
      map((data: ApiData) => {
        return {
          svgGroup: data.payload['SvgGroupDTO'] as SvgGroup[],
          removedFloorDataIds: data.payload['RemovedFloorDataIds'] as number[],
        };
      })
    );
  }

  updateWallStyle(floorDataId: number, wallStyleId: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataWalls}/${floorDataId}/wallstyle`, wallStyleId).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateWallsStyle(floorDataIds: number[], wallStyleId: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['WallIds'] = floorDataIds;
    dto['UpdateValue'] = wallStyleId;
    return this.patch(`${API_ENDPOINTS.floorDataWalls}/wallstyle`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  updateWallWidth(floorDataId: number, width: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataWalls}/${floorDataId}/wallwidth`, width).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateWallsWidth(floorDataIds: number[], width: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['WallIds'] = floorDataIds;
    dto['UpdateValue'] = width;
    return this.patch(`${API_ENDPOINTS.floorDataWalls}/wallwidth`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  updateWallSpaceBound(floorDataId: number, spaceBound: boolean): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataWalls}/${floorDataId}/spacebound`, spaceBound).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateWallsSpaceBound(floorDataIds: number[], spaceBound: boolean): Observable<SvgGroup[]> {
    const dto = {};
    dto['WallIds'] = floorDataIds;
    dto['UpdateValue'] = spaceBound;
    return this.patch(`${API_ENDPOINTS.floorDataWalls}/spacebound`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  /**
   * Doors
   */

  createDoor(wallId: number, insertionPoint: SvgPoint, props: CreateDoorProps): Observable<{ svgGroup: SvgGroup, taskFloorModel?: TaskFloorModel }> {
    const dto = {};
    dto['WallId'] = wallId;
    dto['X'] = insertionPoint.x;
    dto['Y'] = insertionPoint.y;
    dto['DoorWidth'] = props.doorWidth;
    dto['OpeningAngle'] = props.openingAngle;
    dto['DoorStyleId'] = props.doorStyleId;
    dto['OpeningDirection'] = props.openingDirection;
    dto['WallSideOrientation'] = props.wallSideOrientation;

    return this.post(`${API_ENDPOINTS.floorDataDoors}`, dto).pipe(
      map((data: ApiData) => {
        const taskFloorModel = data.payload?.[TASK_FLOOR_MODEL.databaseTableName] ? this.taskFloorModelAdapter.adapt(data.payload[TASK_FLOOR_MODEL.databaseTableName]) : null;
        console.log(taskFloorModel);
        return {
          svgGroup: data.payload['SvgGroupDTO'] as SvgGroup,
          taskFloorModel: taskFloorModel,
        };
      })
    );
  }

  deleteDoor(doorId: number): Observable<[{floorDataId: number, dataStateId: number}]> {
    return this.delete(`${API_ENDPOINTS.floorDataDoors}/${doorId}`).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  deleteDoors(doorIds: number[]): Observable<[{floorDataId: number, dataStateId: number}]> {
    let params = new HttpParams();
    doorIds.forEach(id => {
      params = params.append(`id`, String(id));
    });
    return this.delete(`${API_ENDPOINTS.floorDataDoors}`, params).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  translateDoor(doorId: number, point: SvgPoint): Observable<SvgGroup> {
    const params = new HttpParams()
      .set('doorId', String(doorId))
      .set('x', String(point.x))
      .set('y', String(point.y));
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/translate`, null, 1, params).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateDoorStyle(floorDataId: number, doorStyleId: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/${floorDataId}/doorstyle`, doorStyleId).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateDoorsStyle(floorDataIds: number[], doorStyleId: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['DoorIds'] = floorDataIds;
    dto['UpdateValue'] = doorStyleId;
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/doorstyle`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  updateDoorWidth(floorDataId: number, width: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/${floorDataId}/doorwidth`, width).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateDoorsWidth(floorDataIds: number[], width: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['DoorIds'] = floorDataIds;
    dto['UpdateValue'] = width;
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/doorwidth`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  updateDoorOpeningAngle(floorDataId: number, openingAngle: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/${floorDataId}/dooropeningangle`, openingAngle).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateDoorsOpeningAngle(floorDataIds: number[], openingAngle: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['DoorIds'] = floorDataIds;
    dto['UpdateValue'] = openingAngle;
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/dooropeningangle`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  updateDoorOpeningDirection(floorDataId: number, openingDirection: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/${floorDataId}/DoorOpeningDirection`, openingDirection).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateDoorsOpeningDirection(floorDataIds: number[], openingDirection: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['DoorIds'] = floorDataIds;
    dto['UpdateValue'] = openingDirection;
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/DoorOpeningDirection`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  updateDoorWallSideOrientation(floorDataId: number, wallSideOrientation: number): Observable<SvgGroup> {
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/${floorDataId}/DoorWallSideOrientation`, wallSideOrientation).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup;
      })
    );
  }

  updateDoorsWallSideOrientation(floorDataIds: number[], wallSideOrientation: number): Observable<SvgGroup[]> {
    const dto = {};
    dto['DoorIds'] = floorDataIds;
    dto['UpdateValue'] = wallSideOrientation;
    return this.patch(`${API_ENDPOINTS.floorDataDoors}/DoorWallSideOrientation`, dto).pipe(
      map((data: ApiData) => {
        return data.payload as SvgGroup[];
      })
    );
  }

  /**
   * EQUIPMENT
   */

  translateEquipment(equipmentId: number, translationVector: SvgPoint): Observable<{ svgUse: SvgUse }> {
    const params = new HttpParams()
      //.set('equipmentId', String(equipmentId))
      .set('x', String(translationVector.x))
      .set('y', String(translationVector.y));
    return this.patch(`${API_ENDPOINTS.floorDataEquipment}/${equipmentId}/translate`, null, 1, params).pipe(
      map((data: ApiData) => {
        return {
          svgUse: data.payload as SvgUse,
          //svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]
        };
      })
    );
  }

  translateEquipments(equipmentIds: number[], translationVector: SvgPoint): Observable<{ svgUses: SvgUse[] }> {
    const dto = {};
    dto['EquipmentIds'] = equipmentIds;
    dto['UpdateXValue'] = translationVector.x;
    dto['UpdateYValue'] = translationVector.y;

    return this.patch(`${API_ENDPOINTS.floorDataEquipment}/translate`, dto).pipe(
      map((data: ApiData) => {
        return {
          svgUses: data.payload as SvgUse[],
          //svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]
        };
      })
    );
  }

  rotateEquipment(rotation: ApiFloorDataEquipmentRotate): Observable<{ svgUse: SvgUse}> {
    const params = new HttpParams()
      .set('a', String(rotation.angle))
      .set('x', String(rotation.translate.x))
      .set('y', String(rotation.translate.y));
    return this.patch(`${API_ENDPOINTS.floorDataEquipment}/${rotation.equipmentId}/rotate`, null, 1, params).pipe(
      map((data: ApiData) => {
        return {
          svgUse: data.payload as SvgUse,
          //svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]
        };
      })
    );
  }

  rotateEquipments(rotations: ApiFloorDataEquipmentRotate[]): Observable<{ svgUses: SvgUse[] }> {
    const dto = rotations.map(rotation => {
      return {
        Id: rotation.equipmentId,
        Translate: {
          X: rotation.translate.x,
          Y: rotation.translate.y
        },
        Angle: rotation.angle
      };
    });
    return this.patch(`${API_ENDPOINTS.floorDataEquipment}/rotate`, dto).pipe(
      map((data: ApiData) => {
        return {
          svgUses: data.payload as SvgUse[],
          //svgGroups: data.payload['SvgGroupDTO'] as SvgGroup[]
        };
      })
    );
  }

  deleteEquipment(equipmentId: number): Observable<[{floorDataId: number, dataStateId: number}]> {
    return this.delete(`${API_ENDPOINTS.floorDataEquipment}/${equipmentId}`).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  deleteEquipments(equipmentIds: number[]): Observable<[{floorDataId: number, dataStateId: number}]> {
    let params = new HttpParams();
    equipmentIds.forEach(id => {
      params = params.append(`id`, String(id));
    });
    return this.delete(`${API_ENDPOINTS.floorDataEquipment}`, params).pipe(
      map(apiData => apiData.payload.map(item => {
        return {
          floorDataId: item['id'],
          dataStateId: item['dataStateId']
        };
      })),
    );
  }

  getAllFloorCatalog(): Observable<SvgGroup[]> {
    return this.get(API_URL + `floorcatalog`)
      .pipe(
        map((data: ApiData) => data.payload as SvgGroup[])
      );
  }

  createDirectTask(roomIds: number[], taskName: string): Observable<{ task: Task, taskFloor: TaskFloor }> {
    const dto = {
      RoomIds: roomIds,
      [TASK.name] : taskName,
    };
    return this.post(`${API_ENDPOINTS.cadProjectsDirectTask}/${FloorModelEnum.Planning}`, dto).pipe(
      map((data: ApiData) => {
        return {
        task: this.taskAdapter.adapt(data.payload[TASK.databaseTableName]) as Task,
      taskFloor: this.taskFloorAdapter.adapt(data.payload[TASK_FLOOR.databaseTableName]) as TaskFloor,
      };
      }),
    );
  }

  getRoomTaskCopies(roomId: number): Observable<any> {
    return this.get(`${API_ENDPOINTS.floorDataRoom}/${roomId}/taskcopies`).pipe(
      map((data: ApiData) => data.payload)
    );
  }

  getRoomsTaskCopies(roomIds: number[]): Observable<any> {
    let params = new HttpParams();
    roomIds.forEach(id => params.set('id', String(id)));
    return this.get(`${API_ENDPOINTS.floorDataRoom}/taskcopies`, 1, params).pipe(
      map((data: ApiData) => data.payload)
    );
  }

  /** TASKS **/

  HasValidationFunction(taskId: number): Observable<boolean> {
    return this.get(`${API_ENDPOINTS.hasValidationFunction}/${taskId}`).pipe(
      map((data: ApiData) => data.payload)
    );
  }

}
