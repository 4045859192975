import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';
import {Color, ColorUtils} from '../../core/utils/color';

export enum RoomActivityStatusTypeEnum {
  Usable = 1,
  UnderConstruction,
  Empty
}

export const ROOM_ACTIVITY_STATUS_TYPE = {
  databaseTableName: 'RoomActivityStatusType',
  id: 'RoAcTy_Id',
  name: 'RoAcTy_Name',
  displayNameId: 'RoAcTy_DisplayNameId',
  color: 'RoAcTy_Color'
};

export class RoomActivityStatusType {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number,
    public color: Color
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class RoomActivityStatusTypeAdapter implements Adapter<RoomActivityStatusType> {
  adapt(item: any): RoomActivityStatusType {
    return new RoomActivityStatusType(
      ROOM_ACTIVITY_STATUS_TYPE.databaseTableName,
      item[ROOM_ACTIVITY_STATUS_TYPE.id],
      item[ROOM_ACTIVITY_STATUS_TYPE.name],
      item[ROOM_ACTIVITY_STATUS_TYPE.displayNameId],
      ColorUtils.colorFromHexARGB(item[ROOM_ACTIVITY_STATUS_TYPE.color])
    );
  }
  encode(item: RoomActivityStatusType): ISingleBackendObj {
    const encoded = {
      TableName: ROOM_ACTIVITY_STATUS_TYPE.databaseTableName,
      [ROOM_ACTIVITY_STATUS_TYPE.id]: item.id,
      [ROOM_ACTIVITY_STATUS_TYPE.name]: item.name,
      [ROOM_ACTIVITY_STATUS_TYPE.displayNameId]: item.displayNameId,
      [ROOM_ACTIVITY_STATUS_TYPE.color]: item.color.toHexArgb(),
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
