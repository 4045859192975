import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export enum FloorDataStateEnum {
  Pristine = 1,
  Added,
  Deleted,
  Updated,
  Orphan,
  ChildUpdated
}

export const FLOOR_DATA_STATE = {
  databaseTableName: 'FloorDataState',
  id: 'FlDaSt_Id',
  name: 'FlDaSt_Name',
  displayNameId: 'FlDaSt_DisplayNameId'
};

export class FloorDataState {
  constructor(
    public databaseTableName: string,
    public id: number,
    public name: string,
    public displayNameId: number
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class FloorDataStateAdapter implements Adapter<FloorDataState> {
  adapt(item: any): FloorDataState {
    return new FloorDataState(
      FLOOR_DATA_STATE.databaseTableName,
      item[FLOOR_DATA_STATE.id],
      item[FLOOR_DATA_STATE.name],
      item[FLOOR_DATA_STATE.displayNameId],
    );
  }

  encode(item: FloorDataState): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_DATA_STATE.databaseTableName,
      [FLOOR_DATA_STATE.id]: item.id,
      [FLOOR_DATA_STATE.name]: item.name,
      [FLOOR_DATA_STATE.displayNameId]: item.displayNameId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
