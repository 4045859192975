import {ComponentRef, Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {ComponentPortal} from '@angular/cdk/portal';
import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ImageTooltipComponent} from '../image-tooltip/image-tooltip.component';

@Directive({
  selector: '[echoImageTooltip]'
})
export class ImageTooltipDirective implements OnInit {

  @Input() tooltipUrl: any;
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay,
              private overlayPositionBuilder: OverlayPositionBuilder,
              private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center', //center
        originY: 'top',
        overlayX: 'center', //center
        overlayY: 'bottom',
        offsetY: -8,
      }]);

    this.overlayRef = this.overlay.create({positionStrategy});
  }

  @HostListener('mouseenter')
  show() {
    const tooltipRef: ComponentRef<ImageTooltipComponent>
      = this.overlayRef.attach(new ComponentPortal(ImageTooltipComponent));
    tooltipRef.instance.imageUrl = this.tooltipUrl;
  }

// @HostListener('click')
  @HostListener('mouseleave')
  hide() {
    this.overlayRef.detach();
  }

}
