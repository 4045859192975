import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MenuItem} from '../models/menu-item.model';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from './logging.service';
import {API_ENDPOINTS} from '../../shared/api-endpoints';
import {Z_APP_MENU} from '../../domain-models/core/Z_App_Menu';
import {Z_LANGUAGE_ITEM} from '../../domain-models/core/z-language-item.model';
import {ApiData} from '../models/api-data.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends ApiService {
  public currentPage$ = new BehaviorSubject<any>('');
  public itemToDisplay$ = new BehaviorSubject<any>(null);

  constructor(http: HttpClient,
              loggingService: LoggingService) {
    super(http, loggingService);
  }

  public loadMenu() {
    this.get(API_ENDPOINTS.appMenus)
      .pipe(
        map((data: ApiData) => this.itemToDisplay$.next(this.buildMenu(data))),
      )
      .subscribe();
  }

  buildMenu(data: ApiData): Array<MenuItem> {
    const menu: MenuItem[] = [];
    data.payload.forEach(e => {
      const menuItem = this.addMenuItem(e);
      menu.push(menuItem);
    });
    menu.sort((a: MenuItem, b: MenuItem) => a.order - b.order);
    return menu;
  }

  addMenuItem(element: any): MenuItem {
    const menuItem = {} as MenuItem;
    menuItem.id = element[Z_APP_MENU.id];
    menuItem.label = element[Z_LANGUAGE_ITEM.text];
    menuItem.component = element[Z_APP_MENU.componentPath];
    menuItem.endpoint = element[Z_APP_MENU.apiEndPoint];
    menuItem.dataContext = element[Z_APP_MENU.dataContext];
    menuItem.usageContextId = element[Z_APP_MENU.usageContextId];
    menuItem.order = element[Z_APP_MENU.orderIndex];
    const children = element['Children'];
    if (children) {
      const subItems: MenuItem[] = [];
      children.forEach(e => {
        subItems.push(this.addMenuItem(e));
      });
      menuItem.items = subItems;
      menuItem.items.sort((a: MenuItem, b: MenuItem) => a.order - b.order);
    }
    return menuItem;
  }

}
