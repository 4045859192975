import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from '../../floor-store/reducers';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {exhaustMap, switchMap} from 'rxjs/operators';
import {TaskFunctionActions} from '../actions';
import {of} from 'rxjs';


@Injectable()
export class TaskFunctionEffects {

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }

  beginGetAllTaskFunctions$ = createEffect(() => this.actions$.pipe(
    ofType(TaskFunctionActions.beginGetAllTaskFunctions),
    exhaustMap((action) =>
      this.projectManagementService.getAllTaskFunctions().pipe(
        switchMap(taskFunctions =>
          of(TaskFunctionActions.upsertTaskFunctions({taskFunctions: taskFunctions }))
        ),
    )
  )));
}
