import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const ROOM = {
  databaseTableName: 'Room',
  floorDataId: 'Ro_FloorDataId',
  code: 'Ro_Code',
  area: 'Ro_Area',
  layoutTypeId: 'Ro_LayoutTypeId',
  attributionTypeId: 'Ro_AttributionTypeId',
  activityStatusTypeId: 'Ro_ActivityStatusTypeId'
};

export class Room {
  constructor(
    public databaseTableName: string,
    public floorDataId: number,
    public code: string,
    public area: number,
    public layoutTypeId: number,
    public attributionTypeId: number,
    public activityStatusTypeId: number
  ) {}

}

@Injectable({
  providedIn: 'root'
})
export class RoomAdapter implements Adapter<Room> {
  adapt(item: any): Room {
    return new Room(
      ROOM.databaseTableName,
      item[ROOM.floorDataId],
      item[ROOM.code],
      item[ROOM.area],
      item[ROOM.layoutTypeId],
      item[ROOM.attributionTypeId],
      item[ROOM.activityStatusTypeId]
    );
  }
  encode(item: Room | Partial<Room>): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [ROOM.floorDataId]: item.floorDataId,
      [ROOM.code]: item.code,
      [ROOM.area]: item.area,
      [ROOM.layoutTypeId]: item.layoutTypeId,
      [ROOM.attributionTypeId]: item.attributionTypeId,
      [ROOM.activityStatusTypeId]: item.activityStatusTypeId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
