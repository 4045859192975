import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const ROOM_ALLOCATION = {
  databaseTableName: 'RoomAllocation',
  floorDataId: 'RoAl_FloorDataId',
  businessUnitId: 'RoAl_BusinessUnitId',
  area: 'RoAl_Area',
  rate: 'RoAl_Rate',
  updateTimeStamp: 'RoAl_UpdateTimeStamp',
};

export class RoomAllocation {
  constructor(
    public databaseTableName: string,
    public floorDataId: number,
    public businessUnitId: number,
    public area: number,
    public rate: number,
    public updateTimeStamp: Date,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class RoomAllocationAdapter implements Adapter<RoomAllocation> {
  adapt(item: any): RoomAllocation {
    return new RoomAllocation(
      ROOM_ALLOCATION.databaseTableName,
      item[ROOM_ALLOCATION.floorDataId],
      item[ROOM_ALLOCATION.businessUnitId],
      item[ROOM_ALLOCATION.area],
      item[ROOM_ALLOCATION.rate],
      new Date(item[ROOM_ALLOCATION.updateTimeStamp]),
    );
  }

  encode(item: RoomAllocation): ISingleBackendObj {
    const encoded = {
      TableName: ROOM_ALLOCATION.databaseTableName,
      [ROOM_ALLOCATION.floorDataId]: item.floorDataId,
      [ROOM_ALLOCATION.businessUnitId]: item.businessUnitId,
      [ROOM_ALLOCATION.area]: item.area,
      [ROOM_ALLOCATION.rate]: item.rate,
      [ROOM_ALLOCATION.updateTimeStamp]: item.updateTimeStamp.toDateString(),
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
