import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const Z_DB_COLUMN = {
  databaseTableName: 'z_db_column',
  tableName: 'DbCo_TableName',
  columnName: 'DbCo_ColumnName',
  columnDisplayNameId: 'DbCo_ColumnDisplayNameId',
  isPrimaryKey: 'DbCo_IsPrimaryKey',
  isIdentity: 'DbCo_IsIdentity',
  isBrowsable: 'DbCo_IsBrowsable',
  isReadOnly: 'DbCo_IsReadOnly',
  scopeId: 'DbCo_ScopeId',
  categoryId: 'DbCo_CategoryId',
  displayOrder: 'DbCo_DisplayOrder',
  isDefault: 'DbCo_IsDefault',
  type: 'DbCo_Type',
  displayStringFormat: 'DbCo_DisplayStringFormat',
  readGrantId: 'DbCo_ReadGrantId',
  updateGrantId: 'DbCo_UpdateGrantId',
};

export class DbColumn {
  constructor(
    public databaseTableName: string,
    public tableName: string,
    public columnName: string,
    public columnDisplayNameId: number,
    public isPrimaryKey: boolean,
    public isIdentity: boolean,
    public isBrowsable: boolean,
    public isReadOnly: boolean,
    public scopeId: number,
    public categoryId: number,
    public displayOrder: number,
    public isDefault: boolean,
    public type: number,
    public displayStringFormat: number,
    public readGrantId: number,
    public updateGrantId: number
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class DbColumnAdapter implements Adapter<DbColumn> {

  adapt(item: any): DbColumn {
    return new DbColumn(
      Z_DB_COLUMN.databaseTableName,
      item[Z_DB_COLUMN.tableName],
      item[Z_DB_COLUMN.columnName],
      item[Z_DB_COLUMN.columnDisplayNameId],
      item[Z_DB_COLUMN.isPrimaryKey],
      item[Z_DB_COLUMN.isIdentity],
      item[Z_DB_COLUMN.isBrowsable],
      item[Z_DB_COLUMN.isReadOnly],
      item[Z_DB_COLUMN.scopeId],
      item[Z_DB_COLUMN.categoryId],
      item[Z_DB_COLUMN.displayOrder],
      item[Z_DB_COLUMN.isDefault],
      item[Z_DB_COLUMN.type],
      item[Z_DB_COLUMN.displayStringFormat],
      item[Z_DB_COLUMN.readGrantId],
      item[Z_DB_COLUMN.updateGrantId]
    );
  }

  encode(item: DbColumn): ISingleBackendObj {
    const encoded = {
      TableName: Z_DB_COLUMN.databaseTableName,
      [Z_DB_COLUMN.tableName]: item.tableName,
      [Z_DB_COLUMN.columnName]: item.columnName,
      [Z_DB_COLUMN.columnDisplayNameId]: item.columnDisplayNameId,
      [Z_DB_COLUMN.isPrimaryKey]: item.isPrimaryKey,
      [Z_DB_COLUMN.isIdentity]: item.isIdentity,
      [Z_DB_COLUMN.isBrowsable]: item.isBrowsable,
      [Z_DB_COLUMN.isReadOnly]: item.isReadOnly,
      [Z_DB_COLUMN.scopeId]: item.scopeId,
      [Z_DB_COLUMN.categoryId]: item.categoryId,
      [Z_DB_COLUMN.displayOrder]: item.displayOrder,
      [Z_DB_COLUMN.isDefault]: item.isDefault,
      [Z_DB_COLUMN.type]: item.type,
      [Z_DB_COLUMN.displayStringFormat]: item.displayStringFormat,
      [Z_DB_COLUMN.readGrantId]: item.readGrantId,
      [Z_DB_COLUMN.updateGrantId]: item.updateGrantId
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
