import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const ROOM_SHARING = {
  databaseTableName: 'RoomSharing',
  floorDataId: 'RoSh_FloorDataId',
  perimeterId: 'RoSh_PerimeterId',
};

export class RoomSharing {
  constructor(
    public databaseTableName: string,
    public floorDataId: number,
    public perimeterId: number,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class RoomSharingAdapter implements Adapter<RoomSharing> {
  adapt(item: any): RoomSharing {
    return new RoomSharing (
      ROOM_SHARING.databaseTableName,
      item[ROOM_SHARING.floorDataId],
      item[ROOM_SHARING.perimeterId],
    );
  }
  encode(item: RoomSharing): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [ROOM_SHARING.floorDataId]: item.floorDataId,
      [ROOM_SHARING.perimeterId]: item.perimeterId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
