import {Pipe, PipeTransform} from '@angular/core';
import {LanguageService} from '../services/language.service';
import {Observable} from 'rxjs';

@Pipe({
  name: 'language'
})
export class LanguagePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {}

  // transform(id: number): string {
  //   return this.languageService.languageItems[id].text;
  //   //return null;
  // }

  transform(id: number): Observable<string> {
    return this.languageService.searchDisplayName$(id);
    //return null;
  }

}
