import {Injectable} from '@angular/core';

import {Actions} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from '../../floor-store/reducers';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';


@Injectable()
export class TaskLinkEffects {

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }
}
