import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const SIMULATION = {
  databaseTableName: 'simulation',
  id: 'Si_Id',
  root: 'Si_Root',
  parent: 'Si_Parent',
  projectTaskId: 'Si_ProjectTaskId',
  startDate: 'Si_StartDate',
  endDate: 'Si_EndDate',
  name: 'Si_Name',
  description: 'Si_Description',
  isActive: 'Si_IsActive',
  creationDate: 'Si_CreationDate',
  creationUserId: 'Si_CreationUserId',
  depth: 'Si_Depth'
};

export class Simulation {
  constructor(
    public databaseTableName: string,
    public id: number,
    public root: number,
    public parent: number,
    public projectTaskId: number,
    public startDate: Date,
    public endDate: Date,
    public name: string,
    public description: string,
    public isActive: boolean,
    public creationDate: Date,
    public creationUserId: number,
    public depth: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class SimulationAdapter implements Adapter<Simulation> {
  adapt(item: any): Simulation {
    return new Simulation (
      SIMULATION.databaseTableName,
      item[SIMULATION.id],
      item[SIMULATION.root],
      item[SIMULATION.parent],
      item[SIMULATION.projectTaskId],
      new Date(item[SIMULATION.startDate]),
      new Date(item[SIMULATION.endDate]),
      item[SIMULATION.name],
      item[SIMULATION.description],
      item[SIMULATION.isActive],
      new Date(item[SIMULATION.creationDate]),
      item[SIMULATION.creationUserId],
      item[SIMULATION.depth],
    );
  }
  encode(item: Simulation): ISingleBackendObj {
    const encoded = {
      TableName: item.databaseTableName,
      [SIMULATION.id]: item.id,
      [SIMULATION.root]: item.root,
      [SIMULATION.parent]: item.parent,
      [SIMULATION.projectTaskId]: item.projectTaskId,
      [SIMULATION.startDate]: item.startDate.toDateString(),
      [SIMULATION.endDate]: item.endDate.toDateString(),
      [SIMULATION.name]: item.name,
      [SIMULATION.description]: item.description,
      [SIMULATION.isActive]: item.isActive,
      [SIMULATION.creationDate]: item.creationDate.toDateString(),
      [SIMULATION.creationUserId]: item.creationUserId,
      [SIMULATION.depth]: item.depth,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
