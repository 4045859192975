import {createAction, props} from '@ngrx/store';
import {FloorCatalog} from '../../../domain-models/business/floor-catalog.model';

export const beginGetAllFloorCatalogItems = createAction('[API] - Begin Get All FloorCatalogItems');

export const addFloorCatalogItems = createAction(
  '[API] - Add FloorCatalogItems',
  props<{ floorCatalogItems: FloorCatalog[] }>()
);

export const successGetAllFloorCatalogItems = createAction('[API] - Success Get All FloorCatalogItems');
export const errorGetAllFloorCatalogItems = createAction('[Shared/API] - Error Get All FloorCatalogItems', props<Error>());
