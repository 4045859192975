import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {switchMap, withLatestFrom} from 'rxjs/operators';

import {FloorService} from '../../../floor/services/floor.service';
import {Store} from '@ngrx/store';
import {TaskFloorModelSelectors} from '../selectors';
import {State} from '../reducers';
import {EMPTY, of} from 'rxjs';
import {TaskFloorModelActions, WallActions} from '../actions';
import {FloorModelEnum} from '../../../domain-models/business/floor-model.model';
import {TaskFloorModel} from '../../../domain-models/business-extended/task-floor-model.model';
import {Update} from '@ngrx/entity';

@Injectable()
export class TaskFloorModelEffects {

  /** Delete floorData items by taskIds **/

  removeFloorModelItemsByTaskIds$ = createEffect(
    () => this.actions$.pipe(
      ofType(TaskFloorModelActions.removeTaskFloorModelItemsByTaskIds),
      withLatestFrom(this.store$.select(TaskFloorModelSelectors.selectAllFloorModels)),
      switchMap(([action, floorModels]) => {
        let floorModelIds = [];
        action.taskIds.forEach(id => {
          floorModelIds = [...floorModelIds, ...floorModels.filter(e => e.taskId === id)
            .map(e => `${e.id}-${e.taskId}`)];
        });
        return of(TaskFloorModelActions.removeTaskFloorModelItems({ids: floorModelIds}));
      }),
    )
  );

  updateTaskFloorModelItems$ = createEffect(
    () => this.actions$.pipe(
      ofType(TaskFloorModelActions.updateTaskFloorModelItems),
      switchMap(action => {
        //console.log(action.taskFloorModels);
        const partitioningFrame = action.updates.find(e => Number(String(e.id).split('-')[0]) === FloorModelEnum.PartitioningFrame) as Update<TaskFloorModel>;
        if (partitioningFrame) {
          if (partitioningFrame.changes.isVisible) {
            return of(WallActions.showPartitioningFrame());
          } else {
            return of(WallActions.hidePartitioningFrame());
          }
        } else {
          return EMPTY;
        }
      }),
    )
  );

  updateTaskFloorModel$ = createEffect(
    () => this.actions$.pipe(
      ofType(TaskFloorModelActions.updateTaskFloorModel),
      switchMap(action => {
        const splittedTaskFloorModelId = Number(String(action.update.id).split('-'));
        const floorModelId = splittedTaskFloorModelId[0];
        const taskId = splittedTaskFloorModelId[1];
        if (floorModelId === FloorModelEnum.PartitioningFrame) {
          if (action.update.changes.isVisible) {
            return of(WallActions.showPartitioningFrame());
          } else {
            return of(WallActions.hidePartitioningFrame());
          }
        } else {
          return EMPTY;
        }
      }),
    )
  );

  constructor(
    private actions$: Actions,
    private floorService: FloorService,
    private store$: Store<State>
  ) {
  }
}
