import {createSelector} from '@ngrx/store';
import {selectBuildingsState} from '../reducers';
import * as fromBuildingUi from '../reducers/building-ui.reducer';

export const selectBuildingUi = createSelector(
  selectBuildingsState,
  state => state[fromBuildingUi.buildingUiFeatureKey]
);

export const selectCurrentBuildingRoomLayoutTypeIds = createSelector(
  selectBuildingUi,
  (state) => state.roomLayoutTypeIds
);
export const selectCurrentBuildingActivityStatusTypeIds = createSelector(
  selectBuildingUi,
  (state) => state.activityStatusTypeIds
);
export const selectCurrentBuildingAttributionTypeIds = createSelector(
  selectBuildingUi,
  (state) => state.attributionTypeIds
);
export const selectCurrentBuildingBusinessUnitIds = createSelector(
  selectBuildingUi,
  (state) => state.businessUnitIds
);

// export const selectSelectedTaskId = createSelector(
//   selectBuildingUi,
//   (state) => state.selectedTaskId
// );
