import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {EcSyFile} from '../../domain-models/models/EcSyFile.model';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from './logging.service';
import {API_ENDPOINTS} from '../../shared/api-endpoints';
import {catchError, concatMap, distinctUntilChanged, first, map, shareReplay, tap} from 'rxjs/operators';
import {ApiData} from '../models/api-data.model';
import {Store} from '@ngrx/store';
import {clearState, State} from '../../reducers';
import {CoreActions} from '../../store/core-store/actions';
import {Actions} from '@ngrx/effects';
import {CoreSelectors} from '../../store/core-store/selectors';
import {TicketService} from './ticket.service';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends ApiService {

  public clientFiles: EcSyFile[];
  public clientFiles$: Observable<EcSyFile[]>;
  client$: Observable<EcSyFile>;

  constructor(private api: ApiService,
              private router: Router,
              // private ticketService: TicketService,
              private store: Store<State>,
              private updates$: Actions,
              http: HttpClient,
              loggingService: LoggingService) {
    super(http, loggingService);

    this.client$ = this.store.select(CoreSelectors.selectClient);

    this.clientFiles$ = this.getClients().pipe(
      shareReplay({bufferSize: 1, refCount: true}),
    );

  }

  public selectClient(client: EcSyFile): void {
    this.client$.pipe(
      /** Subscribe to current client and try to release it's ticket and unsubscribe from the stream afterwards **/
      first(),
      // concatMap(currentClient => this.ticketService.releaseClientTicket(currentClient)),
      tap(_ => {
        /** Clear whole State, action from Metareducer **/
        this.store.dispatch(clearState());
        /** Set new Client **/
        this.store.dispatch(CoreActions.setClientFile({client: client, navigateToIndex: true}));
      })
    ).subscribe();
  }

  public getClients(): Observable<EcSyFile[]> {
    return this.http.get<ApiData>(API_ENDPOINTS.ecsyFile)
      .pipe(
        distinctUntilChanged(),
        //tap(_ => this.log('fetched ' + API_ENDPOINTS.ecsyFile)),
        tap(a => console.log(a)),
        map(data => {
          if (data.payload) {
            return data.payload as EcSyFile[];
          } else {
            return null;
          }
        }),
        catchError(this.handleError),
      );
  }
}
