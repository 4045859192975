import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {concatMap, filter, first, map, switchMap, tap} from 'rxjs/operators';
import {API_ENDPOINTS, API_URL} from '../../../shared/api-endpoints';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {GanttTaskAdapter} from '../../../project-management/models/gantt-task.model';
import {TASK, TaskAdapter} from '../../../domain-models/business/task.model';
import {EMPTY, of} from 'rxjs';
import {State} from '../../../reducers';
import {TaskSelectors} from '../selectors';
import {GanttTaskActions, TaskActions, TaskFloorActions} from '../actions';


@Injectable()
export class TaskEffects {

  beginGetTaskById$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.beginGetTaskById),
    switchMap((action) => {
        return this.projectManagementService.get(`${API_ENDPOINTS.dynT}${TASK.databaseTableName}/${action.id}`).pipe(
          switchMap(apiResult => [TaskActions.addTask({task: this.taskAdapter.adapt(apiResult.payload)})]),
        );
      }
    )),
  );

  updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.updateSelectedTaskId),
    switchMap(action => this.store.select(TaskSelectors.selectTaskById(action.taskId)).pipe(
      first(),
      switchMap(task => {
        if (task === undefined && action.taskId !== null) {
          /** If Task is not in store and selectedTaskId is not null**/
          return [
            TaskActions.beginGetTaskById({id: action.taskId}),
            TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
            // RendererActions.resetSelection()
          ];
        } else {
          return [
            TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
            // RendererActions.resetSelection()
          ];
        }
      })
    )),

    ),
  );

  // updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskActions.updateSelectedTaskId),
  //   withLatestFrom(this.store.select(TaskSelectors.selectCurrentTask)),
  //   switchMap(([action, currentTask]) => {
  //     console.log(currentTask);
  //     if (currentTask === undefined) {
  //       return [
  //         TaskActions.beginGetTaskById({id: action.taskId}),
  //         TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
  //         RendererActions.resetSelection()
  //       ];
  //     } else {
  //       return [
  //         TaskFloorActions.updateSelectedTaskFloorId({id: action.taskId}),
  //         RendererActions.resetSelection()
  //       ];
  //     }
  //   })
  //   ),
  // );

  updateTask$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.updateTask),
    //filter(action => !action.ignoreApiCall),
    concatMap((action) => {
        const backendObj = this.taskAdapter.encode(action.update.changes);
        backendObj[TASK.id] = action.update.id;
        if (!action.ignoreApiCall) {
          return this.projectManagementService.patch(`${API_URL}tasks`, backendObj).pipe(
            map(apiResult => {
              const ganttTaskUpdate = this.ganttTaskAdapter.adaptFromTask(action.update.changes);
              return GanttTaskActions.updateGanttTask({update: {id: ganttTaskUpdate.id, changes: ganttTaskUpdate}});
            }),
          );
        } else {
          return EMPTY;
        }
      }
    )),
  );

  deleteTask$ = createEffect(() => this.actions$.pipe(
    ofType(TaskActions.deleteTask),
    switchMap((action) => {
        return this.projectManagementService.delete(`${API_URL}tasks/${action.id}`).pipe(
          concatMap(apiResult => {
            return of(GanttTaskActions.deleteGanttTask({id: action.id}));
          }),
        );
      }
    )),
  );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private ganttTaskAdapter: GanttTaskAdapter,
    private taskAdapter: TaskAdapter,
    private store: Store<State>
  ) {
  }
}
