import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {TaskBusinessTypeActions} from '../actions';
import {TaskBusinessType} from '../../../domain-models/business/task-business-type.model';

export const taskBusinessTypeFeatureKey = 'taskBusinessTypes';

export interface State extends EntityState<TaskBusinessType> {
  selectedTaskBusinessTypeId: number | null;
}

export const adapter: EntityAdapter<TaskBusinessType> = createEntityAdapter<TaskBusinessType>();

export const initialState: State = adapter.getInitialState({
  selectedTaskBusinessTypeId: null,
});

export const reducer = createReducer(
  initialState,
  /** CRUD **/
  on(TaskBusinessTypeActions.addTaskBusinessType, (state, { taskBusinessType }) => {
    return adapter.addOne(taskBusinessType, state);
  }),
  on(TaskBusinessTypeActions.setTaskBusinessType, (state, { taskBusinessType }) => {
    return adapter.setOne(taskBusinessType, state);
  }),
  on(TaskBusinessTypeActions.upsertTaskBusinessType, (state, { taskBusinessType }) => {
    return adapter.upsertOne(taskBusinessType, state);
  }),
  on(TaskBusinessTypeActions.addTaskBusinessTypes, (state, { taskBusinessTypes }) => {
    return adapter.addMany(taskBusinessTypes, state);
  }),
  on(TaskBusinessTypeActions.upsertTaskBusinessTypes, (state, { taskBusinessTypes }) => {
    return adapter.upsertMany(taskBusinessTypes, state);
  }),
  on(TaskBusinessTypeActions.updateTaskBusinessType, (state, { update }) => {
    return adapter.updateOne(update, state);
  }),
  on(TaskBusinessTypeActions.updateTaskBusinessTypes, (state, { updates }) => {
    return adapter.updateMany(updates, state);
  }),
  on(TaskBusinessTypeActions.mapTaskBusinessTypes, (state, { entityMap }) => {
    return adapter.map(entityMap, state);
  }),
  on(TaskBusinessTypeActions.deleteTaskBusinessType, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),
  on(TaskBusinessTypeActions.deleteTaskBusinessTypes, (state, { ids }) => {
    return adapter.removeMany(ids, state);
  }),
  on(TaskBusinessTypeActions.deleteTaskBusinessTypesByPredicate, (state, { predicate }) => {
    return adapter.removeMany(predicate, state);
  }),
  on(TaskBusinessTypeActions.loadTaskBusinessTypes, (state, { taskBusinessTypes }) => {
    return adapter.setAll(taskBusinessTypes, state);
  }),
  on(TaskBusinessTypeActions.clearTaskBusinessTypes, state => {
    return adapter.removeAll({ ...state, selectedTaskBusinessTypeId: null });
  }),
  /** END OF CRUD **/
  on(TaskBusinessTypeActions.updateSelectedTaskBusinessTypeId, (state, {taskBusinessTypeId}) => {
    return ({...state, selectedTaskBusinessTypeId: taskBusinessTypeId});
  }),
);

export const getSelectedTaskBusinessTypeId = (state: State) => state.selectedTaskBusinessTypeId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of taskBusinessType ids
export const selectTaskBusinessTypeIds = selectIds;

// select the dictionary of taskBusinessType entities
export const selectTaskBusinessTypeEntities = selectEntities;

// select the array of taskBusinessTypes
export const selectAllTaskBusinessTypes = selectAll;

// select the total taskBusinessType count
export const selectTaskBusinessTypeTotal = selectTotal;
