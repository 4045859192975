import {createReducer, on} from '@ngrx/store';
import {UiContextActions} from '../actions';
import {CreateRoomFormProps} from '../../../floor/models/room-creation-tool-props.model';
import {arrayMove} from '../../../shared/utils';
import {CreateWallProps} from '../../../floor/models/create-wall-props.model';
import {CreateDoorProps} from '../../../floor/models/create-door-props.model';
import {CreateEquipmentProps} from '../../../floor/models/create-equipment-props.model';

export const uiContextFeatureKey = 'uiContext';

export interface State {
  /** Floor Task related **/
  floorPerimeterIds: number[];
  currentTaskRoomLayoutTypeIds: number[];
  currentTaskActivityStatusTypeIds: number[];
  currentTaskAttributionTypeIds: number[];
  currentTaskBusinessUnitIds: number[];
  displayedTasksIds: number[];
  displayedTasksVisibility: number[];

  displayContext: number;

  toolbarTitle: string | null;
  leftSidenavMainTabLabel: string | null;

  isCreatingRoom: boolean | null;
  isRecalculatingRoomArea: boolean;
  isCreatingWall: boolean | null;
  isSelectingContour: boolean | null;
  roomEditionMode: boolean | null;
  /** Study mode **/
  studyMode: boolean | null;
  isAddingFloorDataToTask: boolean | null;
  equipmentCreationMode: boolean | null;
  isCreatingEquipment: boolean | null;
  isCreatingDoor: boolean | null;
  selectedFloorCatalogId: number | null;
  currentFloorModelEditing: number;
  table: { label: string; endpoint: string; primaryFilterId: number; usageContextId: number };
  roomFormProps: CreateRoomFormProps;
  createWallProps: CreateWallProps;
  createDoorProps: CreateDoorProps;
  createEquipmentProps: CreateEquipmentProps;
}

const initialState: State = {
  floorPerimeterIds: [],
  currentTaskRoomLayoutTypeIds: [],
  currentTaskActivityStatusTypeIds: [],
  currentTaskAttributionTypeIds: [],
  currentTaskBusinessUnitIds: [],
  displayedTasksIds: [],
  displayedTasksVisibility: [],
  displayContext: 1,
  studyMode: null,
  isAddingFloorDataToTask: null,
  toolbarTitle: null,
  leftSidenavMainTabLabel: null,
  isCreatingRoom: false,
  isRecalculatingRoomArea: false,
  isCreatingWall: false,
  isSelectingContour: false,
  roomEditionMode: null,
  table: null,
  roomFormProps: null,
  createWallProps: null,
  createDoorProps: null,
  isCreatingDoor: false,
  createEquipmentProps: null,
  equipmentCreationMode: null,
  isCreatingEquipment: false,
  selectedFloorCatalogId: null,
  currentFloorModelEditing: null,
};

export const reducer = createReducer(
  initialState,
  on(UiContextActions.enableStudyMode, (state) => {
    return {...state, studyMode: true};
  }),
  on(UiContextActions.disableStudyMode, (state) => {
    return {...state, studyMode: false};
  }),
  on(UiContextActions.toggleIsCreatingRoom, (state) => {
    return {...state, isCreatingRoom: !state.isCreatingRoom};
  }),
  on(UiContextActions.toggleIsRecalculatingArea, (state) => {
    return {...state, isRecalculatingRoomArea: !state.isRecalculatingRoomArea};
  }),
  on(UiContextActions.cancelRoomRecalculateArea, (state) => {
    return {...state, isRecalculatingRoomArea: false, isSelectingContour: false};
  }),
  on(UiContextActions.beginRoomCreation, (state) => {
    return {...state, isCreatingRoom: true};
  }),
  on(UiContextActions.cancelRoomCreation, (state) => {
    return {...state, isCreatingRoom: false, isSelectingContour: false, roomFormProps: null};
  }),
  on(UiContextActions.beginWallCreation, (state, {props}) => {
    return {...state, isCreatingWall: true, createWallProps: props};
  }),
  on(UiContextActions.cancelWallCreation, (state) => {
    return {...state, isCreatingWall: false, createWallProps: null};
  }),
  on(UiContextActions.beginDoorCreation, (state, {props}) => {
    return {...state, isCreatingDoor: true, createDoorProps: props};
  }),
  on(UiContextActions.cancelDoorCreation, (state) => {
    return {...state, isCreatingDoor: false, createDoorProps: null};
  }),
  on(UiContextActions.beginEquipmentCreation, (state, {props}) => {
    return {...state, isCreatingEquipment: true, createEquipmentProps: props};
  }),
  on(UiContextActions.cancelEquipmentCreation, (state) => {
    return {...state, isCreatingEquipment: false, createEquipmentProps: null};
  }),
  // on(UiContextActions.setEquipmentCreationMode, (state, {creation}) => {
  //   return {...state, equipmentCreationMode: creation};
  // }),
  // on(UiContextActions.isCreatingEquipment, (state, {creating}) => {
  //   return {...state, isCreatingEquipment: creating};
  //}),
  on(UiContextActions.setSelectedFloorCatalogId, (state, {id}) => {
    return {...state, selectedFloorCatalogId: id};
  }),
  on(UiContextActions.setAddFloorDataToTaskMode, (state, {isAdding}) => {
    return {...state, isAddingFloorDataToTask: isAdding};
  }),
  on(UiContextActions.setCreateRoomFormProps, (state, roomFormProps) => {
    return {...state, roomFormProps: roomFormProps};
  }),
  on(UiContextActions.setRoomIsSelectable, (state, {selectable}) => {
    return {...state, roomIsSelectable: selectable};
  }),
  on(UiContextActions.clearCreateRoomFormProps, (state) => {
    return {...state, roomFormProps: null};
  }),
  on(UiContextActions.setToolbarTitle, (state, {title}) => {
    return {...state, toolbarTitle: title};
  }),
  on(UiContextActions.setLeftSidenavMainTabLabel, (state, {label}) => {
    return {...state, leftSidenavMainTabLabel: label};
  }),
  on(UiContextActions.isSelectingContour, (state, {selecting}) => {
    return {...state, isSelectingContour: selecting};
  }),
  on(UiContextActions.setFloorDisplayContext, (state, {id}) => {
    return {...state, displayContext: id};
  }),
  on(UiContextActions.setTable, (state, {label, endpoint, primaryFilterId, usageContextId}) => {
    return {...state, table: {label: label, endpoint: endpoint, primaryFilterId: primaryFilterId, usageContextId: usageContextId}};
  }),
  on(UiContextActions.setFloorPerimetersIds, (state, {ids}) => {
    return {...state, floorPerimeterIds: ids};
  }),
  on(UiContextActions.setCurrentTaskRoomLayoutTypeIds, (state, {ids}) => {
    return {...state, currentTaskRoomLayoutTypeIds: ids};
  }),
  on(UiContextActions.setCurrentTaskActivityStatusTypeIds, (state, {ids}) => {
    return {...state, currentTaskActivityStatusTypeIds: ids};
  }),
  on(UiContextActions.setCurrentTaskAttributionTypeIds, (state, {ids}) => {
    return {...state, currentTaskAttributionTypeIds: ids};
  }),
  on(UiContextActions.setCurrentTaskBusinessUnitIds, (state, {ids}) => {
    return {...state, currentTaskBusinessUnitIds: ids};
  }),
  on(UiContextActions.addDisplayedTask, (state, {id}) => {
    const idAlreadyExists = state.displayedTasksIds.indexOf(id) > -1;
    // make a copy of the existing array
    const displayedTasksIds = [...state.displayedTasksIds];
    if (idAlreadyExists) {
      const currentIdIndex = displayedTasksIds.indexOf(id);
      /** Sort array to make sure "newly added" id is at the end **/
      arrayMove(displayedTasksIds, currentIdIndex, displayedTasksIds.length);
    } else {
      // modify the COPY, not the original
      displayedTasksIds.push(id);
    }
    return {
      // "spread" the original state object
      ...state,
      // but replace the "displayedTasksIds" field
      displayedTasksIds
    };
  }),
  on(UiContextActions.addDisplayedTaskVisibility, (state, {id}) => {
    const idAlreadyExists = state.displayedTasksVisibility.indexOf(id) > -1;
    // make a copy of the existing array
    const displayedTasksVisibility = [...state.displayedTasksVisibility];
    if (idAlreadyExists) {
      const currentIdIndex = displayedTasksVisibility.indexOf(id);
      /** Sort array to make sure "newly added" id is at the end **/
      //arrayMove(displayedTasksVisibility, currentIdIndex, displayedTasksVisibility.length);
    } else {
      // modify the COPY, not the original
      displayedTasksVisibility.push(id);
    }
    return {
      // "spread" the original state object
      ...state,
      // but replace the "displayedTasksIds" field
      displayedTasksVisibility
    };
  }),
  on(UiContextActions.setDisplayedTaskVisibility, (state, {ids}) => {
    return {...state, displayedTasksVisibility: ids};
  }),
  on(UiContextActions.toggleDisplayedTaskVisibility, (state, {id}) => {
    const idAlreadyExists = state.displayedTasksVisibility.indexOf(id) > -1;
    // make a copy of the existing array
    const displayedTasksVisibility = [...state.displayedTasksVisibility];
    if (idAlreadyExists) {
      const currentIdIndex = displayedTasksVisibility.indexOf(id);
      /** Delete id **/
      displayedTasksVisibility.splice(currentIdIndex, 1);
    } else {
      /** push id **/
      displayedTasksVisibility.push(id);
    }
    return {
      // "spread" the original state object
      ...state,
      // but replace the "displayedTasksVisibility" field
      displayedTasksVisibility
    };
  }),
  on(UiContextActions.setDisplayedTasks, (state, {ids}) => {
    return {...state, displayedTasksIds: ids};
  }),
  on(UiContextActions.clearDisplayedTasks, (state) => {
    return {...state, displayedTasksIds: []};
  }),
  on(UiContextActions.clearFloorUiContextState, (state) => {
    return initialState;
  }),
  on(UiContextActions.isEditingFloorModel, (state, {id}) => {
    return {...state, currentFloorModelEditing: id};
  }),
);

// export function reducer(state: State | undefined, action: Action) {
//   return reducer(state, action);
// }
