import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {State} from '../../reducers';
import {Observable} from 'rxjs';
import {LanguageItem} from '../../domain-models/core/z-language-item.model';
import {selectLanguageItemsEntities} from '../../store/shared-store/selectors/language-item.selectors';
import {Dictionary} from '@ngrx/entity';
import {filter, map} from 'rxjs/operators';
import {LanguageItemSelectors} from '../../store/shared-store/selectors';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languageItems$: Observable<Dictionary<LanguageItem>>;
  languageItems: Dictionary<LanguageItem>;
  allLanguageItemsLoaded$: Observable<boolean>;

  constructor(private store: Store<State>) {
    this.languageItems$ = this.store.select(selectLanguageItemsEntities);
    this.languageItems$.subscribe(items => this.languageItems = items);
  }

  searchDisplayName(id: number): string {
    //console.log(this.languageItems[id].text);
    if (this.languageItems[id]) {
      return this.languageItems[id].text;
    } else {
      return null;
    }
  }

  searchDisplayNameIdInItemKeys(item: any): number | undefined {
    for (const key in item) {
      if (key.includes('DisplayNameId')) {
        return item[key];
      }
    }
  }

  searchDisplayNameInItem(item: any): string {
    const displayNameId = this.searchDisplayNameIdInItemKeys(item);
    if (displayNameId) {
      return this.searchDisplayName(displayNameId);
    } else {
      return null;
    }
  }

  sortAlphabeticalyByDisplayNameIds(ids: number[]): number[] {
    const displayNames: {id: number, name: string}[] = [];
    ids.forEach(id => displayNames.push({id: id, name: this.languageItems[id].text}));
    displayNames.sort((a, b) => a.name.localeCompare(b.name));
    console.log(displayNames);
    return displayNames.map(d => d.id);
  }

  searchDisplayName$(id: number): Observable<string> {
    return this.store.pipe(
      select(LanguageItemSelectors.selectLanguageItemByDisplayNameId(id)),
      filter(x => x !== undefined),
      map(item => item.text)
    );
  }
}
