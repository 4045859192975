<div class="layout-container" [class.layout-is-mobile]="mobileQuery.matches">
  <echo-header class="app-toolbar"></echo-header>
  <mat-sidenav-container class="layout-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">

    <mat-sidenav #leftSidenav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <echo-mobile-menu></echo-mobile-menu>
    </mat-sidenav>

    <mat-sidenav #rightSidenav [position]="('end')" mode="over" (closedStart)="closeRightDrawer()"
                 class="sidenav">
      <echo-user-panel></echo-user-panel>
      <router-outlet name="right-sidenav"></router-outlet>
    </mat-sidenav>

    <mat-sidenav-content class="layout-sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<echo-loading-progress-bar></echo-loading-progress-bar>
