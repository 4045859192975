import {createAction, props} from '@ngrx/store';
import {EcSyFile} from '../../../domain-models/models/EcSyFile.model';
import {User} from '../../../domain-models/business-extended/user.model';
import {AppUser} from '../../../domain-models/business/app-user.model';

/**
 * Client
 */

export const checkClientFiles = createAction('[Core] - Check Client Files');
export const releaseTicket = createAction('[Core] - Release Client Ticket');
export const setClientFile = createAction(
  '[Core] - Set Client File',
  props<{ client: EcSyFile, navigateToIndex?: boolean }>());

/**
 * User
 **/
export const setUser = createAction('[Core] - Set User', props<{ user: User }>());
export const setAppUser = createAction('[Core] - Set AppUser', props<{ appUser: AppUser }>());
