import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import * as FloorWallStyleActions from '../actions/floor-wall-style.actions';
import {FloorWallStyle} from '../../../domain-models/business/floor-wall-style.model';

export const floorWallStylesFeatureKey = 'floorWallStyles';

export interface State extends EntityState<FloorWallStyle> {
  isLoaded: boolean;
}

export const adapter: EntityAdapter<FloorWallStyle> = createEntityAdapter<FloorWallStyle>({});

export const initialState: State = adapter.getInitialState({
  isLoaded: false
});

export const reducer = createReducer(
  initialState,
  on(FloorWallStyleActions.addFloorWallStyles, (state, {floorWallStyles}) => {
    return adapter.addMany(floorWallStyles, state);
  }),
  on(FloorWallStyleActions.successGetAllFloorWallStyles,  state => ({ ...state, isLoaded: true }))
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorWallStylesIds = selectIds;
export const selectFloorWallStylesEntities = selectEntities;
export const selectAllFloorWallStyles = selectAll;
export const selectFloorWallStylesTotal = selectTotal;
