import {createSelector} from '@ngrx/store';
import * as fromWorkplace from '../reducers/workplace.reducer';
import {selectFloorsState} from '../reducers';
import {State} from '../reducers/room.reducer';

/** Select store slice **/

export const selectWorkplaces = createSelector(
  selectFloorsState,
  state => state[fromWorkplace.workplaceFeatureKey]
);

/** Basics selectors **/

export const selectWorkplaceIds = createSelector(
  selectWorkplaces,
  fromWorkplace.selectWorkplaceIds // shorthand for workplacesState => fromWorkplace.selectWorkplaceIds(workplacesState)
);
export const selectWorkplaceEntities = createSelector(
  selectWorkplaces,
  fromWorkplace.selectWorkplaceEntities
);
export const selectAllWorkplaces = createSelector(
  selectWorkplaces,
  fromWorkplace.selectAllWorkplaces
);
export const selectWorkplaceTotal = createSelector(
  selectWorkplaces,
  fromWorkplace.selectWorkplaceTotal
);
// export const selectCurrentWorkplaceId = createSelector(
//   selectWorkplaces,
//   fromWorkplace.getSelectedWorkplaceId
// );

// export const selectCurrentWorkplace = createSelector(
//   selectWorkplaceEntities,
//   selectCurrentWorkplaceId,
//   (workplaceEntities, workplaceId) => workplaceEntities[workplaceId]
// );

/** Advanced selectors **/

export const selectWorkplacesByFloorDataId = (id: number) => createSelector(
  selectAllWorkplaces,
  (workplaces) => workplaces.filter(w => w.floorDataId === id)
);

export const getSelectedWorkplaceIds = createSelector(
  selectWorkplaces,
  (state: State) => state.selectedIds
);

export const getSelectedWorkplaces = createSelector(
  selectAllWorkplaces,
  getSelectedWorkplaceIds,
  (workplaces, ids) =>
    workplaces.filter(w => w.floorDataId === ids.find(e => e === w.floorDataId))
);
