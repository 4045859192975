import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FLOOR} from '../../domain-models/business/floor.model';
import {ROOM_LAYOUT_TYPE} from '../../domain-models/business/room-layout-type.model';
import {ROOM} from '../../domain-models/business/room.model';
import {BUSINESS_UNIT} from '../../domain-models/business/business-unit.model';
import {AppService} from '../../core/services/app.service';

@Component({
  selector: 'echo-svg-viewer',
  templateUrl: './svg-viewer.component.html',
  styleUrls: ['./svg-viewer.component.scss']
})
export class SvgViewerComponent implements OnInit {

  FLOOR = FLOOR;
  ROOM_USE = ROOM_LAYOUT_TYPE;
  ROOM = ROOM;
  BUSINESS_UNIT = BUSINESS_UNIT;

  @Input('floor') floorDetail: any;
  @Input('roomOpacity') roomOpacity: boolean;
  @Input('displayedUsages') displayedUsages: string[];
  @Input('displayedBuns') displayedBuns: string[];
  @Input('colorMode') colorMode: number; //1 usage , 2 allocation
  @Input('viewBox') viewBox: { x: number, y: number, width: number, height: number };
  @Output('ratio') sizeRatio$ = new EventEmitter<boolean>();
  sizeRatio: boolean;
  gradientColor = '#F08080';
  inWorkRoomAllocationBuId;

  constructor(private appService: AppService) {
  }

  ngOnInit() {
    this.sizeRatio = this.calcRatio(this.floorDetail.Width, this.floorDetail.Height);
    this.appService.appParams$.subscribe(params => {
      this.inWorkRoomAllocationBuId = params['InWorkRoomAllocationBuId'];
    });
  }

  calcRatio(first: number, second: number): boolean {
    if (first > second) {
      this.sizeRatio$.emit(true);
      return true;
    } else {
      this.sizeRatio$.emit(false);
      return false;
    }
  }

}
