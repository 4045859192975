import {Injectable, Injector, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SvgViewBox} from './models/svg-view-box.model';
import {HighlightedFloorData} from './models/highlighted-floor-data.model';
import {SvgRendererComponent} from './components/core/svg-renderer/svg-renderer.component';

@Injectable({
  providedIn: 'root'
})
export class SvgRendererService {

  rendererViewContainerRef: ViewContainerRef;

  currentViewBox$ = new BehaviorSubject<SvgViewBox>(null);

  isDraggingFloorDataSubject$ = new Subject<number>();

  private hoveredFloorDataSubject$ = new Subject<{id: number, floorModelId: number, isUse?: boolean}>();
  private highlightedFloorDataSubject$ = new BehaviorSubject<HighlightedFloorData>({
    deleted: false,
    added: false,
    updated: false
  });
  private toggleGrayscaleSubject$ = new BehaviorSubject<boolean>(false);

  highlightedFloorData$ = this.highlightedFloorDataSubject$.asObservable();
  toggleGrayscale$ = this.toggleGrayscaleSubject$.asObservable();
  hoveredFloorData$ = this.hoveredFloorDataSubject$.asObservable();

  destroyContextMenu$ = new Subject<void>();
  detectChanges$ = new Subject<void>();

  isCreatingSecondWallSegmentPoint$ = new Subject<boolean>();
  rightClickFloorDataId$ = new BehaviorSubject<number>(null);
  drawFloorDataContours$ = new BehaviorSubject<number[]>([]);



  constructor() { }

  hoverFloorData(id: number, floorModelId: number, isUse?: boolean) {
    this.hoveredFloorDataSubject$.next({id: id, floorModelId: floorModelId, isUse: isUse});
  }

  hightlightFloorData(floorDataStateId: HighlightedFloorData) {
    this.highlightedFloorDataSubject$.next(floorDataStateId);
  }

  toggleGrayscale(toggleGrayscale: boolean): void {
    this.toggleGrayscaleSubject$.next(toggleGrayscale);
  }

  /** Used to manually trigger change detection of SvgRendererComponent **/
  detectChanges() {
    this.detectChanges$.next();
  }
}
