import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {SvgGroup} from '../../../svg-factory/models/svg-group.model';
import {FloorCatalogActions} from '../actions';

export const floorCatalogFeatureKey = 'floorCatalog';

export interface State extends EntityState<SvgGroup> {
  // additional entities state properties
  // selectedFloorId: number | null;
  // selectedDocumentVersionId: number | null;
}

export const adapter: EntityAdapter<SvgGroup> = createEntityAdapter<SvgGroup>();

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(FloorCatalogActions.addFloorCatalogItems, (state, {svgGroups}) => {
    return adapter.addMany(svgGroups, state);
  }),
  on(FloorCatalogActions.addFloorCatalog, (state, {svgGroup}) => {
    return adapter.addOne(svgGroup, state);
  }),
  on(FloorCatalogActions.updateFloorCatalog, (state, {svgGroup}) => {
    return adapter.updateOne(svgGroup, state);
  }),
  on(FloorCatalogActions.updateFloorCatalogItems, (state, {svgGroups}) => {
    return adapter.updateMany(svgGroups, state);
  }),
  on(FloorCatalogActions.upsertFloorCatalog, (state, {svgGroup}) => {
    return adapter.upsertOne(svgGroup, state);
  }),
  on(FloorCatalogActions.upsertFloorCatalogItems, (state, {svgGroups}) => {
    return adapter.upsertMany(svgGroups, state);
  }),
  on(FloorCatalogActions.removeFloorCatalogItem, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(FloorCatalogActions.removeFloorCatalogItems, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(FloorCatalogActions.clearFloorCatalogItems, state => {
    return adapter.removeAll({...state});
  }),
);


// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectFloorCatalogIds = selectIds;
export const selectFloorCatalogEntities = selectEntities;
export const selectAllFloorCatalogs = selectAll;
export const selectFloorCatalogTotal = selectTotal;
