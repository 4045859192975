import {Injectable} from '@angular/core';

import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {State} from '../../floor-store/reducers';
import {ProjectManagementService} from '../../../project-management/services/project-management.service';
import {exhaustMap, map} from 'rxjs/operators';
import {TaskContributorDirectoryActions} from '../actions';


@Injectable()
export class TaskContributorDirectoryEffects {

  // updateSelectedTaskId$ = createEffect(() => this.actions$.pipe(
  //   ofType(TaskActions.updateSelectedTaskId),
  //   )
  // );

  constructor(
    private actions$: Actions,
    private projectManagementService: ProjectManagementService,
    private store: Store<State>
  ) {
  }

  beginGetAllTaskContributorDirectory$ = createEffect(() => this.actions$.pipe(
    ofType(TaskContributorDirectoryActions.beginGetAllTaskContributorDirectoryItems),
    exhaustMap((action) =>
      this.projectManagementService.getAllTaskContributorDirectory().pipe(
        map(taskContributorDirectory =>
          TaskContributorDirectoryActions.loadTaskContributorDirectoryItems({taskContributorDirectoryItems: taskContributorDirectory })
        ),
    )
  )));
}
