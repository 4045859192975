interface IColor {
  new(): Color;

  new(value: number | string): Color;

  new(r: number, g: number, b?: number, a?: number): Color;

  (): string;
}

export class Color {
  // public r?: number;
  // public g?: number;
  // public b?: number;
  // public a?: number;
  constructor(
    public r?: number,
    public g?: number,
    public b?: number,
    public a?: number
  ) {
  }

  // loadFromHexARGB(colorString: string): void {
  //   colorString = colorString.substr(colorString.indexOf('#') + 1);
  //   this.a = parseInt(colorString.substr(0, 2), 16);
  //   this.r = parseInt(colorString.substr(2, 2), 16);
  //   this.g = parseInt(colorString.substr(4, 2), 16);
  //   this.b = parseInt(colorString.substr(6, 2), 16);
  // }


  toHexRgb() {
    return '#' + this.r.toString(16).padStart(2, '0') + this.g.toString(16).padStart(2, '0') + this.b.toString(16).padStart(2, '0');
  }

  toHexArgb() {
    //console.log((Math.round(this.a * 255)).toString(16));
    return '#' + (Math.round(this.a * 255)).toString(16).padStart(2, '0') + this.r.toString(16).padStart(2, '0') + this.g.toString(16).padStart(2, '0') + this.b.toString(16).padStart(2, '0');
  }

  toHexRgba() {
    return '#' + this.r.toString(16).padStart(2, '0') + this.g.toString(16).padStart(2, '0') + this.b.toString(16).padStart(2, '0') + (Math.round(this.a * 255)).toString(16).padStart(2, '0');
  }

  toRgb() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }

  toRgba() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a * 255})`;
  }

  toCssOpacity() {
    return this.r / 255;
  }
}

export class ColorUtils {
  static colorFromHexARGB(s: string): Color | null {
    if (s) {
      let a, r, g, b;
      s = s.substr(s.indexOf('#') + 1);
      a = parseInt(s.substr(0, 2), 16);
      r = parseInt(s.substr(2, 2), 16);
      g = parseInt(s.substr(4, 2), 16);
      b = parseInt(s.substr(6, 2), 16);
      return new Color(r, g, b, a);
    } else {
      return null;
    }
  }

  static colorFromHexRGBA(s: string): Color {
    let r, g, b, a;
    s = s.substr(s.indexOf('#') + 1);
    r = parseInt(s.substr(0, 2), 16);
    g = parseInt(s.substr(2, 2), 16);
    b = parseInt(s.substr(4, 2), 16);
    a = parseInt(s.substr(6, 2), 16);
    return new Color(r, g, b, a);
  }

  // static alphaDecimalToHex(opacity: number): string {
  //   const alpha = '';
  //   for (let i = 1; i >= 0; i -= 0.01) {
  //     i = Math.round(i * 100) / 100.0;
  //     const alpha = Math.round(i * 255);
  //     const hex = alpha.toString(16).toUpperCase();
  //     if (hex.length === 1) {
  //       hex = '0' + hex;
  //     }
  //     const percent = i * 100;
  //     //System.out.println(String.format("%d%% — %s", percent, hex));
  //   }
  // }
}
