import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {API_ENDPOINTS, API_URL} from '../../shared/api-endpoints';
import {map} from 'rxjs/operators';
import {ApiData} from '../../core/models/api-data.model';
import {Building, BuildingAdapter} from '../../domain-models/business/building.model';
import {HttpClient} from '@angular/common/http';
import {LoggingService} from '../../core/services/logging.service';
import {ApiService} from '../../core/services/api.service';
import {ApiBuildingsAreaIds} from '../../domain-models/endpoint-specific/buildings.areaids.model';
import {
  ApiBuildingsFloors,
  ApiBuildingsFloorsAdapter
} from '../../domain-models/endpoint-specific/buildings-floors.model';
import {RoomAllocation, RoomAllocationAdapter} from '../../domain-models/business/room-allocation.model';

@Injectable({
  providedIn: 'root'
})
export class BuildingService extends ApiService {

  constructor(http: HttpClient,
              loggingService: LoggingService,
              private apiBuildingFloorsAdapter: ApiBuildingsFloorsAdapter,
              private roomAllocationAdapter: RoomAllocationAdapter,
              private buildingAdapter: BuildingAdapter) {
    super(http, loggingService);
  }

  getAllBuildings(): Observable<Building[]> {
    return this.get(API_ENDPOINTS.dynT + `building`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.buildingAdapter.adapt(item));
        })
      );
  }

  getRoomAllocationsByBuildingId(buildingId: number): Observable<RoomAllocation[]> {
    return this.get(`${API_URL}buildings/${buildingId}/roomallocations`)
      .pipe(
        map((data: ApiData) => {
          return data.payload.map((item) => this.roomAllocationAdapter.adapt(item));
        })
      );
  }

  getBuildingAreaIds(buildingId: number): Observable<ApiBuildingsAreaIds> {
    return this.get(API_URL + `buildings/${buildingId}/areaids`)
      .pipe(
        map((data: ApiData) => {
          return data.payload as ApiBuildingsAreaIds;
        })
      );
  }

  getBuildingFloors(buildingId: number): Observable<ApiBuildingsFloors> {
    return this.get(API_URL + `buildings/${buildingId}/floors`)
      .pipe(
        map((data: ApiData) => {
          return this.apiBuildingFloorsAdapter.adapt(data.payload);
        })
      );
  }
}
