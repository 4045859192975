import {createAction, props} from '@ngrx/store';
import {SvgViewBox} from '../../../svg-factory/models/svg-view-box.model';
import {RendererSelection} from '../reducers/renderer.reducer';
import {RoomContourProps} from '../../../floor/models/room-contour-props.model';

export const addBlueprintExtentsLayerId = createAction(
  '[Floor/API] - Add BlueprintExtentsLayerId Metadata',
  props<{ blueprintExtentsLayerId: number }>()
);

export const lastClickedSVGPoint = createAction(
  '[SVG Renderer] - Last Clicked P coordinates',
  props<{ x: number, y: number }>()
);

export const togglePointerEvents = createAction('[SVG Renderer] - Toggle pointerEvents');

export const toggleBlueprint = createAction('[SVG Renderer] - Toggle blueprint mode');
export const setBlueprint = createAction('[SVG Renderer] - Toggle blueprint mode', props<{ blueprint: boolean }>());

/**
 * ViewBox
 */

export const setViewBox = createAction(
  '[SvgRenderer] - Set viewBox',
  props<{ taskId: number, viewBox: string }>()
);

export const createViewboxFromApi = createAction(
  '[Floor/API] - Create viewBox',
  props<{ taskId: number, viewBox: string }>()
);

export const updateViewbox = createAction(
  '[SVG Renderer] - Update viewBox',
  props<{ taskId: number, viewBox: string }>()
);

export const successCreateViewbox = createAction(
  '[Floor/API] - Sucess Create viewBox',
  props<SvgViewBox>()
);

// export const updateViewbox = createAction(
//   '[SVG Renderer] - Update viewBox',
//   props<SvgViewBox>()
// );


export const resetViewbox = createAction('[FloorComponent] - Reset viewBox');

/**
 * RoomContour
 */

export const beginGetRoomContour = createAction(
  '[Floor/API] - Begin Get Room Contour',
  props<{ taskId: number, x: number, y: number, isUpdate?: boolean }>()
);

export const successGetRoomContour = createAction(
  '[Floor/API] - Sucess Get Room Contour',
  props<{ props: RoomContourProps }>()
);

export const errorGetRoomContour = createAction('[API] - Error Get Room Contour', props<Error>());

export const clearRoomContour = createAction(
  '[FloorRoomFormComponent] - Clear Room Contour',
);

export const clearRenderer = createAction('[Floor] - Clear Renderer');

export const updateActiveFloorModel = createAction(
  '[Renderer] - Update active FloorModel id',
  props<{ id: number }>()
);

export const isSelectingMultipleItems = createAction(
  '[Renderer] - Set multiple items selection',
  props<{ multipleSelection: boolean }>()
);

export const setSelection = createAction(
  '[Renderer] - Set selection',
  props<{ selection: RendererSelection }>()
);

export const resetSelection = createAction('[Renderer] - Reset selection');
