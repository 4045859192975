import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SnackBarService} from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  public message = new Subject();

  constructor(public snackBarService: SnackBarService) {
  }

  public displayError(message: string, alert?: boolean) {
    this.snackBarService.message$.next({
      message: message,
      action: 'Ok',
      alert: alert,
    });
  }

  public displayMessage(message: string) {
    this.snackBarService.message$.next({
      message: message,
      action: 'Ok',
      alert: false,
    });
  }
}
