<div class="wrapper">
  <mat-form-field>
    <mat-select
      (ngModelChange)="selectNewTheme($event)"
      [(ngModel)]="displayThemeId">
      <mat-option *ngFor="let displayTheme of (displayThemes$ | async)"
                  [value]="displayTheme.id">{{displayTheme.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <!--{{displayThemeId}}-->
  <mat-nav-list *ngIf="displayThemeItems$ | async"
                (mouseleave)="toggleHoveredItem(null)"
  >
    <ng-container *ngFor="let item of (displayThemeItems$ | async)">


    <mat-list-item *ngIf="idsToDisplay.includes(item.resId)"
                   (mouseenter)="toggleHoveredItem(item)"
                   (click)="toggleItemVisibility(item)">
      <div
        [ngClass]="{'color-cell': item.active, 'color-cell-stroke': !item.active, 'stripes': item.svgPatternId === 1 }"
        [style.background-color]="item.color?.toHexRgb()" [style.border-color]="item.color?.toHexRgb()"></div>
      <h3 matLine> {{item.name}} </h3>
      <p matLine>
        <span> Total </span>
<!--        <span> &#45;&#45; 42 m<sup>2</sup> </span>&lt;!&ndash;{{item.area | number:'1.1-2'}}&ndash;&gt;-->
        <span> -- {{(item.resId | roomArea: selectedTaskId: selectedBuildingId | async) | number:'1.1-2'}} m<sup>2</sup> </span>
      </p>
    </mat-list-item>
    </ng-container>
  </mat-nav-list>
</div>


<!--<mat-nav-list>-->
<!--  <h3 mat-subheader *ngIf="label">{{displayTheme.label}}</h3>-->
<!--  <mat-list-item (click)="item.active = !item.active; legendItemsOut.emit(displayTheme.displayThemeItems)"-->
<!--                 (mouseenter)="overLegendItem.emit(item)"-->
<!--                 (mouseleave)="overLegendItem.emit(null)"-->
<!--                 *ngFor="let item of displayTheme.displayThemeItems | orderBy: 'name' :true"-->
<!--  >-->
<!--    <div-->
<!--      [ngClass]="{'color-cell': item.active || !clickable, 'color-cell-stroke': !item.active && clickable,-->
<!--      'stripes': item.displayThemeSvgPattern !== 1 }"-->
<!--      [style.background-color]="item.hexColor" [style.border-color]="item.hexColor"></div>-->
<!--    <h3 matLine> {{item.name}} </h3>-->
<!--    <p matLine>-->
<!--      <span> Total </span>-->
<!--      <span> &#45;&#45; {{item.area | number:'1.1-2'}} m<sup>2</sup> </span>-->
<!--    </p>-->
<!--  </mat-list-item>-->
<!--</mat-nav-list>-->
