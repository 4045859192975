import {createSelector} from '@ngrx/store';
import * as fromTaskFloor from '../reducers/task-floor.reducer';
import {selectProjectManagementState} from '../reducers';
import {selectCurrentTaskId} from './task.selectors';

/** Select store slice **/
export const selectTaskFloors = createSelector(
  selectProjectManagementState,
  state => state[fromTaskFloor.taskTaskFloorFeatureKey]
);

/** Basics selectors **/

export const selectTaskFloorIds = createSelector(
  selectTaskFloors,
  fromTaskFloor.selectTaskFloorIds // shorthand for taskTaskFloorsState => fromTaskFloor.selectTaskFloorIds(taskTaskFloorsState)
);
export const selectTaskFloorEntities = createSelector(
  selectTaskFloors,
  fromTaskFloor.selectTaskFloorEntities
);
export const selectAllTaskFloors = createSelector(
  selectTaskFloors,
  fromTaskFloor.selectAllTaskFloors
);
export const selectTaskFloorTotal = createSelector(
  selectTaskFloors,
  fromTaskFloor.selectTaskFloorTotal
);
export const selectCurrentTaskFloorId = createSelector(
  selectTaskFloors,
  fromTaskFloor.getSelectedTaskFloorId
);

export const selectCurrentTaskFloor = createSelector(
  selectTaskFloorEntities,
  selectCurrentTaskFloorId,
  (taskTaskFloorEntities, taskTaskFloorId) => taskTaskFloorEntities[taskTaskFloorId]
);

/** Advanced selectors **/

export const selectCurrentTaskTaskFloor = createSelector(
  selectTaskFloorEntities,
  selectCurrentTaskId,
  (taskTaskFloorEntities, currentTaskId) => taskTaskFloorEntities[currentTaskId]
);

export const selectTaskFloorByTaskId = (taskId: number) => createSelector(
  selectAllTaskFloors,
  (taskFloors) => taskFloors.find(taskFloor => taskFloor.taskId === taskId)
);
