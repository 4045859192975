import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const TASK_VALIDATION = {
  databaseTableName: 'TaskValidation',
  id: 'TaVa_Id',
  taskId: 'TaVa_TaskId',
  userId: 'TaVa_UserId',
  isApproved: 'TaVa_IsApproved',
  timestamp: 'TaVa_TimeStamp',
  comment: 'TaVa_Comment',
};

export class TaskValidation {
  constructor(
    public databaseTableName: string,
    public id: number,
    public taskId: number,
    public userId: number,
    public isApproved: boolean,
    public timestamp: Date,
    public comment: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})

export class TaskValidationAdapter implements Adapter<TaskValidation> {
  adapt(item: any): TaskValidation {
    return new TaskValidation(
      TASK_VALIDATION.databaseTableName,
      item[TASK_VALIDATION.id],
      item[TASK_VALIDATION.taskId],
      item[TASK_VALIDATION.userId],
      item[TASK_VALIDATION.isApproved],
      new Date(item[TASK_VALIDATION.timestamp]),
      item[TASK_VALIDATION.comment]
    );
  }

  encode(item: TaskValidation | Partial<TaskValidation>): ISingleBackendObj {
    const encoded = {
      TableName: TASK_VALIDATION.databaseTableName,
      [TASK_VALIDATION.id]: item?.id,
      [TASK_VALIDATION.taskId]: item?.taskId,
      [TASK_VALIDATION.userId]: item?.userId,
      [TASK_VALIDATION.isApproved]: item?.isApproved,
      [TASK_VALIDATION.timestamp]: item.timestamp?.toString(),
      [TASK_VALIDATION.comment]: item?.comment,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
