import {Injectable} from '@angular/core';
import {ApiService} from '../../core/services/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {LoggingService} from '../../core/services/logging.service';
import {Observable} from 'rxjs';
import {API_ENDPOINTS, API_URL} from '../../shared/api-endpoints';
import {map} from 'rxjs/operators';
import {ApiData} from '../../core/models/api-data.model';
import {TASK_BUSINESS_TYPE, TaskBusinessType, TaskBusinessTypeAdapter} from '../../domain-models/business/task-business-type.model';
import {
  TASK_CONTRIBUTOR_DIRECTORY,
  TaskContributorDirectory,
  TaskContributorDirectoryAdapter
} from '../../domain-models/business/task-contributor-directory.model';
import {TASK_STATUS, TaskStatus, TaskStatusAdapter} from '../../domain-models/business/task-status.model';
import {TASK_TYPE, TaskType, TaskTypeAdapter} from '../../domain-models/business/task-type.model';
import {ApiTasksRootItems, ApiTasksRootItemsAdapter} from '../../domain-models/endpoint-specific/tasks-root-items.model';
import {TASK_FUNCTION, TaskFunction, TaskFunctionAdapter} from '../../domain-models/business/task-function.model';
import {TaskValidation, TaskValidationAdapter} from '../../domain-models/business/task-validation.model';
import {TaskChangelog, TaskChangelogAdapter} from '../../domain-models/business/task-changelog.model';
import {Floor, FloorAdapter} from '../../domain-models/business/floor.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService extends ApiService{

  constructor(http: HttpClient,
              loggingService: LoggingService,
              private taskContributorDirectoryAdapter: TaskContributorDirectoryAdapter,
              private taskBusinessTypeAdapter: TaskBusinessTypeAdapter,
              private taskFunctionAdapter: TaskFunctionAdapter,
              private taskStatusAdapter: TaskStatusAdapter,
              private taskTypeAdapter: TaskTypeAdapter,
              private floorAdapter: FloorAdapter,
              private taskValidationAdapter: TaskValidationAdapter,
              private apiTaskRootItemsAdapter: ApiTasksRootItemsAdapter,
              private taskChangelogAdapter: TaskChangelogAdapter
              ) {
    super(http, loggingService);
  }

  loadTasks(): Observable<ApiTasksRootItems> {
    const params = new HttpParams()
      .set('isActive', 'true');

    return this.get(`${API_ENDPOINTS.tasks}`, null, params)
      .pipe(
        map((data: ApiData) => this.apiTaskRootItemsAdapter.adapt(data.payload)),
      );
  }

  getTaskBusinessType(taskBusinessTypeId: number): Observable<TaskBusinessType> {
    return this.get(`${API_ENDPOINTS.dynT}${TASK_BUSINESS_TYPE.databaseTableName}/${taskBusinessTypeId}`)
      .pipe(
        map((data: ApiData) => this.taskBusinessTypeAdapter.adapt(data.payload)),
      );
  }

  getAllTaskContributorDirectory(): Observable<TaskContributorDirectory[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_CONTRIBUTOR_DIRECTORY.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskContributorDirectoryAdapter.adapt(item)))
      );
  }

  getAllTaskFunctions(): Observable<TaskFunction[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_FUNCTION.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskFunctionAdapter.adapt(item)))
      );
  }

  getAllTaskBusinessTypes(): Observable<TaskBusinessType[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_BUSINESS_TYPE.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskBusinessTypeAdapter.adapt(item)))
      );
  }

  getAllTaskStatusItems(): Observable<TaskStatus[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_STATUS.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskStatusAdapter.adapt(item)))
      );
  }

  getAllTaskTypes(): Observable<TaskType[]> {
    return this.get(API_ENDPOINTS.dynT + TASK_TYPE.databaseTableName)
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskTypeAdapter.adapt(item)))
      );
  }

  getAllTaskValidationsByTaskId(taskId: number): Observable<TaskValidation[]> {
    return this.get(`${API_URL}tasks/validations`, 1, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskValidationAdapter.adapt(item)))
      );
  }

  getTaskChangelogByTaskId(taskId: number): Observable<TaskChangelog[]> {
    return this.get(`${API_URL}tasks/changelog`, 1, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskChangelogAdapter.adapt(item)))
      );
  }

  getTaskValidationByTaskId(taskId: number): Observable<TaskValidation[]> {
    return this.get(`${API_URL}tasks/validations`, 1, new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => data.payload.map((item) => this.taskValidationAdapter.adapt(item)))
      );
  }

  validateTask(taskId: number, isApproved: boolean, comment: string): Observable<TaskValidation> {
    let dto = {} as Partial<TaskValidation>;
    dto.taskId = taskId;
    dto.isApproved = isApproved;
    dto.comment = comment;
    return this.post(`${API_URL}tasks/validations`, this.taskValidationAdapter.encode(dto), new HttpParams().set('taskId', taskId.toString()))
      .pipe(
        map((data: ApiData) => this.taskValidationAdapter.adapt(data.payload['TaskValidation']))
      );
  }

  getDatalessFloors(): Observable<Floor[]> {
    return this.get(`${API_ENDPOINTS.datalessFloors}`).pipe(
      map((data: ApiData) => data.payload.map(f => this.floorAdapter.adapt(f)))
    );
  }
}
