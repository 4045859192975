import {environment} from '../../environments/environment';

export const API_URL = environment.apiUrl;
//export const API_URL = 'https://ecsy.echo-on.fr/api/';
export const NOT_FOUND_IMG = 'https://static.echo-on.fr/public/img/baseline_image_not_supported_black_48dp.png';

export const API_ENDPOINTS = {
  root: API_URL,
  ecsyFile: API_URL + 'ecsyfile/items',
  appMenus: API_URL + 'dynh/z_app_menu_view/all',
  appParams: API_URL + 'dynt/z_app_parameter',
  appUsers: API_URL + 'appuserview/',
  appUsersMe: API_URL + 'appusers/me',
  appRoles: API_URL + 'approles/',
  appRoleGrants: API_URL + 'approlegrants/',
  appGrantGroups: API_URL + 'appgrantgroups/',
  appGrants: API_URL + 'appgrants/',
  buildings: API_URL + 'buildings/items/',
  constraints: API_URL + 'constraints/',
  dashboard: API_URL + 'dashboard/',
  dynT: API_URL + 'dynt/',
  dynH: API_URL + 'dynh/',
  displayThemes: API_URL + 'displaythemes/',//site/id
  filters: API_URL + 'filters/',
  paginationUpdate: API_URL + 'pagination/updatepageitemscount',
  realEstate: API_URL + 'realestate/items/',
  realEstateSubItems: API_URL + 'realestate/subitems/',
  realEstateSite: API_URL + 'realestate/subitems/', //+siteid
  fsImg: API_URL + 'fs/thumbnail/',
  fsSvg: API_URL + 'fs/svg/',
  graphicsFloor: API_URL + 'graphics/floor/',
  graphicsCadTask: API_URL + 'graphics/CadTask/', //floor/docId
  graphicsBuilding: API_URL + 'graphics/building/',
  graphicsBuildingTest: API_URL + 'graphics/building/test/',
  floorRoomsDynView: 'dynview/room_dynview/Items/',
  floorAllocationDynView: 'dynview/floor_allocation_dynview/items/',
  floorDirectoryDynView: 'dynview/floor_directory_dynview/items/',
  floorWorkplaceDynView: 'dynview/floor_workplace_dynview/items/',
  floorFurnitureDynView: 'dynview/floor_furniture_dynview/items/',
  activeCadTasks: API_URL + 'floors/ActiveCadTasks/',
  activeCadRootTasks: API_URL + 'floors/ActiveCadRootTasks/',
  activeCadTasksDocument: API_URL + 'floors/ActiveCadTaskDocuments/',
  rootTasks: API_URL + 'dynh/task/rootitems/',
  rootSimulation: API_URL + 'dynh/simulation/rootitems/',
  rootBus: API_URL + 'dynh/business_unit/rootitems/',
  simulationChildren: API_URL + 'dynh/simulation/subitems/', //parentid
  simulations: API_URL + 'dynh/simulation/', //idsim,
  simulationPhoto: API_URL + 'simulationphotos/',//sim photo id
  simulationOccupationsPatchActivityStatus: API_URL + 'simulationoccupations/activitystatus/', //{SiOc}
  addToSandbox: API_URL + 'simulationoccupations/intosandbox',
  removeFromSandbox: API_URL + 'simulationoccupations/fromsandbox',
  simulationOccupationsDelete: API_URL + 'simulationoccupations/',
  simulationOccupationsUpdate: API_URL + 'simulationoccupations/move',
  simulationOccupationsPatchArea: API_URL + 'simulationoccupations/patcharea/',//{SiOc}
  svgContour: API_URL + 'floordata/contour', //{taskId, x, y}
  tasks: API_URL + 'tasks/rootitems',
  taskLinks: API_URL + 'tasklinks/items',
  uploadLayersUrl: API_URL + 'floordata/import',
  uploadCatalogUrl: API_URL + 'floorcatalog/import',
  cadProjectsDirectTask: API_URL + 'cadprojects/directtask',

  /**
   * FLOOORDATA
   */

  floorDataRoom: API_URL + 'floordata/room',
  floorDataWalls: API_URL + 'floordata/walls',
  floorDataDoors: API_URL + 'floordata/doors',
  floorDataEquipment: API_URL + 'floordata/equipments',
  floorDataWorkplace: API_URL + 'floordata/workplaces',
  floorDataWorkplaceAllocation: API_URL + 'floordata/workplaces/allocation',
  /**
   * ROOMS
   */

  rooms: API_URL + 'rooms/', // {roomId} to get a specific payload / also PATCH point

  /**
   * Project management
   */
  taskContributors: API_URL + 'TaskContributors',
  hasValidationFunction:  API_URL + 'TaskContributors/HasValidationFunction',
  datalessFloors: API_URL + 'dynt/DatalessFloorsView'

};
