import {createSelector} from '@ngrx/store';
import * as fromTask from '../reducers/task.reducer';
import {selectProjectManagementState} from '../reducers';

/** Select store slice **/

export const selectTasks = createSelector(
  selectProjectManagementState,
  state => state[fromTask.taskFeatureKey]
);

/** Basics selectors **/

export const selectTaskIds = createSelector(
  selectTasks,
  fromTask.selectTaskIds
);
export const selectTaskEntities = createSelector(
  selectTasks,
  fromTask.selectTaskEntities
);
export const selectAllTasks = createSelector(
  selectTasks,
  fromTask.selectAllTasks
);
export const selectTaskTotal = createSelector(
  selectTasks,
  fromTask.selectTaskTotal
);
export const selectCurrentTaskId = createSelector(
  selectTasks,
  fromTask.getSelectedTaskId
);

export const selectCurrentTask = createSelector(
  selectTaskEntities,
  selectCurrentTaskId,
  (taskEntities, taskId) => taskEntities[taskId]
);

/** Advanced selectors **/

export const selectTaskById = (id: number) => createSelector(
  selectTaskEntities,
  (entities) => entities[id]
);

export const selectTasksByIds = (ids: number[]) => createSelector(
  selectTaskEntities,
  (entities) => ids.map(id => entities[id])
);
