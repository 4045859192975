import {createReducer, on} from '@ngrx/store';
import {createEntityAdapter, Dictionary, EntityAdapter, EntityState} from '@ngrx/entity';
import * as UsesActions from '../actions/uses.actions';
import {SvgUse} from '../../../svg-factory/models/svg-use.model';
import {SvgBoundingBox} from '../../../svg-factory/models/svg-bounding-box.model';

export const usesFeatureKey = 'uses';

export interface State extends EntityState<SvgUse> {
  selectedIds: number[];
  // hoveredUseId: number | null;
  selectedUseDOMRect: DOMRect | null;
  selectedUseBoundingBox: any | null;
  selectedUsesBoundingBoxes: Dictionary<SvgBoundingBox>;
}

export const adapter: EntityAdapter<SvgUse> = createEntityAdapter<SvgUse>();

export const initialState: State = adapter.getInitialState({
  selectedIds: [],
  // hoveredUseId: null,
  selectedUseDOMRect: null,
  selectedUseBoundingBox: null,
  selectedUsesBoundingBoxes: {}
});

export const reducer = createReducer(
  initialState,
  on(UsesActions.addUse, (state, {svgUse}) => {
    return adapter.addOne(svgUse, state);
  }),
  on(UsesActions.addUses, (state, {svgUses}) => {
    return adapter.addMany(svgUses, state);
  }),
  on(UsesActions.upsertUse, (state, {svgUse}) => {
    return adapter.upsertOne(svgUse, state);
  }),
  on(UsesActions.upsertUses, (state, {svgUses}) => {
    return adapter.upsertMany(svgUses, state);
  }),
  on(UsesActions.deleteUse, (state, {id}) => {
    return adapter.removeOne(id, state);
  }),
  on(UsesActions.deleteUses, (state, {ids}) => {
    return adapter.removeMany(ids, state);
  }),
  on(UsesActions.updateUse, (state, {svgUse}) => {
    return adapter.updateOne(svgUse, state);
  }),
  on(UsesActions.updateUses, (state, {svgUses}) => {
    return adapter.updateMany(svgUses, state);
  }),
  // on(UsesActions.updateHoveredUseId, (state, {id}) => {
  //   return {...state, hoveredUseId: id};
  // }),
  on(UsesActions.updateselectedUseDOMRect, (state, {DOMRect}) => {
    return {...state, selectedUseDOMRect: DOMRect};
  }),
  on(UsesActions.updateselectedUseBoundingBox, (state, {boundingBox}) => {
    return {...state, selectedUseBoundingBox: boundingBox};
  }),
  on(UsesActions.clearUses, state => {
    return adapter.removeAll({
      ...state,
      selectedIds: state.selectedIds.slice(0, 0),
      // hoveredUseId: null,
      selectedUseDOMRect: null,
      selectedUseBoundingBox: null,
    });
  }),
  on(UsesActions.addToSelection, (state, {id, clearSelection}) => {
    const existingId = state.selectedIds.find(e => e === id);
    if (clearSelection) {
      return {...state, selectedIds: [id]};
    } else {
      if (existingId) {
        return {...state, selectedIds: state.selectedIds};
      } else {
        return {...state, selectedIds: [...state.selectedIds, id]};
      }
    }
  }),
  on(UsesActions.updateSelection, (state, {addedId, removedId}) => {
    const alreadySelectedId = state.selectedIds.find(e => e === addedId);
    const idToRemoveIndex = state.selectedIds.indexOf(removedId, 0);

    let cases: number;
    if (addedId && !removedId) {
      if (!alreadySelectedId) {
        cases = 1;
      } else {
        cases = 0;
      }
    } else if (!addedId && removedId) {
      if (idToRemoveIndex > -1) {
        cases = 2;
      } else {
        cases = 0;
      }
    } else if (addedId && removedId) {
      if (!alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 3;
        } else {
          cases = 1;
        }
      } else if (alreadySelectedId) {
        if (idToRemoveIndex > -1) {
          cases = 2;
        } else {
          cases = 0;
        }
      }
    } else {
      cases = 0;
    }
    switch (cases) {
      case 0:
        return state;
      case 1:
        return {...state, selectedIds: [...state.selectedIds, addedId]};
      case 2:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1)]};
      case 3:
        return {...state, selectedIds: [...state.selectedIds.slice(0, idToRemoveIndex), ...state.selectedIds.slice(idToRemoveIndex + 1), addedId ]};
      default: {
        return state;
      }
    }
  }),
  on(UsesActions.clearSelection, (state) => {
    return {...state, selectedIds: state.selectedIds.slice(0, 0)};
  }),
  on(UsesActions.removeFromSelection, (state, {id}) => {
    return {...state, selectedIds: state.selectedIds.filter(e => e !== id)};
  }),
  on(UsesActions.addBoundingBox, (state, {id, bbox}) => {
    const newEntry = {[id]: bbox};
    return {...state, selectedUsesBoundingBoxes: {...state.selectedUsesBoundingBoxes, ...newEntry}};
  }),
);

//export const getSelectedUseId = (state: State) => state.selectedUseId;

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

export const selectUseIds = selectIds;
export const selectUseEntities = selectEntities;
export const selectAllUses = selectAll;
export const selectUseTotal = selectTotal;
