import {createAction, props} from '@ngrx/store';
import {EntityMap, Predicate, Update} from '@ngrx/entity';
import {TaskContributor} from '../../../domain-models/business/task-contributor.model';

/**
 * CRUD Actions
 */

export const loadTaskContributors = createAction('[TaskContributor/API] Load TaskContributors', props<{ taskContributors: TaskContributor[] }>());
export const addTaskContributor = createAction('[TaskContributor/API] Add TaskContributor', props<{ taskContributor: TaskContributor }>());
export const setTaskContributor = createAction('[TaskContributor/API] Set TaskContributor', props<{ taskContributor: TaskContributor }>());
export const upsertTaskContributor = createAction('[TaskContributor/API] Upsert TaskContributor', props<{ taskContributor: TaskContributor }>());
export const addTaskContributors = createAction('[TaskContributor/API] Add TaskContributors', props<{ taskContributors: TaskContributor[] }>());
export const upsertTaskContributors = createAction('[TaskContributor/API] Upsert TaskContributors', props<{ taskContributors: TaskContributor[] }>());
export const updateTaskContributor = createAction('[TaskContributor/API] Update TaskContributor', props<{ update: Update<TaskContributor> }>());
export const updateTaskContributors = createAction('[TaskContributor/API] Update TaskContributors', props<{ updates: Update<TaskContributor>[] }>());
export const mapTaskContributors = createAction('[TaskContributor/API] Map TaskContributors', props<{ entityMap: EntityMap<TaskContributor> }>());
export const deleteTaskContributor = createAction('[TaskContributor/API] Delete TaskContributor', props<{ id: string }>());
export const deleteTaskContributors = createAction('[TaskContributor/API] Delete TaskContributors', props<{ ids: string[] }>());
export const deleteTaskContributorsByPredicate = createAction('[TaskContributor/API] Delete TaskContributors By Predicate', props<{ predicate: Predicate<TaskContributor> }>());
export const clearTaskContributors = createAction('[TaskContributor/API] Clear TaskContributors');

/** END of CRUD **/

export const beginGetAllTaskContributors = createAction('[TaskContributor/API] - Begin Get All TaskContributors');
export const errorGetAllTaskContributors = createAction('[API] - Error Get All TaskContributors', props<Error>());

export const updateSelectedTaskContributorId = createAction(
  '[TaskContributor/API] - Save Selected TaskContributor Id',
  props<{ taskContributorId: number }>()
);
