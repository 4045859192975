import {Injectable} from '@angular/core';
import {Adapter, deleteUndefinedFields, ISingleBackendObj} from '../adapter';

export const FLOOR_DATA = {
  databaseTableName: 'FloorData',
  id: 'FlDa_Id',
  catalogId: 'FlDa_FloorCatalogId',
  svgStatement: 'FlDa_SvgStatement',
  boundingBox: 'FlDa_BoundingBox',
  floorModelId: 'FlDa_FloorModelId',
  taskId: 'FlDa_TaskId',
  dataStateId: 'FlDa_DataStateId',
  parentId: 'FlDa_ParentId',
  sourceId: 'FlDa_SourceId'
};

export class FloorData {
  constructor(
    public databaseTableName: string,
    public id: number,
    public catalogId: number,
    public svgStatement: string,
    public boundingBox: string,
    public floorModelId: number,
    public taskId: number,
    public dataStateId: number,
    public parentId: number,
    public sourceId: number,
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class FloorDataAdapter implements Adapter<FloorData> {
  adapt(item: any): FloorData {
    return new FloorData(
      FLOOR_DATA.databaseTableName,
      item[FLOOR_DATA.id],
      item[FLOOR_DATA.catalogId],
      item[FLOOR_DATA.svgStatement],
      item[FLOOR_DATA.boundingBox],
      item[FLOOR_DATA.floorModelId],
      item[FLOOR_DATA.taskId],
      item[FLOOR_DATA.dataStateId],
      item[FLOOR_DATA.parentId],
      item[FLOOR_DATA.sourceId],
    );
  }

  encode(item: FloorData): ISingleBackendObj {
    const encoded = {
      TableName: FLOOR_DATA.databaseTableName,
      [FLOOR_DATA.id]: item.id,
      [FLOOR_DATA.catalogId]: item.catalogId,
      [FLOOR_DATA.svgStatement]: item.svgStatement,
      [FLOOR_DATA.boundingBox]: item.boundingBox,
      [FLOOR_DATA.floorModelId]: item.floorModelId,
      [FLOOR_DATA.taskId]: item.taskId,
      [FLOOR_DATA.dataStateId]: item.dataStateId,
      [FLOOR_DATA.parentId]: item.parentId,
      [FLOOR_DATA.sourceId]: item.sourceId,
    } as ISingleBackendObj;
    return deleteUndefinedFields(encoded);
  }
}
