import {Injectable} from "@angular/core";
import {Adapter} from "../adapter";
import {Task, TaskAdapter} from "../business/task.model";
import {TaskFloor, TaskFloorAdapter} from "../business/task-floor.model";
import {TaskContributor, TaskContributorAdapter} from "../business/task-contributor.model";
import {TaskContributorDirectory, TaskContributorDirectoryAdapter} from "../business/task-contributor-directory.model";
import {TaskLink, TaskLinkAdapter} from "../business/task-link.model";

const TASKS_ROOT_ITEMS = {
  task: 'task',
  taskFloor: 'task_floor',
  taskContributor: 'task_contributor',
  taskContributorDirectoryView: 'task_contributor_directory_view',
  taskLink: 'task_link',
  businessUnit: 'business_unit',
};

export class ApiTasksRootItems {
  constructor(
    public tasks: Task[],
    public taskFloors: TaskFloor[],
    public taskContributors: TaskContributor[],
    public taskContributorDirectoryItems: TaskContributorDirectory[],
    public taskLinks: TaskLink[],
  ) {
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApiTasksRootItemsAdapter implements Adapter<ApiTasksRootItems> {
  taskAdapter = new TaskAdapter();
  taskFloorAdapter = new TaskFloorAdapter();
  taskContributorAdapter = new TaskContributorAdapter();
  taskContributorDirectoryAdapter = new TaskContributorDirectoryAdapter();
  taskLinksAdapter = new TaskLinkAdapter();

  adapt(item: any): ApiTasksRootItems {

    const taskFloors = item[TASKS_ROOT_ITEMS.taskFloor]
      .map(t => this.taskFloorAdapter.adapt(t)) as TaskFloor[];

    const tasks = item[TASKS_ROOT_ITEMS.task]
      .map(t => this.taskAdapter.adapt(t)) as Task[];

    const taskContributors = item[TASKS_ROOT_ITEMS.taskContributor]
      .map(c => this.taskContributorAdapter.adapt(c)) as TaskContributor[];

    const taskContributorDirectoryItems = item[TASKS_ROOT_ITEMS.taskContributorDirectoryView]
      .map(d => this.taskContributorDirectoryAdapter.adapt(d)) as TaskContributorDirectory[];

    const taskLinks = item[TASKS_ROOT_ITEMS.taskLink]
      .map(l => this.taskLinksAdapter.adapt(l)) as TaskLink[];

    return new ApiTasksRootItems(
      tasks,
      taskFloors,
      taskContributors,
      taskContributorDirectoryItems,
      taskLinks
    );
  }
}
