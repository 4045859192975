import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {State} from '../../reducers';
import {CoreSelectors} from '../../store/core-store/selectors';
import {User} from '../../domain-models/business-extended/user.model';

@Component({
  selector: 'echo-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ProfileComponent implements OnInit {

  //user: User;
  user$: Observable<User>;

  constructor(private store: Store<State>) {
    this.user$ = this.store.select(CoreSelectors.selectUser);
  }

  ngOnInit() {
  }

}
