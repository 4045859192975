import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
// import {DisplayTheme} from '../models/display-theme.model';
import {Store} from '@ngrx/store';
import {State} from '../../reducers';
import {combineLatest, Observable, Subject} from 'rxjs';
import {filter, map, switchMap, takeUntil, withLatestFrom} from 'rxjs/operators';
import {Update} from '@ngrx/entity';
import {ROOM_LAYOUT_TYPE} from '../../domain-models/business/room-layout-type.model';
import {ThemeItemMockup} from '../../floor/models/theme-item-mockup.model';
import {DisplayThemeMockup} from '../../floor/models/display-theme-mockup.model';
import {ROOM_ALLOCATION} from '../../domain-models/business/room-allocation.model';
import {ROOM_ATTRIBUTION_TYPE} from '../../domain-models/business/room-attribution-type.model';
import {ROOM_ACTIVITY_STATUS_TYPE} from '../../domain-models/business/room-activity-status-type.model';
import {Actions, ofType} from '@ngrx/effects';
import {DisplayThemeItemActions} from '../../store/shared-store/actions';
import {RoomActions} from '../../store/floor-store/actions';
import {UiContextSelectors} from '../../store/floor-store/selectors';
import {DisplayThemeItemSelectors} from '../../store/shared-store/selectors';
import {GraphicsFloorActions} from '../../store/svg-store/actions';
import {BuildingSelectors, BuildingUiSelectors} from '../../store/building-store/selectors';
import {LegendContextEnum} from '../../domain-models/models/legend-context.enum';
import {TaskSelectors} from '../../store/project-management/selectors';
import {TaskActions} from '../../store/project-management/actions';

@Component({
  selector: 'echo-floor-legend',
  templateUrl: './floor-legend.component.html',
  styleUrls: ['./floor-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FloorLegendComponent implements OnInit, AfterViewInit, OnDestroy {

  idsToDisplay: string[] = [];

  displayThemeId$: Observable<number>;
  displayThemeId: number;

  legendContextId$: Observable<number>;
  selectedBuildingId$: Observable<number>;
  selectedTaskId$: Observable<number>;
  detectChanges$: Observable<boolean>;

  selectedTaskId: number;
  selectedBuildingId: number;

  displayThemes$: Observable<DisplayThemeMockup[]>;
  displayThemeItems$: Observable<ThemeItemMockup[]>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store<State>,
              private cd: ChangeDetectorRef,
              private actions$: Actions) {
    // Moved to GraphicsFloorEffects
    //this.store.dispatch(DisplayThemeItemActions.buildDisplayThemes());
    this.displayThemes$ = this.store.select(DisplayThemeItemSelectors.selectDisplayThemes);
    this.displayThemeItems$ = this.store.select(DisplayThemeItemSelectors.selectAllCurrentDisplayThemeItems);
    this.displayThemeId$ = this.store.select(DisplayThemeItemSelectors.selectCurrentDisplayThemeId);
    this.legendContextId$ = this.store.select(DisplayThemeItemSelectors.selectLegendContext);
    this.selectedBuildingId$ = this.store.select(BuildingSelectors.selectCurrentBuildingId);
    this.selectedTaskId$ = this.store.select(TaskSelectors.selectCurrentTaskId);

    this.detectChanges$ = this.actions$.pipe(
      ofType(
        RoomActions.addRoom,
        RoomActions.updateRoom,
        GraphicsFloorActions.successGetGraphicsFloorByTaskId,
        TaskActions.updateSelectedTaskId
      ));

  }

  ngOnInit() {

    combineLatest([this.selectedBuildingId$, this.selectedTaskId$, this.legendContextId$]).pipe(
      map(([selectedBuildingId, selectedTaskId, legendContextId]) => {
        switch (legendContextId) {
          case LegendContextEnum.Building: {
            this.selectedBuildingId = selectedBuildingId;
            this.selectedTaskId = null;
            break;
          }
          case LegendContextEnum.Floor: {
            this.selectedBuildingId = null;
            this.selectedTaskId = selectedTaskId;
            break;
          }
        }
      }),
    ).subscribe(_ => this.cd.detectChanges());

      this.legendContextId$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.selectedBuildingId$, this.selectedTaskId$),
      filter(id => id !== null),
      switchMap(([id, selectedBuildingId, selectedTaskId]) => {
        switch (id) {
          case LegendContextEnum.Building: {
            this.selectedBuildingId = selectedBuildingId;
            this.selectedTaskId = selectedTaskId;
            return combineLatest([
              this.store.select(BuildingUiSelectors.selectCurrentBuildingRoomLayoutTypeIds),
              this.store.select(BuildingUiSelectors.selectCurrentBuildingActivityStatusTypeIds),
              this.store.select(BuildingUiSelectors.selectCurrentBuildingAttributionTypeIds),
              this.store.select(BuildingUiSelectors.selectCurrentBuildingBusinessUnitIds)
            ]);
          }
          case LegendContextEnum.Floor: {
            this.selectedBuildingId = null;
            this.selectedTaskId = selectedTaskId;
            return combineLatest([
              this.store.select(UiContextSelectors.selectCurrentTaskRoomLayoutTypeIds),
              this.store.select(UiContextSelectors.selectCurrentTaskActivityStatusTypeIds),
              this.store.select(UiContextSelectors.selectCurrentTaskAttributionTypeIds),
              this.store.select(UiContextSelectors.selectCurrentTaskBusinessUnitIds)
            ]);
          }
          // default: {
          //   return EMPTY;
          // }
        }
      }),
        //filter(r => r !== undefined),
        //tap(a => console.log(a)),
        map(([currentTaskRoomLayoutTypeIds,
             currentTaskActivityStatusTypeIds,
             currentTaskAttributionTypeIds,
             currentTaskBusinessUnitIds]) =>
        this.idsToDisplay = this.checkIdsToDisplay(currentTaskRoomLayoutTypeIds,
          currentTaskActivityStatusTypeIds,
          currentTaskAttributionTypeIds,
          currentTaskBusinessUnitIds)
        ),
    ).subscribe(_ => {
      this.cd.detectChanges();

      });

    this.detectChanges$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.cd.detectChanges());

    this.displayThemeId$.pipe(takeUntil(this.unsubscribe$))
      .subscribe(id => {
        this.displayThemeId = id;
      });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectNewTheme(event: number) {
    this.store.dispatch(DisplayThemeItemActions.updateDisplayThemeId({id: event}));
  }

  toggleItemVisibility(item: ThemeItemMockup) {
    const updatedItem = {
      id: item.resId,
      changes: {
        active: !item.active
      }
    } as Update<ThemeItemMockup>;
    this.store.dispatch(DisplayThemeItemActions.updateDisplayThemeItem({displayThemeItem: updatedItem}));
  }

  toggleHoveredItem(item: ThemeItemMockup) {
    if (item) {
      this.store.dispatch(DisplayThemeItemActions.setHoveredThemeItemId({id: item.resId}));
    } else {
      this.store.dispatch(DisplayThemeItemActions.setHoveredThemeItemId({id: null}));
    }
  }

  checkIdsToDisplay(roomLayoutTypeIds: number[], activityStatusTypeIds: number[], attributionTypeIds: number[], businessUnitIds: number[]): string[] {
    const idsToDisplay = [];
    roomLayoutTypeIds.forEach(id => idsToDisplay.push(ROOM_LAYOUT_TYPE.databaseTableName + '-' + id));
    activityStatusTypeIds.forEach(id => idsToDisplay.push(ROOM_ACTIVITY_STATUS_TYPE.databaseTableName + '-' + id));
    attributionTypeIds.forEach(id => idsToDisplay.push(ROOM_ATTRIBUTION_TYPE.databaseTableName + '-' + id));
    businessUnitIds.forEach(id => idsToDisplay.push(ROOM_ALLOCATION.databaseTableName + '-' + id));
    /** Enable MultiAllocated and NotAllocated in Attributions theme **/
    idsToDisplay.push(ROOM_ATTRIBUTION_TYPE.databaseTableName + '-MultiAllocated');
    idsToDisplay.push(ROOM_ATTRIBUTION_TYPE.databaseTableName + '-NotAllocated');
    return idsToDisplay;
  }

}
