import {Injectable} from '@angular/core';
import {Adapter} from '../adapter';
import {APP_USER, AppUser} from '../business/app-user.model';

export const USER = {
  ...APP_USER,
};

export class User extends AppUser {
  constructor(
    public name: string,
    public preferred_username: string,
    public role: any,
    public sub: string) {
    super(USER.databaseTableName,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null);
  }

  public setAppUser(appUser: AppUser) {
    this.id = appUser.id;
    this.directoryId = appUser.directoryId;
    this.code = appUser.code;
    this.title = appUser.title;
    this.firstName = appUser.firstName;
    this.lastName = appUser.lastName;
    this.roleId = appUser.roleId;
    this.isActive = appUser.isActive;
    this.policyId = appUser.policyId;
    this.perimeterId = appUser.perimeterId;
  }

}
